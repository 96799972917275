/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { ScrollView } from "react-native";
// import { Context } from '../../context/PetContext';
import PetForm from "../../components/PetForm";
import TopNavBar from "../../components/TopNavBar";
import BottomLink from "../../components/BottomLink";
import { Button } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../reduxStores/actions/allActions";
import { STACK_SCREEN, MyLog } from "../../constants/config";

const PetEditScreen = ({ route, navigation }) => {
  const petId = route.params?.id;

  const currentUser = useSelector((reduxState) => reduxState.users.myProfile);

  MyLog("pet edit currentUser", currentUser);

  const dispatch = useDispatch();
  const allPets = useSelector((reduxState) => reduxState.pets.allItems);
  const currentPet = allPets.find((item) => item.id === petId);
  const allSpecies = useSelector((reduxState) => reduxState.pets.allSpecies);

  const [confirmDel, setConfirmDel] = useState(false);

  // FIREBASE.auth().onAuthStateChanged((user) => {
  //   if (user) {
  //     // User is signed in, see docs for a list of available properties
  //     // https://firebase.google.com/docs/reference/js/firebase.User
  //     MyLog('PetEditScreen.onAuthStateChanged logged in', user)

  //   } else {
  //     // User is signed out
  //     MyLog('PetEditScreen.onAuthStateChanged logged out', user)
  //     navigation.navigate(STACK_SCREEN.userLogin.name);
  //     return null;
  //   }
  // });

  // if (allPets.length == 0) {
  //   MyLog('try to get pet info from database');
  //   dispatch(allActions.listPetRequest({ id: petId }));
  // }

  useEffect(() => {
    if (!currentUser) {
      navigation.navigate(STACK_SCREEN.userLogin.name);
    }
    if (!allSpecies || allSpecies.length == 0) {
      dispatch(allActions.listSpeciesRequest());
    }
    // always get data from db
    // BUG: needs refresh to load new data after change it on different device
    dispatch(allActions.listPetRequest({ id: petId }));
  }, [petId]);

  useEffect(() => {
    navigation.setOptions({
      title: STACK_SCREEN.petEdit.title + ":" + currentPet?.name + "",
    });
    setConfirmDel(false);
  }, [currentPet]);
  //
  MyLog("PetEditScreen currentPet", currentPet);

  if (!currentPet) {
    return <></>;
  }

  return (
    <ScrollView>
      <TopNavBar title={STACK_SCREEN.petEdit.title} />
      <PetForm
        currentPet={currentPet}
        currentUser={currentUser}
        allSpecies={allSpecies}
        onSubmit={(formData) => {
          if (!formData.name.trim()) {
            alert("Please Enter Pet Name");
            return false;
          }
          dispatch(allActions.editPetRequest(formData));
          // editPet(petDetails, () => navigation.navigate('PetIndex'));
        }}
      />

      <Button
        icon={{
          name: "trash",
          type: "font-awesome",
          size: 15,
          color: "red",
        }}
        type="outline"
        title={confirmDel ? "Confirm Delete" : "Delete"}
        titleStyle={{ color: "red" }}
        buttonStyle={{
          width: "60%",
          margin: 40,

          alignSelf: "center",
        }}
        onPress={() => {
          if (confirmDel) {
            dispatch(allActions.delPetRequest(currentPet));
          } else {
            setConfirmDel(true);
          }

          // deletePet(id, () => navigation.navigate('PetIndex'));
        }}
      />
      <BottomLink currentUser={currentUser} />
    </ScrollView>
  );
};

export default PetEditScreen;
