/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { View, ScrollView } from "react-native";
import { Button, Text } from "react-native-elements";
import { MySubmitButton } from "../../helper/commonHelper";
import { Link } from "@react-navigation/native";

// import SearchBar from "../../components/SearchBar";

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../reduxStores/actions/allActions";
import PetList from "../../components/PetList";
import TopNavBar, { PageHeader } from "../../components/TopNavBar";
import BottomLink from "../../components/BottomLink";
import { NUM_PER_PAGE, COLORS, MyLog } from "../../constants/config";

const PetIndexScreen = ({ route, navigation }) => {
  const dispatch = useDispatch();
  // const allPets = useSelector(reduxState => reduxState.pets.allItems);
  // const allSpecies = useSelector(reduxState => reduxState.pets.allSpecies);
  // const petExtraInfo = useSelector(reduxState => reduxState.pets.extraInfo);
  const reduxPets = useSelector((reduxState) => reduxState.pets);
  const allPets = reduxPets.allItems;
  const allSpecies = reduxPets.allSpecies;
  const petExtraInfo = reduxPets.extraInfo;

  const [searchResults, setSearchResult] = useState(allPets);
  //   const [term, setTerm] = useState("");

  const [species, setSpecies] = useState(route.params?.species);
  const [showAllSpecies, setShowAllSpecies] = useState(false);
  const [displayNumber, setDisplayNumber] = useState(NUM_PER_PAGE);

  let isValidSpecies = false;
  let listPetParams = {
    _limit: NUM_PER_PAGE,
    // _start: searchResults.length,
    _start: 0,
    // _end: 0,
  };

  if (species && species.indexOf("%") != -1) {
    setSpecies(decodeURIComponent(species));
  }
  if (species && allSpecies.find((item) => item.name == species)) {
    isValidSpecies = true;
  }
  if (isValidSpecies) {
    listPetParams.species = species;
  }

  MyLog("species param", species, isValidSpecies, listPetParams);

  useEffect(() => {
    if (!species || isValidSpecies) {
      setDisplayNumber(NUM_PER_PAGE);
      if (displayNumber >= searchResults.length) {
        dispatch(allActions.listPetRequest(listPetParams));
      } else {
        // already have some old data in reduxState
        // get a few new records from database no mater what
        dispatch(
          allActions.listPetRequest({
            ...listPetParams,
            ...{ _start: 0, _sort: "id", _order: "DESC" },
          })
        );
      }
    }
    if (!allSpecies || allSpecies.length == 0) {
      dispatch(allActions.listSpeciesRequest());
    }
  }, [species, isValidSpecies]);

  useEffect(() => {
    if (isValidSpecies) {
      setSearchResult(allPets.filter((item) => item.species == species));
    }
    // setResultsByTerm(term);
  }, [allPets]);

  const DisplayAllSpecies = () => {
    if (showAllSpecies || searchResults.length == 0) {
      return (
        <View style={{ flex: 1, flexDirection: "row", flexWrap: "wrap" }}>
          <Link
            to={"/petProfiles"}
            style={{ margin: 20, fontSize: 20, color: COLORS.info }}
            onPress={() => {
              setSpecies(null);
              setShowAllSpecies(false);
            }}
          >
            * All Species
          </Link>
          {allSpecies.map((item) => {
            return (
              <Link
                to={"/petProfiles/" + item.name}
                style={{ margin: 20, fontSize: 20, color: COLORS.info }}
                onPress={() => {
                  setSpecies(item.name);
                  setShowAllSpecies(false);
                }}
                key={item.id}
              >
                * {item.name}
              </Link>
            );
          })}
        </View>
      );
    } else {
      return (
        <Button
          type="clear"
          title="Other Pet Species >>"
          buttonStyle={{ alignContent: "flex-end", alignSelf: "flex-end" }}
          // titleStyle={{ fontSize: 14 }}
          onPress={() => setShowAllSpecies(true)}
        />
      );
    }
  };

  MyLog(
    "listPetParams._start -searchResults.length",
    listPetParams._start,
    searchResults.length,
    displayNumber
  );
  return (
    <ScrollView>
      <PageHeader
        title={species ? species + " Pet Profiles 2" : "All Pet Profiles 2"}
        description={
          species
            ? species + " Pet Profiles, find a lovely " + species + " nearby"
            : "All lovely Pets in the world"
        }
      />
      <TopNavBar
        title={species ? species + " Pet Profiles 3" : "All Pet Profiles 3"}
      />
      <Text h4 style={{ textTransform: "capitalize", alignSelf: "center" }}>
        {species ? species : "All Pet"} Profiles
      </Text>
      <DisplayAllSpecies />
      {/* <SearchBar
          term={term}
          onTermChange={setTerm}
          onTermSubmit={() => setResultsByTerm(term)}
          inputContainerStyle={{ width: "100%" }}
        /> */}
      {/* {errorMessage ? <Text>{errorMessage}</Text> : null} */}

      <PetList
        pets={searchResults.slice(0, displayNumber)}
        navigation={navigation}
      />

      {displayNumber <= searchResults.length || petExtraInfo.loading ? (
        <MySubmitButton
          iconName="arrow-right"
          title={"Load more data " + displayNumber}
          isLoading={petExtraInfo.loading}
          message={petExtraInfo.errorMsg}
          // buttonStyle={{
          //     backgroundColor: 'rgb(218, 68, 90)', paddingLeft: 20,
          //     paddingRight: 20, width: "auto"
          // }}
          onSubmit={() => {
            // if (searchResults.length >= displayNumber + listPetParams._limit) {
            //     setDisplayNumber(displayNumber + listPetParams._limit);
            // } else {
            listPetParams._start = displayNumber;
            setDisplayNumber(displayNumber + listPetParams._limit);
            return dispatch(allActions.listPetRequest(listPetParams));
            // }
          }}
        />
      ) : (
        <Button
          title={
            species ? "No more " + species + " Profile" : "No more Pet Profile"
          }
          type="clear"
          titleStyle={{ fontSize: 11 }}
        />
      )}

      {/* {allSpecies && allSpecies.map(item => {
                    // return <PetResultsList
                    //     results={filterResultsBySpecies(item.name)}
                    //     title={item.name}
                    //     key={item.id}
                    // />
                    return <PetList pets={filterResultsBySpecies(item.name)} navigation={navigation} />
                }
                )} */}
      <BottomLink />
    </ScrollView>
  );
};

export default PetIndexScreen;
