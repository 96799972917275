/* eslint-disable react/prop-types */
import React from "react";
import { View } from "react-native";
import { Link } from "@react-navigation/native";
import { Text, Image, Divider } from "react-native-elements";
import { COLORS } from "../constants/config";
// import { useDispatch, useSelector } from "react-redux";

const BottomLink = ({ currentUser }) => {
  // const currentUser = useSelector(reduxState => reduxState.users.myProfile);

  return (
    <>
      <View
        style={{
          // flexDirection: 'row',
          justifyContent: "center",
          marginTop: 50,
          alignItems: "center",
        }}
      >
        {/* assume google ad is here */}
        <Text style={{ color: COLORS.secondary }}>Google Ad</Text>
        <Image
          source={{
            uri: "https://picsum.photos/320/180?ramdom=" + currentUser?.id,
          }}
          style={{ width: 480, height: 270 }}
        />
      </View>

      <Divider style={{ backgroundColor: "gray", marginTop: 50 }} />
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          margin: 20,
        }}
      >
        {/* <Button
                    onPress={() => navigation.navigate(STACK_SCREEN.petIndex.name)}
                    type="clear"
                    title="Home  "
                    titleStyle={{ color: "gray" }}
                /> */}
        <Link to="/" style={{ marginRight: 20 }}>
          Home
        </Link>
        {/* <Button
                    onPress={() => navigation.navigate(STACK_SCREEN.storyIndex.name)}
                    type="clear"
                    title="Story  "
                    titleStyle={{ color: "gray" }}
                /> */}
        <Link to="/petProfiles" style={{ marginRight: 20 }}>
          Pet
        </Link>
        <Link to="/petStories" style={{ marginRight: 20 }}>
          Story
        </Link>
        {/* <Button
                    onPress={() => navigation.navigate(currentUser ? STACK_SCREEN.userIndex.name : STACK_SCREEN.userLogin.name)}
                    type="clear"
                    title="User"
                    titleStyle={{ color: "gray" }}
                /> */}
        <Link to="/user/index">User</Link>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: 20,
        }}
      >
        <Link to="/" style={{ fontSize: 10 }}>
          www.PetStory.onLine
        </Link>
      </View>
    </>
  );
};

export default BottomLink;
