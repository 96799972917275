/* eslint-disable react/prop-types */
import React from "react";
import { Button, Icon } from "react-native-elements";
import { InlineShareButtons } from "sharethis-reactjs";
import { MyRowView } from "../helper/commonHelper";
import { COLORS } from "../constants/config";
// import { useDispatch, useSelector } from "react-redux";
// import allActions from "../reduxStores/actions/allActions";
import LikeIcon from "./LikeIcon";

export const LikeShareIcon = ({
  currentPet,
  currentStory,
  currentUser,
  petOwner,
  category,
}) => {
  // const dispatch = useDispatch();
  // const myLikes = useSelector((reduxState) => reduxState.users.myLikes);

  // useEffect(() => {
  //   // MyLog('useEffect ')
  // }, []);

  const shareUrl = window.location.href;

  let viewNum = 0;
  // let likeNum = 0;
  let shareTitle = "";
  // let targetId = "";
  // let type = "thumbUp";
  // let userId = "";
  // if (currentUser) {
  //   userId = currentUser.id;
  // }
  if (category == "petProfile" && currentPet) {
    viewNum = currentPet.view_num || 0;
    // likeNum = currentPet.likeNum || 0;
    // targetId = currentPet.id;
    shareTitle = "Pet Profile:" + currentPet?.species + " " + currentPet?.name;
  } else if (category == "petStory" && currentStory) {
    viewNum = currentStory.view_num || 0;
    // likeNum = currentStory.likeNum || 0;
    // targetId = currentStory.id;
    shareTitle =
      "Pet Story:" +
      currentStory.title +
      " - " +
      currentPet.species +
      " " +
      currentPet.name;
  } else if (category == "petOwner" && petOwner) {
    // likeNum = petOwner.likeNum || 0;
    viewNum = petOwner.view_num || 0;
    // targetId = petOwner.id;
    shareTitle = "Pet petOwner:" + petOwner.firstName + " " + petOwner.lastName;
  }
  // let likeStyle = { color: "gray" };
  // let otherLikeStyle = { color: "gray", marginRight: 10 };
  // let likeColor = "gray";
  // let currentLike = null;
  // if (myLikes) {
  //   myLikes.map((item) => {
  //     if (item.targetId == targetId) {
  //       likeStyle = { color: COLORS.info };
  //       likeColor = COLORS.info;
  //       otherLikeStyle = { color: COLORS.info, marginRight: 10 };
  //       currentLike = item;
  //     }
  //   });
  // }

  // const handleLikeIconPress = () => {
  //   if (currentUser) {
  //     const newData = {
  //       type: type,
  //       targetId: targetId,
  //       category: category,
  //       userId: userId,
  //       isLiked: currentLike ? true : false,
  //       id: currentLike ? currentLike.id : null,
  //     };
  //     dispatch(allActions.addLikeRequest(newData));
  //   } else {
  //     window.alert("Please log in");
  //   }
  // };

  return (
    <>
      <MyRowView>
        <Button
          // onPress={() => MyLog('like function onpress')}
          title={viewNum + " Views"}
          type="clear"
          buttonStyle={{
            backgroundColor: "white",
            marginLeft: 20,
            marginRight: 10,
            // margin: 20,
          }}
          titleStyle={{ color: COLORS.secondary }}
          icon={
            <Icon
              reverse={false}
              name="eye"
              type="font-awesome"
              // size={15}
              color={COLORS.secondary}
              style={{ marginRight: 10 }}
            />
          }
        />
        <LikeIcon
          currentPet={currentPet}
          currentStory={currentStory}
          petOwner={petOwner}
          category={category}
          currentUser={currentUser}
          buttonType="clear"
        />

        {/* 
        <Button
          onPress={handleLikeIconPress}
          title={
            likeNum >= 1 ? likeNum + (likeNum > 1 ? " Likes" : " Like") : "Like"
          }
          type="clear"
          buttonStyle={{
            backgroundColor: "white",
            marginLeft: 20,
            marginRight: 10,
            margin: 20,
          }}
          titleStyle={likeStyle}
          icon={
            <Icon
              reverse={false}
              name="thumbs-up"
              type="font-awesome"
              color={likeColor}
              // size={15}
              style={otherLikeStyle}
            />
          }
        /> */}
      </MyRowView>
      <InlineShareButtons
        config={{
          alignment: "center", // alignment of buttons (left, center, right)
          color: "social", // set the color of buttons (social, white)
          enabled: true, // show/hide buttons (true, false)
          font_size: 14, // font size for the buttons
          labels: "cta", // button labels (cta, counts, null)
          language: "en", // which language to use (see LANGUAGES)
          networks: [
            // which networks to include (see SHARING NETWORKS)
            "facebook",
            "twitter",
            "whatsapp",
            "messenger",
            "wechat",
            "pinterest",
            "linkedin",
            "reddit",
            "email",
            "blogger",
            "tumblr",
            "weibo",
            "vk",
          ],
          padding: 10, // padding within buttons (INTEGER)
          radius: 4, // the corner radius on each button (INTEGER)
          show_total: false,
          size: 30, // the size of each button (INTEGER)

          // OPTIONAL PARAMETERS
          url: shareUrl, // (defaults to current url)
          image: "https://bit.ly/2CMhCMC", // (defaults to og:image or twitter:image)
          description: shareTitle, // (defaults to og:description or twitter:description)
          title: shareTitle, // (defaults to og:title or twitter:title)
          message: shareTitle + " " + shareUrl, // (only for email sharing)
          subject: shareTitle, // (only for email sharing)
          username: "custom twitter handle", // (only for twitter sharing)
        }}
      />
      <hr />
    </>
  );
};

export default LikeShareIcon;
