/* eslint-disable react/prop-types */
import React from "react";
import { Button, Icon } from "react-native-elements";
// import { MyLog, MyRowView } from "../helper/commonHelper";
import { COLORS } from "../constants/config";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../reduxStores/actions/allActions";

export const LikeIcon = ({
  currentPet,
  currentStory,
  currentUser,
  petOwner,
  category,
  buttonType,
}) => {
  const dispatch = useDispatch();
  const myLikes = useSelector((reduxState) => reduxState.users.myLikes);

  // let viewNum = 0;
  let likeNum = 0;
  // let shareTitle = "";
  let targetId = "";
  let type = "thumbUp";
  let userId = "";
  if (currentUser) {
    userId = currentUser.id;
  }
  if (category == "petProfile" && currentPet) {
    // viewNum = currentPet.viewNum || 0;
    likeNum = currentPet.likeNum || 0;
    targetId = currentPet.id;
    // shareTitle = "Pet Profile:" + currentPet?.species + " " + currentPet?.name;
  } else if (category == "petStory" && currentStory) {
    // viewNum = currentStory.viewNum || 0;
    likeNum = currentStory.likeNum || 0;
    targetId = currentStory.id;
    // shareTitle =
    //   "Pet Story:" +
    //   currentStory.title +
    //   " - " +
    //   currentPet.species +
    //   " " +
    //   currentPet.name;
  } else if (category == "petOwner" && petOwner) {
    likeNum = petOwner.likeNum || 0;
    // viewNum = petOwner.viewNum || 0;
    targetId = petOwner.id;
    // shareTitle = "Pet petOwner:" + petOwner.firstName + " " + petOwner.lastName;
  }
  let likeStyle = { color: "gray" };
  let otherLikeStyle = { color: "gray", marginRight: 10 };
  let likeColor = "gray";
  let currentLike = null;
  if (myLikes) {
    myLikes.map((item) => {
      if (item.targetId == targetId) {
        likeStyle = { color: COLORS.info };
        likeColor = COLORS.info;
        otherLikeStyle = { color: COLORS.info, marginRight: 10 };
        currentLike = item;
      }
    });
  }

  const handleLikeIconPress = () => {
    if (currentUser) {
      const newData = {
        type: type,
        targetId: targetId,
        category: category,
        userId: userId,
        isLiked: currentLike ? true : false,
        id: currentLike ? currentLike.id : null,
      };
      dispatch(allActions.addLikeRequest(newData));
    } else {
      window.alert("Please log in");
    }
  };

  return (
    <Button
      onPress={handleLikeIconPress}
      title={
        likeNum >= 1 ? likeNum + (likeNum > 1 ? " Likes" : " Like") : "Like"
      }
      type={buttonType || "clear"}
      buttonStyle={{
        backgroundColor: "white",
        marginLeft: 20,
        marginRight: 10,
        // margin: 20,
      }}
      titleStyle={likeStyle}
      icon={
        <Icon
          reverse={false}
          name="thumbs-up"
          type="font-awesome"
          color={likeColor}
          // size={15}
          style={otherLikeStyle}
        />
      }
    />
  );
};

export default LikeIcon;
