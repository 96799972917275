import { call, put } from "redux-saga/effects";
import sagaApi from "./sagaApiServices";
import { MyLog } from "../constants/config";
import allActions from "../reduxStores/actions/allActions";

// file
export function* addFile(action) {
  try {
    const uploadJson = yield call(sagaApi.uploadFile, action); // call sagaApi function
    MyLog("addFile-uploadJson3", uploadJson.data);
    let source = "unknown";
    let jsonData = uploadJson.data;
    if (jsonData.url) {
      if (jsonData.url.includes("ibb.")) {
        source = "ibb";
        jsonData = uploadJson.data.data;
      } else if (jsonData.url.includes("imgur.")) {
        source = "imgur";
        jsonData = uploadJson.data.data;
      } else if (jsonData.url.includes("imagekit.")) {
        source = "imagekit";
        jsonData.url += "?tr=w-1600,c-at_max";
      } else if (jsonData.url.includes("cloudinary.")) {
        source = "cloudinary";
        jsonData.url = jsonData.eager
          ? jsonData.eager[0].secure_url
          : jsonData.secure_url;
      }
    }
    const dbFormData = {
      extra_info: { providerData: jsonData },
      name: action.formData.name,
      size: action.formData.size,
      mime: action.formData.type,
      user: action.formData.userId,
      pet: action.formData.petId,
      story: action.formData.storyId,
      category: action.formData.category,
      url: jsonData.url,
      source: source,
    };
    const json = yield call(sagaApi.addFile, { formData: dbFormData }); // call sagaApi function
    MyLog("addFile-mylog5", action, json, dbFormData);
    yield put(allActions.addFileSuccess(json.data, action.formData)); // call reducer function
    // yield navigationService.navigate(STACK_SCREEN.storyShow.name, { id: json.data.id });
  } catch (e) {
    MyLog("addFile-addFileFailure", e);
    yield put(allActions.addFileFailure(e.message));
  }
}

export function* delFile(action) {
  try {
    const json = yield call(sagaApi.delFile, action); // call sagaApi function
    MyLog("delFile-mylog3", action, json);
    yield put(allActions.delFileSuccess(json.data, action.formData)); // call reducer function
    // yield navigationService.navigate(STACK_SCREEN.petShow.name, { id: action.formData.petId });
  } catch (e) {
    yield put(allActions.delFileFailure(e.message, action.formData));
  }
}

export function* listFile(action) {
  try {
    const json = yield call(sagaApi.listFile, action); // call sagaApi function
    MyLog("listFile-mylog1", action.type, json);
    yield put(
      allActions.listFileSuccess(json.data, action.terms, action.nextPageToken)
    ); // call reducer function
  } catch (e) {
    yield put(allActions.listFileFailure(e.message));
  }
}
