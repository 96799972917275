import { call, put } from "redux-saga/effects";
import sagaApi from "./sagaApiServices";
import navigationService from "./navigationService";
import { STACK_SCREEN, MyLog } from "../constants/config";
import allActions from "../reduxStores/actions/allActions";
import { SetAccessToken } from "../components/UserToken";

// site setting

export function* listSiteSetting(action) {
  try {
    const json = yield call(sagaApi.listSiteSetting, action); // call sagaApi function
    MyLog("Saga:listSiteSetting-mylog1", action.type, json);

    let newJsonData = json.data;

    yield put(
      allActions.listSiteSettingSuccess(
        newJsonData,
        action.terms,
        action.nextPageToken
      )
    ); // call reducer function
  } catch (e) {
    yield put(allActions.listSiteSettingFailure(e.message));
  }
}

// user
export function* userRegister(action) {
  yield put(allActions.userLoadingChange(true, ""));

  try {
    const json = yield call(sagaApi.userRegister, action); // call sagaApi function
    MyLog("userRegister-mylog5", action, "returned backendData:", json);
    if (json.firebaseData && json.firebaseData.user) {
      yield put(allActions.userRegisterSuccess(json, action.formData)); // call reducer function
      yield navigationService.navigate(STACK_SCREEN.userIndex.name);
    } else {
      yield put(
        allActions.userRegisterFailure(
          json.firebaseData.error.message || "userRegister Error"
        )
      );
    }
  } catch (e) {
    yield put(allActions.userRegisterFailure(e.message));
  }
}

export function* userLogin(action) {
  MyLog("userLogin check loginType", action);
  if (action.formData.loginType == "google") {
    yield call(userGoogleLogin, action);
  } else if (action.formData.loginType == "facebook") {
    yield call(userFacebookLogin, action);
  } else {
    yield call(userEmailLogin, action);
  }
}

export function* userGoogleLogin2(action) {
  // yield put(allActions.userLoadingChange(true, ''));
  try {
    const json = yield call(sagaApi.userGoogleLogin, action); // call sagaApi function
    MyLog("userGoogleLogin-mylog5", action, "returned backendData:", json);
    if (json.firebaseData && json.firebaseData.user) {
      yield put(allActions.userLoginSuccess(json, action.formData)); // call reducer function
      yield navigationService.navigate(STACK_SCREEN.petIndex.name);
    } else {
      yield put(
        allActions.userLoginFailure(
          json.firebaseData.error.message || "userLogin Error"
        )
      );
    }
  } catch (e) {
    yield put(allActions.userLoginFailure(e.message));
  }
}

export function* userGoogleLogin(action) {
  yield put(allActions.userLoadingChange(true, ""));
  try {
    const json = yield call(sagaApi.userGoogleLogin, action.formData); // call sagaApi function
    MyLog("userGoogleLogin-mylog5", action, "returned backendData:", json);
    const backendData = {
      jwt: json.data.jwt,
      userProfile: json.data.user,
    };
    SetAccessToken(json.data.jwt);
    yield put(allActions.userLoginSuccess(backendData, action.formData)); // call reducer function
    yield navigationService.navigate(STACK_SCREEN.userIndex.name);
  } catch (e) {
    yield put(allActions.userLoginFailure(e.message));
  }
}

export function* userFacebookLogin(action) {
  yield put(allActions.userLoadingChange(true, ""));
  try {
    const json = yield call(sagaApi.userFacebookLogin, action.formData); // call sagaApi function
    MyLog("userFacebookLogin-mylog5", action, "returned backendData:", json);
    const backendData = {
      jwt: json.data.jwt,
      userProfile: json.data.user,
    };
    SetAccessToken(json.data.jwt);
    yield put(allActions.userLoginSuccess(backendData, action.formData)); // call reducer function
    yield navigationService.navigate(STACK_SCREEN.userIndex.name);
  } catch (e) {
    yield put(allActions.userLoginFailure(e.message));
  }
}

export function* userEmailLogin(action) {
  yield put(allActions.userLoadingChange(true, ""));
  try {
    const json = yield call(sagaApi.userEmailLogin, action); // call sagaApi function
    MyLog("userEmailLogin-mylog5", action, "returned backendData:", json);
    const backendData = {
      jwt: json.data.jwt,
      userProfile: json.data.user,
    };
    SetAccessToken(json.data.jwt);
    yield put(allActions.userLoginSuccess(backendData, action.formData)); // call reducer function
    yield navigationService.navigate(STACK_SCREEN.userIndex.name);
  } catch (e) {
    yield put(allActions.userLoginFailure(e.message));
  }
}

export function* userFirebaseEmailLogin(action) {
  yield put(allActions.userLoadingChange(true, ""));
  try {
    const json = yield call(sagaApi.userFirebaseEmailLogin, action); // call sagaApi function
    MyLog(
      "userFirebaseEmailLogin-mylog5",
      action,
      "returned backendData:",
      json
    );
    if (json.firebaseData && json.firebaseData.user) {
      yield put(allActions.userLoginSuccess(json, action.formData)); // call reducer function
      // yield navigationService.reset(STACK_SCREEN.userIndex.name);
      yield navigationService.navigate(STACK_SCREEN.userIndex.name);
    } else {
      yield put(
        allActions.userLoginFailure(
          json.firebaseData.error.message || "userLogin Error"
        )
      );
    }
  } catch (e) {
    yield put(allActions.userLoginFailure(e.message));
  }
}

export function* userLogout(action) {
  yield put(allActions.userLoadingChange(true, ""));
  try {
    const json = yield call(sagaApi.userLogout, action); // call sagaApi function
    MyLog("userLogout-saga:", action, "returned backendData:", json);
    yield put(allActions.userLogoutSuccess(json, action.formData)); // call reducer function
    SetAccessToken(null);
    yield navigationService.navigate(STACK_SCREEN.petIndex.name);
  } catch (e) {
    yield put(allActions.userLogoutFailure(e.message));
  }
}

export function* listUser(action) {
  try {
    const json = yield call(sagaApi.listUser, action); // call sagaApi function
    MyLog("Saga:listUser-mylog1", action.type, json);

    let newJsonData = json.data;

    // // handle extra data for _embed & _expand -- api data is incorrect
    // if (newJsonData[0]) {
    //   // let newJsonData = [...json.data];
    //   let tempData = [];

    //   // save stories to redux
    //   if (newJsonData[0].stories && newJsonData[0].stories.length > 0) {
    //     tempData = [];
    //     newJsonData.map(item => {
    //       tempData = [...tempData, ...item.stories]
    //       delete item.stories;
    //     })
    //     if (tempData.length > 0) {
    //       yield put(
    //         allActions.listStorySuccess(tempData, action.terms, action.nextPageToken)
    //       );
    //     }
    //   }
    //   // save pets to redux
    //   if (newJsonData[0].pets && newJsonData[0].pets.length > 0) {
    //     tempData = [];
    //     newJsonData.map(item => {
    //       tempData = [...tempData, ...item.pets]
    //       delete item.pets;
    //     })
    //     if (tempData.length > 0) {
    //       yield put(
    //         allActions.listPetSuccess(tempData, action.terms, action.nextPageToken)
    //       );
    //     }
    //   }
    //   // save comments to redux
    //   if (newJsonData[0].comments && newJsonData[0].comments.length > 0) {
    //     tempData = [];
    //     newJsonData.map(item => {
    //       tempData = [...tempData, ...item.comments]
    //       delete item.comments;
    //     })
    //     if (tempData.length > 0) {
    //       yield put(
    //         allActions.listCommentSuccess(tempData, action.terms, action.nextPageToken)
    //       );
    //     }
    //   }

    //   MyLog("Saga:listUser", json.data, newJsonData);
    // }

    yield put(
      allActions.listUserSuccess(
        newJsonData,
        action.terms,
        action.nextPageToken
      )
    ); // call reducer function
  } catch (e) {
    yield put(allActions.listUserFailure(e.message));
  }
}

export function* viewUser(action) {
  try {
    const json = yield call(sagaApi.viewUser, action); // call sagaApi function
    MyLog("Saga:viewUser-mylog1", action.type, json);

    let newJsonData = json.data;

    // // handle extra data for _embed & _expand
    // if (newJsonData.id) {
    //   // let newJsonData = [...json.data];

    //   // save stories to redux
    //   if (newJsonData.stories && newJsonData.stories.length > 0) {
    //     yield put(
    //       allActions.listStorySuccess(
    //         newJsonData.stories,
    //         action.terms,
    //         action.nextPageToken
    //       )
    //     );
    //     delete newJsonData.stories;
    //   }
    //   // save pets to redux
    //   if (newJsonData.pets && newJsonData.pets.length > 0) {
    //     yield put(
    //       allActions.listPetSuccess(
    //         newJsonData.pets,
    //         action.terms,
    //         action.nextPageToken
    //       )
    //     );
    //     delete newJsonData.pets;
    //   }
    //   // save comments to redux
    //   if (newJsonData.comments && newJsonData.comments.length > 0) {
    //     yield put(
    //       allActions.listCommentSuccess(
    //         newJsonData.comments,
    //         action.terms,
    //         action.nextPageToken
    //       )
    //     );
    //     delete newJsonData.comments;
    //   }

    //   MyLog("Saga:viewUser 2", json.data, newJsonData);
    // }

    // trade the user profile as one element of an array and add to list
    yield put(
      allActions.listUserSuccess(
        [newJsonData],
        action.terms,
        action.nextPageToken
      )
    ); // call reducer function
  } catch (e) {
    yield put(allActions.listUserFailure(e.message));
  }
}

export function* editUser(action) {
  yield put(allActions.userLoadingChange(true, ""));
  try {
    const json = yield call(sagaApi.editUser, action); // call sagaApi function
    MyLog("editUser-mylog3", action, json);
    yield put(allActions.editUserSuccess(json.data, action.formData)); // call reducer function
    if (action.formData.firstName) {
      yield navigationService.navigate(STACK_SCREEN.userIndex.name);
    }
  } catch (e) {
    yield put(allActions.editUserFailure(e.message));
  }
}
