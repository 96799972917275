/* eslint-disable react/prop-types */
import React from "react";
import { Text } from "react-native";
import { Card } from "react-native-elements";
import { Link } from "@react-navigation/native";

const PetResultsDetail = ({ petProfile, navigation }) => {
  return (
    <Card
      containerStyle={{ backgroundColor: "white", margin: 10, padding: 10 }}
      wrapperStyle={{ backgroundColor: "white" }}
    >
      <Card.Title>
        <Link to={"/pets/" + petProfile.id}>{petProfile.name}</Link>
      </Card.Title>
      {/* <Card.Divider /> */}
      <Card.Image
        style={{ padding: 1 }}
        source={{
          uri:
            petProfile.mainImageUrl ||
            "https://picsum.photos/320/180?ramdom=" + petProfile.id,
        }}
        onPress={() => navigation.navigate("PetShow", { id: petProfile.id })}
      />
      <Text style={{ marginBottom: 1 }}>
        {petProfile.age} years old, {petProfile.gender}
        {petProfile.editorChoice
          ? ",EditorChoice:" + petProfile.editorChoice
          : ""}
      </Text>
    </Card>
  );
};

// const styles = StyleSheet.create({
//   container: {
//     marginLeft: 15,
//   },
//   image: {
//     width: 250,
//     height: 120,
//     borderRadius: 4,
//     marginBottom: 5,
//   },
//   name: {
//     fontWeight: "bold",
//   },
// });

export default PetResultsDetail;
