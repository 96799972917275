/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import { ScrollView } from "react-native";
// import { Context } from '../../context/PetContext';
import PetForm from "../../components/PetForm";
import TopNavBar from "../../components/TopNavBar";
import BottomLink from "../../components/BottomLink";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../reduxStores/actions/allActions";
import { STACK_SCREEN, MyLog } from "../../constants/config";

const PetCreateScreen = ({ navigation }) => {
  const currentUser = useSelector((reduxState) => reduxState.users.myProfile);
  const allSpecies = useSelector((reduxState) => reduxState.pets.allSpecies);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!currentUser) {
      navigation.navigate(STACK_SCREEN.userLogin.name);
    }
    if (!allSpecies || allSpecies.length == 0) {
      dispatch(allActions.listSpeciesRequest());
    }
  }, [currentUser]);
  MyLog("allSpecies", allSpecies);

  if (!currentUser) {
    return null;
  }
  return (
    <ScrollView>
      <TopNavBar title={STACK_SCREEN.petCreate.title} />
      <PetForm
        onSubmit={(formData) => {
          if (!formData.name.trim()) {
            alert("Please Enter Pet Name");
            return false;
          }
          const newData = { ...formData, ...{ user: currentUser.id } };
          dispatch(allActions.addPetRequest(newData));
        }}
        allSpecies={allSpecies}
        currentUser={currentUser}
      />
      <BottomLink currentUser={currentUser} />
    </ScrollView>
  );
};
// need reset the form after add a record

export default PetCreateScreen;
