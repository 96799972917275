import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import { STACK_LINKING, STACK_SCREEN } from "../../constants/config";

import HomepageScreen from "../homepage";
import PetShowScreen from "../pet/show";
import PetCreateScreen from "../pet/create";
import PetEditScreen from "../pet/edit";
import PetIndexScreen from "../pet/index";

import StoryCreateScreen from "../story/create";
import StoryShowScreen from "../story/show";
import StoryEditScreen from "../story/edit";
import StoryIndexScreen from "../story/index";

import UserLoginScreen from "../user/login";
import UserRegisterScreen from "../user/register";
import UserLogoutScreen from "../user/logout";
import UserIndexScreen from "../user/index";
import UserProfileScreen from "../user/profile";

import OwnerShowScreen from "../owner/show";

import UserLoginWithGoogleScreen from "../user/loginWithGoogle";

export const screenComponents = {
  petCreate: PetCreateScreen,
  petEdit: PetEditScreen,
  petShow: PetShowScreen,
  petIndex: PetIndexScreen,

  storyCreate: StoryCreateScreen,
  storyEdit: StoryEditScreen,
  storyShow: StoryShowScreen,
  storyIndex: StoryIndexScreen,

  userLogin: UserLoginScreen,
  userLogout: UserLogoutScreen,
  userRegister: UserRegisterScreen,
  userLoginWithGoogle: UserLoginWithGoogleScreen,
  userIndex: UserIndexScreen,
  userProfile: UserProfileScreen,

  ownerShow: OwnerShowScreen,

  homepage: HomepageScreen,
};

const Stack = createStackNavigator();

export const StackNavigator = () => {
  // MyLog('StackNavigator screenComponents', screenComponents);
  return (
    <Stack.Navigator
      initialRouteName={STACK_LINKING.initialRouteName}
      screenOptions={{ headerShown: false }}
    >
      {Object.keys(STACK_SCREEN).map((key) => {
        return (
          <Stack.Screen
            name={STACK_SCREEN[key].name}
            component={screenComponents[key]}
            options={{ title: STACK_SCREEN[key].title }}
            key={key}
          />
        );
      })}

      {/* 
          <Stack.Screen name={STACK_SCREEN.petShow.name} component={PetShowScreen} options={{ title: STACK_SCREEN.petShow.title }} />

          <Stack.Screen name={STACK_SCREEN.petCreate.name} component={PetCreateScreen} options={{ title: STACK_SCREEN.petCreate.title }} />

          <Stack.Screen name={STACK_SCREEN.petEdit.name} component={PetEditScreen} options={{ title: STACK_SCREEN.petEdit.title }} />

          <Stack.Screen name={STACK_SCREEN.petIndex.name} component={PetIndexScreen} options={{ title: STACK_SCREEN.petIndex.title }} />

          <Stack.Screen name={STACK_SCREEN.storyCreate.name} component={StoryCreateScreen} options={{ title: STACK_SCREEN.storyCreate.title }} />

          <Stack.Screen name={STACK_SCREEN.storyShow.name} component={StoryShowScreen} options={{ title: STACK_SCREEN.storyShow.title }} />
          <Stack.Screen name={STACK_SCREEN.storyEdit.name} component={StoryEditScreen} options={{ title: STACK_SCREEN.storyEdit.title }} />

          <Stack.Screen name={STACK_SCREEN.userLogin.name} component={UserLoginScreen} options={{ title: STACK_SCREEN.userLogin.title }} />

          <Stack.Screen name={STACK_SCREEN.userRegister.name} component={UserRegisterScreen} options={{ title: STACK_SCREEN.userRegister.title }} />

          <Stack.Screen name={STACK_SCREEN.userLogout.name} component={UserLogoutScreen} options={{ title: STACK_SCREEN.userLogout.title }} />

          <Stack.Screen name={STACK_SCREEN.userLoginWithGoogle.name} component={UserLoginWithGoogleScreen} options={{ title: STACK_SCREEN.userLoginWithGoogle.title }} />

          <Stack.Screen name={STACK_SCREEN.userIndex.name} component={UserIndexScreen} options={{ title: STACK_SCREEN.userIndex.title }} />

          <Stack.Screen name={STACK_SCREEN.ownerShow.name} component={OwnerShowScreen} options={{ title: STACK_SCREEN.ownerShow.title }} /> */}
    </Stack.Navigator>
  );
};
