/* eslint-disable react/prop-types */
import React from "react";
import { View, Platform, StyleSheet, FlatList } from "react-native";
import PetResultsDetail from "./PetResultsDetail";
import { Link, useNavigation } from "@react-navigation/native";

const PetResultsList = ({ title, results }) => {
  if (!results.length) {
    return null;
  }
  const navigation = useNavigation();

  return (
    <View style={styles.container}>
      <Link to={"/petProfiles/" + title} style={styles.title}>
        {title}
      </Link>
      <FlatList
        horizontal={true}
        showsHorizontalScrollIndicator={Platform.OS == "web" ? true : false}
        data={results}
        keyExtractor={(result) => result.id.toString()}
        renderItem={({ item }) => {
          return <PetResultsDetail petProfile={item} navigation={navigation} />;
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginLeft: 15,
    marginBottom: 5,
  },
  container: {
    marginBottom: 10,
  },
});

// export default withNavigation(ResultsList);
export default PetResultsList;
