/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { ScrollView } from "react-native";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../reduxStores/actions/allActions";
import { STACK_SCREEN } from "../../constants/config";
import ProfileForm from "../../components/UserProfileForm";
import TopNavBar from "../../components/TopNavBar";

const UserProfileScreen = ({ navigation }) => {
  const currentUser = useSelector((reduxState) => reduxState.users.myProfile);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentUser) {
      navigation.navigate(STACK_SCREEN.userLogin.name);
    } else {
      // dispatch(allActions.listPetRequest({ userId: currentUser.id, isMyItem: true }));
    }
  }, []);

  return (
    <ScrollView>
      <TopNavBar title={STACK_SCREEN.userProfile.title} />
      <ProfileForm
        onSubmit={(formData) => {
          dispatch(allActions.editUserRequest(formData));
        }}
        navigation={navigation}
        currentUser={currentUser}
      />
    </ScrollView>
  );
};

// const styles = StyleSheet.create({});

export default UserProfileScreen;
