import { createReducer } from "reduxsauce";
import { actionTypes } from "../actions/allActions";
import Immutable from "seamless-immutable";
import { MyLog, getUniqueMergeArray } from "../../helper/commonHelper";

// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  allItems: [],
  likedItems: [],
  myItems: [],
  allFiles: [],
  allSpecies: [],
  allComments: [],
  keywords: [],
  extraInfo: {
    etag: null,
    loading: false,
    nextPageToken: null,
    resultsPerPage: 5,
    totalResults: null,
    errorMsg: null,
  },
});

// const getUniqueItemArray = (existItems, newItems) => {
//   const uniqueNewItems = newItems.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
//   MyLog('newItems uniqueNewItems', newItems, uniqueNewItems)
//   const noDuplicateItems = existItems.filter((item) => {
//     if (uniqueNewItems.find((data) => data.id == item.id)) {
//       return false;
//     } else {
//       return true;
//     }
//   });
//   return [...noDuplicateItems, ...uniqueNewItems];
// };

const listPetSuccess = (state = INITIAL_STATE, action) => {
  MyLog("petReducer listPetSuccess", action);
  if (action.terms?.isMyItem) {
    return listMyPetSuccess(state, action);
  }
  const allItems = getUniqueMergeArray(state.allItems, action.allData);

  // const allItems = (action.terms?.appendToAll) ? getUniqueItemArray(state.allItems, action.allData) : action.allData;

  // const allItems = action.terms.appendToAll
  //   ? [...state.allItems, ...action.allData]
  //   : action.allData;
  return {
    ...state,
    ...{
      allItems: allItems,
      keywords: [
        action.keyword,
        ...state.keywords.filter((t) => t !== action.keyword),
      ],
      extraInfo: {
        loading: false,
        // nextPageToken: action.allData.nextPageToken,
        // resultsPerPage: action.allData.pageInfo.resultsPerPage,
        // totalResults: action.allData.pageInfo.totalResults,
        // errorMsg: null,
        // etag: action.allData.etag,
      },
    },
  };
};

const listMyPetSuccess = (state = INITIAL_STATE, action) => {
  MyLog("petReducer listMyPetSuccess", action);

  const myItems = action.nextPageToken
    ? [...state.myItems, ...action.allData]
    : action.allData;
  return {
    ...state,
    ...{
      myItems: myItems,
      keywords: [
        action.keyword,
        ...state.keywords.filter((t) => t !== action.keyword),
      ],
      extraInfo: {
        loading: false,
        // nextPageToken: action.allData.nextPageToken,
        // resultsPerPage: action.allData.pageInfo.resultsPerPage,
        // totalResults: action.allData.pageInfo.totalResults,
        // errorMsg: null,
        // etag: action.allData.etag,
      },
    },
  };
};

const editPetSuccess = (state = INITIAL_STATE, action) => {
  MyLog("petReducer editPetSuccess", action);
  const allPets = state.allItems.map((pet) => {
    return pet.id === action.backendData.id ? action.backendData : pet;
  });
  const extraInfo = { ...state.extraInfo, ...{ loading: false } };
  return {
    ...state,
    ...{ allItems: allPets, extraInfo: extraInfo },
  };
};

const addPetSuccess = (state = INITIAL_STATE, action) => {
  MyLog("petReducer addPetSuccess", action);
  const allPets = [action.backendData, ...state.allItems];
  const extraInfo = { ...state.extraInfo, ...{ loading: false } };
  return {
    ...state,
    ...{ allItems: allPets, extraInfo: extraInfo },
  };
};

const delPetSuccess = (state = INITIAL_STATE, action) => {
  MyLog("petReducer delPetSuccess", action);
  const allPets = state.allItems.filter((pet) => {
    return pet.id !== action.formData.id;
  });
  return {
    ...state,
    ...{ allItems: allPets },
  };
};

const editPetFailure = (state = INITIAL_STATE, action) => {
  MyLog("userReducer editPetFailure", action);
  const extraInfo = {
    ...state.extraInfo,
    ...{ loading: false, errorMsg: action.error },
  };
  return {
    ...state,
    ...{
      extraInfo: extraInfo,
    },
  };
};

const addFileSuccess = (state = INITIAL_STATE, action) => {
  MyLog("petReducer addFileSuccess", action);
  const allFiles = [action.backendData, ...state.allFiles];
  return {
    ...state,
    ...{ allFiles: allFiles },
  };
};

const delFileSuccess = (state = INITIAL_STATE, action) => {
  MyLog("petReducer delFileSuccess", action);
  const allFiles = state.allFiles.filter((item) => {
    return item.id !== action.formData.id;
  });
  return {
    ...state,
    ...{ allFiles: allFiles },
  };
};

const delFileFailure = (state = INITIAL_STATE, action) => {
  MyLog("petReducer delFileFailure", action);
  if (action.error.indexOf("404") != -1) {
    const allFiles = state.allFiles.filter((item) => {
      return item.id !== action.formData.id;
    });
    return {
      ...state,
      ...{ allFiles: allFiles },
    };
  } else {
    return state;
  }
};

const listFileSuccess = (state = INITIAL_STATE, action) => {
  MyLog("petReducer listFileSuccess", action);
  const noDuplicateItems = state.allFiles.filter((item) => {
    if (action.allData.find((data) => data.id == item.id)) {
      return false;
    } else {
      return true;
    }
  });
  const allFiles = [...noDuplicateItems, ...action.allData];

  return {
    ...state,
    ...{
      allFiles: allFiles,
    },
  };
};

const listSpeciesSuccess = (state = INITIAL_STATE, action) => {
  MyLog("petReducer listSpeciesSuccess", action);
  return {
    ...state,
    ...{
      allSpecies: action.allData,
    },
  };
};

const petLoadingChange = (state = INITIAL_STATE, action) => {
  MyLog("petReducer petLoadingChange", action);
  const extraInfo = {
    ...state.extraInfo,
    ...{ loading: action.loading, errorMsg: null },
  };
  return {
    ...state,
    ...{
      extraInfo: extraInfo,
    },
  };
};

const listCommentSuccess = (state = INITIAL_STATE, action) => {
  MyLog("petReducer listCommentSuccess", action);

  const allComments = getUniqueMergeArray(state.allComments, action.allData);

  return {
    ...state,
    ...{
      allComments: allComments,
    },
  };
};

const addCommentSuccess = (state = INITIAL_STATE, action) => {
  MyLog("CommentReducer addCommentSuccess", action);
  const allComments = [action.backendData, ...state.allComments];
  const extraInfo = { ...state.extraInfo, ...{ loading: false } };
  return {
    ...state,
    ...{ allComments: allComments, extraInfo: extraInfo },
  };
};

// map our action types to our reducer functions
export const HANDLERS = {
  [actionTypes.LIST_PET_SUCCESS]: listPetSuccess,
  [actionTypes.EDIT_PET_SUCCESS]: editPetSuccess,
  [actionTypes.EDIT_PET_FAILURE]: editPetFailure,
  [actionTypes.ADD_PET_SUCCESS]: addPetSuccess,
  [actionTypes.DEL_PET_SUCCESS]: delPetSuccess,

  [actionTypes.ADD_FILE_SUCCESS]: addFileSuccess,
  [actionTypes.DEL_FILE_SUCCESS]: delFileSuccess,
  [actionTypes.DEL_FILE_FAILURE]: delFileFailure,
  [actionTypes.LIST_FILE_SUCCESS]: listFileSuccess,

  [actionTypes.LIST_SPECIES_SUCCESS]: listSpeciesSuccess,

  [actionTypes.PET_LOADING_CHANGE]: petLoadingChange,

  [actionTypes.LIST_COMMENT_SUCCESS]: listCommentSuccess,
  [actionTypes.ADD_COMMENT_SUCCESS]: addCommentSuccess,
  // [actionTypes.LIKE_ITEM_SUCCESS]: likeItem,
  // [actionTypes.UNLIKE_ITEM_SUCCESS]: unlikeItem,
  // [actionTypes.DELETE_ITEM_SUCCESS]: deleteItem,
  // [actionTypes.SET_FILTER_KEY_SUCCESS]: setFilterKey,
  // [actionTypes.SHOW_LOADING]: showLoading,
};

export default createReducer(INITIAL_STATE, HANDLERS);
