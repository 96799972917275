/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { View, ScrollView } from "react-native";
import { Button, Text, Icon } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "@react-navigation/native";

import allActions from "../../reduxStores/actions/allActions";
import BottomLink from "../../components/BottomLink";
import PetResultsList from "../../components/PetResultsList";
import TopNavBar from "../../components/TopNavBar";
import { STACK_SCREEN, COLORS, MyLog } from "../../constants/config";
import StoryList from "../../components/StoryList";
import MyMap from "../../components/MyMap";

const UserIndexScreen = ({ navigation }) => {
  const dispatch = useDispatch();
  // const myPets = useSelector((reduxState) => reduxState.pets.myItems);
  // // const [searchResults, setSearchResult] = useState(myPets);
  // const myStories = useSelector((reduxState) => reduxState.stories.myItems);

  const currentUser = useSelector((reduxState) => reduxState.users.myProfile);
  const myPets = currentUser?.pets;
  const myStories = currentUser?.stories;

  useEffect(() => {
    if (currentUser) {
      dispatch(
        allActions.viewUserRequest({
          id: currentUser.id,
          // _embed: "pets,stories,comments|userId",
          isMyItem: true,
        })
      );
    }
    MyLog("force useEffect user.index2 currentUser", currentUser);
  }, []);

  useEffect(() => {
    const screenOnFocus = navigation.addListener("focus", () => {
      // check login & auto-login use local token
      MyLog("force login useEffect focus1", new Date());
      if (!currentUser) {
        navigation.navigate(STACK_SCREEN.userLogin.name);
      }
    });
    return screenOnFocus; //returned as the cleanup function in the effect
  }, [currentUser]);

  if (!myPets) {
    return <></>;
  }

  const sortedMyPets = [...myPets].sort((a, b) => {
    return b.priority - a.priority;
  });
  const sortedMyStories = [...myStories].sort((a, b) => {
    return new Date(b.updated_at) - new Date(a.updated_at);
  });

  const UserMainLinks = () => {
    return (
      <View
        style={{
          margin: 10,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "flex-start",
          textAlignVertical: "top",
        }}
      >
        <Link to="/pets/create" style={{ marginRight: 15, color: COLORS.info }}>
          <Icon
            name="paw"
            type="font-awesome"
            size={13}
            iconStyle={{ marginRight: 3, color: COLORS.info }}
          />
          Add Pet
        </Link>
        {myPets && myPets.length > 0 && (
          <Link
            to={"/stories/create?petId=" + myPets[0].id}
            style={{ marginRight: 15, color: COLORS.info }}
          >
            <Icon
              name="edit"
              type="font-awesome"
              size={13}
              iconStyle={{ marginRight: 3, color: COLORS.info }}
            />
            Write Story
          </Link>
        )}
        <Link
          to="/user/profile"
          style={{ marginRight: 15, color: COLORS.info }}
        >
          <Icon
            name="cog"
            type="font-awesome"
            size={13}
            iconStyle={{ marginRight: 3, color: COLORS.info }}
          />
          Settings
        </Link>
        <Link to="/user/logout" style={{ marginRight: 3, color: COLORS.info }}>
          <Icon
            name="arrow-right"
            type="font-awesome"
            size={13}
            iconStyle={{ marginRight: 3, color: COLORS.info }}
          />
          Logout
        </Link>
      </View>
    );
  };

  return (
    <ScrollView>
      <TopNavBar title={STACK_SCREEN.petIndex.appTitle} />
      <Text h4 style={{ alignSelf: "center", marginTop: 20 }}>
        Hi, {currentUser?.first_name || currentUser?.username}
      </Text>
      <UserMainLinks />

      <PetResultsList results={sortedMyPets || []} title="My Pets" />

      {sortedMyStories.length > 0 && (
        <>
          <Text style={{ fontSize: 18, fontWeight: "bold", margin: 15 }}>
            Pet Stories
          </Text>
          <StoryList stories={sortedMyStories} navigation={navigation} />
        </>
      )}

      {myPets?.length == 0 && (
        <Text style={{ alignSelf: "center", marginTop: 20, color: "red" }}>
          You do not add any pet yet
        </Text>
      )}
      <Button
        icon={{
          name: "edit",
          type: "font-awesome",
          size: 15,
          color: "white",
        }}
        // type="outline"
        title="Add a Pet"
        // titleStyle={{ color: "red" }}
        buttonStyle={{
          // width: "60%",
          margin: 40,

          alignSelf: "center",
        }}
        onPress={() => {
          navigation.navigate(STACK_SCREEN.petCreate.name);
        }}
      />

      <MyMap />

      <BottomLink currentUser={currentUser} />
    </ScrollView>
  );
};

export default UserIndexScreen;
