/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { ScrollView } from "react-native";
// import { Context } from '../context/StoryContext';
import { Text } from "react-native-elements";
import TopNavBar from "../../components/TopNavBar";
import { MySubmitButton } from "../../helper/commonHelper";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../reduxStores/actions/allActions";
import { STACK_SCREEN, MyLog } from "../../constants/config";

const UserLogoutScreen = ({ navigation }) => {
  const currentUser = useSelector((reduxState) => reduxState.users.myProfile);
  const userExtraInfo = useSelector((reduxState) => reduxState.users.extraInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    const screenOnFocus = navigation.addListener("focus", () => {
      // check login & auto-login use local token
      MyLog("force logout useEffect focus", new Date());
      if (!currentUser) {
        navigation.navigate(STACK_SCREEN.userLogin.name);
      }
    });
    return screenOnFocus; //returned as the cleanup function in the effect
  }, [currentUser]);

  return (
    <ScrollView>
      <TopNavBar title={STACK_SCREEN.userLogout.title} />
      <Text style={{ alignSelf: "center", marginTop: 20 }}>
        Hi, {currentUser?.firstName}
      </Text>
      <Text h4 style={{ alignSelf: "center", marginTop: 20 }}>
        Are you sure you want
      </Text>
      <Text h4 style={{ alignSelf: "center", marginTop: 20 }}>
        sign out / logout?
      </Text>
      <Text style={{ alignSelf: "center", marginTop: 20 }}>
        You will need to login again next time
      </Text>

      <MySubmitButton
        iconName="arrow-right"
        title="Logout"
        isLoading={userExtraInfo.loading}
        message={userExtraInfo.errorMsg}
        buttonStyle={{
          backgroundColor: "rgb(218, 68, 90)",
          paddingLeft: 20,
          paddingRight: 20,
          width: "auto",
        }}
        onSubmit={(formData) => {
          return dispatch(allActions.userLogoutRequest(formData));
        }}
      />
    </ScrollView>
  );
};

export default UserLogoutScreen;
