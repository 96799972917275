/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { ScrollView } from "react-native";
import { Link } from "@react-navigation/native";
import { Button, Card, Text } from "react-native-elements";
import TopNavBar, { PageHeader } from "../../components/TopNavBar";
import BottomLink from "../../components/BottomLink";
import { STACK_SCREEN, MyLog } from "../../constants/config";
import Comment from "../../components/Comment";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../reduxStores/actions/allActions";
import PetImageCarousel from "../../components/PetImageCarousel";
import LikeShareIcon from "../../components/LikeShareIcon";
import StoryList from "../../components/StoryList";
import {
  MyRowView,
  MyFormattedTime,
  getTruncatedText,
} from "../../helper/commonHelper";
import { Platform } from "react-native";

const StoryShowScreen = ({ route, navigation }) => {
  const storyId = parseInt(route.params?.id);
  const dispatch = useDispatch();

  const allPets = useSelector((reduxState) => reduxState.pets.allItems);
  let currentPet = allPets.find(
    (p) => p.stories && p.stories.find((s) => s.id === storyId)
  );
  // const currentStory = currentPet?.stories.find((s) => s.id === storyId);

  const allStories = useSelector((reduxState) => reduxState.stories.allItems);
  const currentStory = allStories.find((s) => s.id === storyId);
  if (!currentPet) {
    currentPet = currentStory.pet;
  }

  const relatedStories = currentPet.stories
    ? currentPet.stories.find((s) => s.id !== storyId)
    : [];

  // MyLog("currentStory", currentStory);
  // const currentPet = allPets.find((item) => item.id === currentStory?.petId);
  // const relatedStories = allStories
  //   ? allStories.filter(
  //       (item) => item.petId == currentStory?.petId && item.id != storyId
  //     )
  //   : [];
  // const currentUser = useSelector((reduxState) => reduxState.users.myProfile);

  // const allFiles = useSelector((reduxState) => reduxState.pets.allFiles);
  // const currentFiles = allFiles
  //   ? allFiles.filter((item) => item.storyId == storyId)
  //   : [];

  // MyLog("currentFiles", currentStory.files);

  // MyLog('saga FIREBASE_SDK_KEY2', JSON.stringify(FIREBASE_SDK_KEY2))

  const scrollRef = React.useRef();

  useEffect(() => {
    MyLog("always get the story info from database");
    dispatch(
      allActions.listStoryRequest({
        id: storyId,
        // _embed: "files,comments",
        // _expand: "pet",
      })
    );

    if (currentStory && currentPet) {
      navigation.setOptions({
        title:
          STACK_SCREEN.storyShow.title +
          ":" +
          currentStory.title +
          "(" +
          currentPet.species +
          ":" +
          currentPet.name +
          ")",
      });

      if (scrollRef.current) {
        scrollRef.current.scrollTo({ x: 0, y: 0, animated: true });
        if (Platform.OS == "web") {
          window.scrollTo(0, 0);
        }
      }
    }

    if (currentStory) {
      // update viewNum
      const timer1 = setTimeout(
        () =>
          dispatch(
            allActions.editStoryRequest({
              id: storyId,
              view_num: currentStory.view_num ? currentStory.view_num + 1 : 1,
            })
          ),
        4000
      );
      return () => {
        clearTimeout(timer1);
      };
    }
  }, [storyId]);

  if (!currentPet) {
    return <></>;
  }

  return (
    <ScrollView ref={scrollRef}>
      <PageHeader
        title={
          STACK_SCREEN.storyShow.title +
          ":" +
          currentStory.title +
          "(" +
          currentPet.species +
          ":" +
          currentPet.name +
          ")"
        }
        description={
          currentStory ? getTruncatedText(currentStory.detail) : null
        }
      />

      <TopNavBar title={STACK_SCREEN.storyShow.title} />
      <Text h4 style={{ alignSelf: "center" }}>
        {currentStory.title}
      </Text>
      <Card
        containerStyle={{
          marginBottom: 10,
          alignItems: "center",
          elevation: 0,
          borderColor: "white",
          borderWidth: 0,
          shadowColor: "rgba(0,0,0, 0.0)",
        }}
      >
        <MyRowView>
          <Link to={"/petProfiles/" + currentPet.species}>
            {currentPet.species}:{" "}
          </Link>
          <Link to={"/pets/" + currentPet.id}>{currentPet.name}</Link>

          <Text style={{ marginLeft: 10 }}></Text>
          <Text style={{ marginRight: 10 }}>{currentPet.gender}</Text>
          <MyFormattedTime date={currentPet.dob} showAge={true} type="DOB" />
          <Text></Text>
        </MyRowView>
        <Card.Divider />

        {/* <Image source={{ uri: currentPet.mainImageUrl || 'https://picsum.photos/320/180?ramdom=' + currentStory.id }} style={{ width: 320, height: 180 }} /> */}

        <Text style={{ fontSize: 18 }}>{currentStory.detail}</Text>
      </Card>
      <PetImageCarousel
        currentPet={currentPet}
        currentFiles={currentStory.files}
      />
      <MyFormattedTime
        date={currentStory.createdAt}
        showAge={true}
        type="list"
        prefix="Pet story added on "
        style={{ alignSelf: "center", color: "gray" }}
      />
      <LikeShareIcon
        currentPet={currentPet}
        currentUser={currentStory.user}
        currentStory={currentStory}
        category="petStory"
      />

      <Comment
        currentPet={currentPet}
        currentUser={currentStory.user}
        currentStory={currentStory}
        category="petStory"
      />
      {relatedStories && relatedStories.length > 0 && (
        <Text h4>Related Stories</Text>
      )}
      <StoryList stories={relatedStories} navigation={navigation} />

      <Link
        to={"/petStories/" + currentPet.species}
        style={{ alignContent: "flex-end", alignSelf: "flex-end", margin: 10 }}
      >
        {"More " + currentPet.species + " Stories >>"}
      </Link>

      {currentStory.user && currentStory.user.id == currentPet.user && (
        <>
          <Button
            icon={{
              name: "edit",
              type: "font-awesome",
              size: 15,
              color: "white",
            }}
            // type="outline"
            title="Write another story for this pet"
            // titleStyle={{ color: "cyan" }}
            buttonStyle={{
              // width: "80%",
              marginTop: 40,
              alignSelf: "center",
            }}
            onPress={() =>
              navigation.navigate(STACK_SCREEN.storyCreate.name, {
                petId: currentPet.id,
              })
            }
          />

          <Button
            icon={{
              name: "edit",
              type: "font-awesome",
              size: 15,
              color: "rgb(32, 137, 220)",
            }}
            type="outline"
            title="Edit or Delete"
            // titleStyle={{ color: "cyan" }}
            buttonStyle={{
              width: "60%",
              margin: 40,

              alignSelf: "center",
            }}
            onPress={() =>
              navigation.navigate(STACK_SCREEN.storyEdit.name, { id: storyId })
            }
          />
        </>
      )}

      <BottomLink currentUser={currentPet.user} />
    </ScrollView>
  );
};

export default StoryShowScreen;
