/* eslint-disable react/prop-types */
import React, { useEffect, useReducer } from "react";
// import { StyleSheet } from 'react-native';
import { Input } from "react-native-elements";
import { useSelector } from "react-redux";
// import allActions from "../reduxStores/actions/allActions";
import { INITIAL_STORY_DATA, MyLog } from "../constants/config";
import {
  MyInput,
  MyRadio,
  MyFormReducer,
  MySubmitButton,
} from "../helper/commonHelper";
import UploadImage from "./UploadImage";
// import SubmitButton from './SubmitButton';

const StoryForm = ({ onSubmit, currentStory, petId, currentUser }) => {
  if (petId) currentStory.petId = petId;
  const [storyDetails, setStoryReducer] = useReducer(MyFormReducer, {
    ...INITIAL_STORY_DATA,
    ...currentStory,
  });
  // const currentUser = useSelector(reduxState => reduxState.users.myProfile);
  // const allPets = useSelector((reduxState) => reduxState.pets.allItems);
  // const myPets = allPets.filter((item) => item.user === currentUser?.id);
  const myPets = currentUser.pets;
  const currentPet = myPets.find((item) => item.id === storyDetails.petId);
  // const [submitTimes, setSubmitTimes] = useState(0);
  const storyExtraInfo = useSelector(
    (reduxState) => reduxState.stories.extraInfo
  );
  MyLog("currentStory storyDetails", storyDetails);

  useEffect(() => {
    if (currentPet) {
      setStoryReducer({
        type: "change_species",
        payload: currentPet.species,
      });
    }
  }, [currentPet]);

  return (
    <>
      <MyInput
        propName="title"
        label="Story Title"
        keyboardType="default"
        formValues={storyDetails}
        setFormValues={setStoryReducer}
      />
      <Input label="Choose a Pet" inputContainerStyle={{ display: "none" }} />
      {myPets.map((item) => {
        return (
          <MyRadio
            propName="petId"
            value={item.id}
            label={item.species + ": " + item.name}
            key={item.id}
            formValues={storyDetails}
            setFormValues={setStoryReducer}
          />
        );
      })}

      {storyDetails.id && storyDetails.pet && currentPet ? (
        <>
          <UploadImage
            currentPet={currentPet}
            currentStory={storyDetails}
            currentUser={currentUser}
            setFormValues={setStoryReducer}
            category="petStory"
          />

          <MyInput
            propName="detail"
            label="Story Content"
            keyboardType="default"
            numberOfLines="5"
            formValues={storyDetails}
            setFormValues={setStoryReducer}
          />
          <MyInput
            propName="priority"
            keyboardType="decimal-pad"
            formValues={storyDetails}
            setFormValues={setStoryReducer}
          />
          {/* <MyInput
                        propName="species"
                        keyboardType='default'
                        formValues={storyDetails}
                        setFormValues={setStoryReducer}
                    /> */}
          {/* <MyInput
                        propName="id"
                        keyboardType='default'
                        formValues={storyDetails}
                        setFormValues={setStoryReducer}
                    /> */}
        </>
      ) : (
        []
      )}
      <MySubmitButton
        isSave={storyDetails.id}
        isLoading={storyExtraInfo.loading}
        message={storyExtraInfo.errorMsg}
        onSubmit={() => {
          return onSubmit(storyDetails);
        }}
        // formData={storyDetails}
      />
      {/* <Button
                icon={{
                    name: storyDetails.id ? "save" : "arrow-circle-right",
                    type: 'font-awesome',
                    size: 15,
                    color: "white"
                }}
                title={storyDetails.id ? "Save" : "Next"}
                loading={storyExtraInfo.loading && submitTimes > 0}
                disabled={storyExtraInfo.loading && submitTimes > 0}
                disabledStyle={{ backgroundColor: "rgb(87, 110, 121)" }}
                buttonStyle={{
                    width: "60%",
                    marginTop: 40,
                    marginBottom: 60,
                    alignSelf: 'center'
                }}
                onPress={() => { setSubmitTimes(submitTimes + 1); return onSubmit(storyDetails) }}
            /> */}
    </>
  );
};

StoryForm.defaultProps = {
  currentStory: INITIAL_STORY_DATA,
};

export default StoryForm;
