/* eslint-disable react/prop-types */
import React from "react";
import { View } from "react-native";
import { Text, Badge } from "react-native-elements";
import { Link } from "@react-navigation/native";
import { MyFormattedTime } from "../helper/commonHelper";

const CommentItem = ({ item, depth }) => {
  // const newDate = moment(item.createdAt, "YYYY-MM-DDTHH:mm:ssZ");
  // const formattedDate = newDate.fromNow();
  return (
    <View style={{ marginTop: 15, marginLeft: 25 * (depth - 1) }}>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "flex-start",
          marginBottom: 10,
        }}
      >
        {item.nickname == "anonymous" ? (
          <Text style={{ fontWeight: "bold" }}>{item.nickname}</Text>
        ) : (
          <Link to={"/owner/" + item.userId} style={{ fontWeight: "bold" }}>
            {item.nickname}
          </Link>
        )}
        <MyFormattedTime
          date={item.createdAt}
          showAge={true}
          type="comment"
          style={{ color: "#C0C0C0", marginLeft: 8 }}
        />
      </View>

      {item.isPublic ? <Text /> : <Badge value="private" />}
      <Text>{item.content}</Text>

      {/* <CommentForm isReply={true} /> */}
    </View>
  );
};

export default CommentItem;
