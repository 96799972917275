import { call, put } from "redux-saga/effects";
import sagaApi from "./sagaApiServices";
import navigationService from "./navigationService";
import { STACK_SCREEN, MyLog } from "../constants/config";
import allActions from "../reduxStores/actions/allActions";

// story
export function* addStory(action) {
  yield put(allActions.storyLoadingChange(true, ""));
  try {
    const json = yield call(sagaApi.addStory, action); // call sagaApi function
    MyLog("addStory-mylog5", action, json);
    yield put(allActions.addStorySuccess(json.data, action.formData)); // call reducer function

    // update storyNum
    if (json.data.petId) {
      yield call(sagaApi.editPet, {
        formData: { id: json.data.pet, story_num: "increment" },
      }); // call sagaApi function
    }

    yield navigationService.navigate(STACK_SCREEN.storyEdit.name, {
      id: json.data.id,
    });
  } catch (e) {
    yield put(allActions.addStoryFailure(e.message));
  }
}

export function* listStory(action) {
  yield put(allActions.storyLoadingChange(true, ""));
  try {
    const json = yield call(sagaApi.listStory, action); // call sagaApi function
    MyLog("Saga:listStory-mylog1", action.type, json);

    let newJsonData = json.data;
    // // handle extra data for _embed & _expand
    // if (json.data[0]) {
    //   // let newJsonData = [...json.data];
    //   let tempData = [];
    //   if (newJsonData[0].pet && newJsonData[0].pet.id) {
    //     tempData = [];
    //     newJsonData.map((item) => {
    //       tempData.push(item.pet);
    //       delete item.pet;
    //     });
    //     yield put(
    //       allActions.listPetSuccess(
    //         tempData,
    //         action.terms,
    //         action.nextPageToken
    //       )
    //     );
    //   }

    //   if (newJsonData[0].files && newJsonData[0].files.length > 0) {
    //     tempData = [];
    //     newJsonData.map((item) => {
    //       tempData = [...tempData, ...item.files];
    //       delete item.files;
    //     });
    //     if (tempData.length > 0) {
    //       yield put(
    //         allActions.listFileSuccess(
    //           tempData,
    //           action.terms,
    //           action.nextPageToken
    //         )
    //       );
    //     }
    //   }
    //   if (newJsonData[0].comments && newJsonData[0].comments.length > 0) {
    //     tempData = [];
    //     newJsonData.map((item) => {
    //       tempData = [...tempData, ...item.comments];
    //       delete item.comments;
    //     });
    //     if (tempData.length > 0) {
    //       yield put(
    //         allActions.listCommentSuccess(
    //           tempData,
    //           action.terms,
    //           action.nextPageToken
    //         )
    //       );
    //     }
    //   }

    //   // MyLog("Saga:listStory-pet", json.data, newJsonData);
    // }

    yield put(
      allActions.listStorySuccess(
        newJsonData,
        action.terms,
        action.nextPageToken
      )
    ); // call reducer function
  } catch (e) {
    yield put(allActions.listStoryFailure(e.message));
  }
}

export function* editStory(action) {
  yield put(allActions.storyLoadingChange(true, ""));
  try {
    const json = yield call(sagaApi.editStory, action); // call sagaApi function
    MyLog("editStory-mylog3", action, json);
    yield put(allActions.editStorySuccess(json.data, action.formData)); // call reducer function
    // yield navigationService.navigate(STACK_SCREEN.petShow.name, { id: action.formData.petId });
    yield navigationService.navigate(STACK_SCREEN.storyShow.name, {
      id: json.data.id,
    });
  } catch (e) {
    yield put(allActions.editStoryFailure(e.message));
  }
}

export function* delStory(action) {
  try {
    const json = yield call(sagaApi.delStory, action); // call sagaApi function
    MyLog("delStory-mylog3", action, json);
    yield put(allActions.delStorySuccess(json.data, action.formData)); // call reducer function

    // update storyNum
    if (action.formData.petId) {
      yield call(sagaApi.editPet, {
        formData: { id: action.formData.petId, storyNum: "decrement" },
      }); // call sagaApi function
    }

    yield navigationService.navigate(STACK_SCREEN.petShow.name, {
      id: action.formData.petId,
    });
  } catch (e) {
    yield put(allActions.delStoryFailure(e.message));
  }
}
