/**
 * For react native  web
 */
import { AppRegistry } from "react-native";
import App from "./ReduxApp";

AppRegistry.registerComponent("App", () => App);

AppRegistry.runApplication("App", {
  rootTag: document.getElementById("react-js-app"),
});
