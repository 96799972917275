/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageUploader from "react-images-upload";
import allActions from "../reduxStores/actions/allActions";
import { MyLog } from "../constants/config";
// import ApiMethods from "../middlewares/sagaApiServices";

export const UploadImage = ({
  currentPet,
  currentStory,
  currentUser,
  category,
  setFormValues,
}) => {
  const dispatch = useDispatch();
  const allFiles = useSelector((reduxState) => reduxState.pets.allFiles);
  const [displayImages, setDisplayImages] = useState([]);
  // const [imageKitAuth, setImageKitAuth] = useState();

  const handleChooseImages = async (imgFiles, imgBase64URLs) => {
    let newImgFiles = [];
    imgFiles.map((item, index) => {
      newImgFiles.push({
        name: imgFiles[index].name,
        size: imgFiles[index].size,
        type: imgFiles[index].type,
        userId: currentUser?.id,
        petId: currentPet?.id,
        storyId: currentStory?.id,
        category: category,
        uploadStatus: "pending",
        url: imgBase64URLs[index],
        data: imgFiles[index], // file object
      });
    });

    const uniqueImgFiles = newImgFiles.filter((item) => {
      if (
        displayImages.find(
          (data) => data.name == item.name && data.size == item.size
        )
      ) {
        return false;
      } else {
        return true;
      }
    });

    const allDisplayedImages = [...uniqueImgFiles, ...displayImages];
    setDisplayImages(allDisplayedImages);

    // if (!imageKitAuth) {
    //   setImageKitAuth(await ApiMethods.getImagekitAuth());
    // }

    MyLog("pictureFiles2newFiles", uniqueImgFiles);

    startUpload(allDisplayedImages);
  };

  const startUpload = (newDisplayImages) => {
    // const uploadedFiles = allFiles.filter(item => item.userId == currentUser.id && (item.petId == '' || item.petId == currentPet.id));
    const uploadedFiles = allFiles.filter((item) => {
      if (category == "petProfile") {
        return (
          item.user &&
          item.user.id == currentUser.id &&
          (!item.pet || item.pet.id == currentPet.id) &&
          item.category &&
          item.category.indexOf("petProfile") != -1
        );
      } else if (category == "petStory" && currentStory) {
        return (
          item.user.id == currentUser.id &&
          (!item.story || item.story.id == currentStory.id) &&
          item.category &&
          item.category.indexOf("petStory") != -1
        );
      }
      return false;
    });

    const allDisplayedImages = newDisplayImages
      ? newDisplayImages
      : displayImages;

    let remainFiles = allDisplayedImages.filter((item) => {
      if (
        uploadedFiles.find(
          (data) => data.name == item.name && data.size == item.size
        )
      ) {
        return false;
      } else {
        return true;
      }
    });

    const uploadingFiles = remainFiles.filter(
      (item) => item.uploadStatus == "uploading"
    );
    let uploadingNum = uploadingFiles.length;
    if (uploadingNum < 1) {
      // const reverseFiles = remainFiles.reverse(); // this changes the original remainFiles
      remainFiles
        .slice()
        .reverse()
        .forEach((item) => {
          if (uploadingNum < 1 && item.uploadStatus == "pending") {
            // MyLog("uploadingNum", uploadingNum, imageKitAuth);
            item.uploadStatus = "uploading";
            uploadingNum++;
            // item.imageKitAuth = imageKitAuth?.data;
            // process upload file and insert into database
            dispatch(allActions.addFileRequest(item));
          }
        });
    }

    setDisplayImages([...remainFiles, ...uploadedFiles]);
    MyLog("startUpload displayImages3", allDisplayedImages);

    if (uploadedFiles[0]) {
      if (currentStory && !currentStory.mainImageId) {
        setMainImage(uploadedFiles[uploadedFiles.length - 1]);
      } else if (currentPet && !currentPet.mainImageId) {
        setMainImage(uploadedFiles[uploadedFiles.length - 1]);
      }
    }
  };

  const setMainImage = (mainImage) => {
    setFormValues({ type: "change_main_image_url", payload: mainImage?.url });
    setFormValues({ type: "change_main_image_id", payload: mainImage?.id });
  };

  const delImage = (item) => {
    if (!item.confirmDel) {
      let newDisplayImage = [];
      displayImages.map((img) => {
        let newImg = { ...img };
        if (item.id == img.id) {
          newImg.confirmDel = true;
        } else {
          newImg.confirmDel = false;
        }
        newDisplayImage.push(newImg);
      });
      setDisplayImages(newDisplayImage);
    } else {
      setDisplayImages(
        displayImages.filter(
          (img) => !(img.name == item.name && img.size == item.size)
        )
      );
      dispatch(allActions.delFileRequest(item));
    }
  };

  useEffect(() => {
    startUpload();
    MyLog("UploadImage.web useEffect displayImages", displayImages);
  }, [allFiles]);

  const UploadingIcon = ({ item }) => {
    return (
      <span>
        {item.uploadStatus == "uploading" ? (
          <span className="text-primary">
            <i className="fas fa-spinner fa-spin mr-1"></i>
            <span className="small">Uploading</span>
          </span>
        ) : (
          <i className="far fa-pause-circle text-secondary"></i>
        )}
      </span>
    );
  };
  const ActionIcon = ({ item }) => {
    let isMainImage = false;
    if (category == "petProfile" && item.url == currentPet.main_image_url) {
      isMainImage = true;
    } else if (
      category == "petStory" &&
      item.url == currentStory.main_image_url
    ) {
      isMainImage = true;
    }
    return (
      <div>
        {isMainImage ? (
          <span className="text-primary">
            <i className="fas fa-star mr-1"></i>
            <span className="small">Main Image</span>
          </span>
        ) : (
          <>
            <span
              role="button"
              className="text-secondary"
              onClick={() => setMainImage(item)}
            >
              <i className="far fa-star mr-3"></i>
            </span>

            <span
              role="button"
              className={item.confirmDel ? "text-danger" : "text-secondary"}
              onClick={() => delImage(item)}
            >
              <i className="fas fa-trash-alt mr-1"></i>
              {item.confirmDel && <span className="small">Confirm</span>}
            </span>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {/* Doc: https://jakehartnell.github.io/react-images-upload/ */}
      <ImageUploader
        withIcon={true}
        withLabel={true}
        withPreview={false}
        buttonText="Choose & upload images for this pet"
        onChange={handleChooseImages}
        imgExtension={[
          ".jpg",
          ".jpeg",
          ".gif",
          ".png",
          ".webp",
          ".tif",
          ".heic",
          ".avif",
          ".tiff",
        ]}
        maxFileSize={30242880}
      />

      <div className="row upload-images">
        {displayImages.map((item, index) => {
          return (
            <React.Fragment key={item.name + index}>
              <div className="col-sm mb-2 text-center">
                <img
                  src={item.url}
                  width="200"
                  alt={item.name}
                  className="img-thumbnail"
                />
                <div className="action-icons">
                  {item.extra_info ? (
                    <ActionIcon item={item} />
                  ) : (
                    <UploadingIcon item={item} />
                  )}
                </div>
              </div>

              {(index + 1) % 8 == 0 && <div className="w-100"></div>}
            </React.Fragment>
          );
        })}
      </div>
      {/* <Text>{displayImages.length} currentUser:{currentUser?.id}   currentPet:{currentPet?.id}</Text> */}
    </>
  );
};

export default UploadImage;
