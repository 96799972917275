import React from "react";
import { Text, Platform } from "react-native";
import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import { Provider } from "react-redux";
// import { Route, Switch } from "react-router-dom";
// import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import createSagaMiddleware from "redux-saga";
import configureStore from "./reduxStores/configureStore";
import rootSaga from "./middlewares/saga";
import { navigationRef } from "./middlewares/navigationService";
// import { Provider as ContextProvider } from './context/PetContext';
import { STACK_LINKING } from "./constants/config";

import { StackNavigator, screenComponents } from "./screens/navigator/stack";
import { DrawerNavigator } from "./screens/navigator/drawer";
// import { BottomNavigator } from './screens/navigator/bottom';
import allActions from "./reduxStores/actions/allActions";

const sagaMiddleware = createSagaMiddleware();
const { store, persistor } = configureStore(sagaMiddleware, {});
sagaMiddleware.run(rootSaga);

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: "white",
  },
};

// load site settings in the beginning of the app
store.dispatch(allActions.listSiteSettingRequest());

const App = () => {
  return (
    <>
      {Platform.OS == "web" ? (
        <NavigationContainer
          theme={MyTheme}
          linking={STACK_LINKING}
          ref={navigationRef}
          fallback={<Text>Loading Pet Story ...</Text>}
        >
          <StackNavigator />
        </NavigationContainer>
      ) : (
        <>
          <NavigationContainer
            theme={MyTheme}
            linking={STACK_LINKING}
            ref={navigationRef}
          >
            <DrawerNavigator
              screenComponents={screenComponents}
              StackNavigator={StackNavigator}
            />
          </NavigationContainer>
          {/* <NavigationContainer>
            <BottomNavigator screenComponents={screenComponents} StackNavigator={StackNavigator} />
          </NavigationContainer> */}
        </>
      )}
    </>
  );
};

// export default App ;
const ReduxApp = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <ConnectedRouter history={ }> */}
        {/* <ContextProvider> */}
        <App />
        {/* </ContextProvider> */}
        {/* </ConnectedRouter> */}
      </PersistGate>
    </Provider>
  );
};
export default ReduxApp;
