/* eslint-disable react/prop-types */
import React from "react";
import { Icon } from "react-native-elements";
import { useNavigation } from "@react-navigation/native";
import { Header } from "react-native-elements";
import { STACK_SCREEN } from "../constants/config";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const NavBarTopLeft = () => {
  const navigation = useNavigation();
  return (
    <Icon
      name="home"
      type="font-awesome"
      color="#fff"
      // onPress={() => alert('ea')}
      onPress={() => navigation.navigate(STACK_SCREEN.homepage.name)}
    />
  );
};

const TopNavBar = ({ title }) => {
  const navigation = useNavigation();
  const currentUser = useSelector((reduxState) => reduxState.users.myProfile);

  return (
    <Header
      leftComponent={NavBarTopLeft}
      centerComponent={{
        text: title,
        style: { color: "#fff" },
        onPress: () => navigation.navigate(STACK_SCREEN.petCreate.name),
        // onPress: () => navigation.navigate(currentUser ? STACK_SCREEN.userIndex.name : STACK_SCREEN.userLogin.name)
      }}
      rightComponent={{
        icon: "user",
        type: "font-awesome",
        color: "#fff",
        raised: false,
        onPress: () =>
          navigation.navigate(
            currentUser
              ? STACK_SCREEN.userIndex.name
              : STACK_SCREEN.userLogin.name
          ),
        // onPress: () => navigation.dispatch(DrawerActions.toggleDrawer())
      }}
    />
  );
};

export const PageHeader = ({ title, description, ogType }) => {
  return (
    <Helmet>
      <title>{title || STACK_SCREEN.homepage.title}</title>
      <meta
        name="description"
        content={description || STACK_SCREEN.homepage.description}
      />
      <meta property="og:type" content={ogType || "article"} />
    </Helmet>
  );
};

export default TopNavBar;
