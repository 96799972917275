/* eslint-disable react/prop-types */
import React from "react";
import { ListItem, Text, Image } from "react-native-elements";
import { Link } from "@react-navigation/native";
import { STACK_SCREEN } from "../constants/config";

const PetList = ({ navigation, pets }) => {
  if (!pets.length) {
    return null;
  }
  return (
    <>
      {pets ? (
        pets.map((item, i) => {
          // const petProfile = pets?.find(pet => item.petId == pet.id);

          return (
            <ListItem key={i} bottomDivider>
              {/* <Icon name="clipboard" type='font-awesome' /> */}
              {/* <Avatar source={{ uri: item.mainImageUrl }} /> */}
              <Image
                style={{ height: 100, width: 150 }}
                source={{
                  uri:
                    item.mainImageUrl ||
                    "https://picsum.photos/100/60?ramdom=" + item.id,
                }}
                onPress={() =>
                  navigation.navigate(STACK_SCREEN.petShow.name, {
                    id: item.id,
                  })
                }
              />

              <ListItem.Content>
                <ListItem.Title>
                  <Link to={"/petProfiles/" + item.species}>
                    {item.species}
                  </Link>
                  :<Link to={"/pets/" + item.id}>{item.name}</Link>
                </ListItem.Title>

                <ListItem.Subtitle style={{ marginTop: 10 }}>
                  {item.gender || "Male"}, Age: {item.age || 1}
                  {item.age}, Stories: {item.storyNum || 0}, Comments:{" "}
                  {item.commentNum || 0}, Likes: {item.likeNum || 0}, Updated
                  At: {item.updated_at}
                </ListItem.Subtitle>
              </ListItem.Content>
              <ListItem.Chevron name="caret-right" type="font-awesome" />
            </ListItem>
          );
        })
      ) : (
        <Text>Loading...</Text>
      )}
    </>
  );
};

export default PetList;
