/* eslint-disable react/prop-types */
import React from "react";
import { ScrollView } from "react-native";
// import { Context } from '../context/StoryContext';
import LoginForm from "../../components/UserLoginForm";
import TopNavBar from "../../components/TopNavBar";
import BottomLink from "../../components/BottomLink";
import { useDispatch } from "react-redux";
import allActions from "../../reduxStores/actions/allActions";
import { STACK_SCREEN } from "../../constants/config";

// import firebase from 'firebase/app'
// import {
//   GoogleSignin,
//   statusCodes,
// } from '@react-native-community/google-signin';

// Somewhere in your code

const UserLoginScreen = ({ navigation }) => {
  // const petId = route.params?.petId;
  // MyLog('UserLoginScreen route2', route, navigation);
  const dispatch = useDispatch();

  // GoogleSignin.configure();
  return (
    <ScrollView>
      {/* <GoogleSigninButton
                style={{ width: 192, height: 48 }}
                size={GoogleSigninButton.Size.Wide}
                color={GoogleSigninButton.Color.Dark}
                onPress={this.GoogleLogin}
                // disabled={this.state.isSigninInProgress}
                /> */}
      <TopNavBar title={STACK_SCREEN.userLogin.title} />
      <LoginForm
        onSubmit={(formData) => {
          dispatch(allActions.userLoginRequest(formData));
        }}
        navigation={navigation}
      />
      <BottomLink currentUser={null} />
    </ScrollView>
  );
};

export default UserLoginScreen;
