/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { MyLog } from "../constants/config";

export const SetAccessToken = async (value) => {
  try {
    if (value !== null) {
      await localStorage.setItem("mainApiAccessToken", value);
    } else {
      await localStorage.removeItem("mainApiAccessToken");
    }
    return true;
  } catch (error) {
    // Error saving data
    MyLog("SetAccessToken error", error);
  }
};

export const GetAccessToken = () => {
  try {
    const value = localStorage.getItem("mainApiAccessToken");
    return value;
  } catch (error) {
    // Error saving data
    MyLog("GetAccessToken error", error);
    return false;
  }
};
