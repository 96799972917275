import { createReducer } from "reduxsauce";
import { actionTypes } from "../actions/allActions";
import Immutable from "seamless-immutable";
import { MyLog } from "../../constants/config";

// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  allItems: [],
  likedItems: [],
  myItems: [],
  keywords: [],
  extraInfo: {
    etag: null,
    loading: false,
    nextPageToken: null,
    resultsPerPage: 5,
    totalResults: null,
    errorMsg: null,
  },
});

const addStorySuccess = (state = INITIAL_STATE, action) => {
  MyLog(" addStorySuccess", action);
  const myItems = [action.backendData, ...state.myItems];
  const allItems = [action.backendData, ...state.allItems];
  const extraInfo = { ...state.extraInfo, ...{ loading: false } };
  return {
    ...state,
    ...{ myItems: myItems, allItems: allItems, extraInfo: extraInfo },
  };
};

const listStorySuccess = (state = INITIAL_STATE, action) => {
  MyLog("storyReducer listStorySuccess", action);
  if (action.terms?.isMyItem) {
    return listMyStorySuccess(state, action);
  }
  const noDuplicateItems = state.allItems.filter((item) => {
    if (action.allData.find((data) => data.id == item.id)) {
      return false;
    } else {
      return true;
    }
  });
  const allItems = [...noDuplicateItems, ...action.allData];
  const extraInfo = { ...state.extraInfo, ...{ loading: false } };
  return {
    ...state,
    ...{
      allItems: allItems,
      keywords: [
        action.keyword,
        ...state.keywords.filter((t) => t !== action.keyword),
      ],
      extraInfo: extraInfo,
    },
  };
};

const listMyStorySuccess = (state = INITIAL_STATE, action) => {
  MyLog("storyReducer listMyStorySuccess", action);

  const myItems = action.nextPageToken
    ? [...state.myItems, ...action.allData]
    : action.allData;
  const extraInfo = { ...state.extraInfo, ...{ loading: false } };
  return {
    ...state,
    ...{
      myItems: myItems,
      keywords: [
        action.keyword,
        ...state.keywords.filter((t) => t !== action.keyword),
      ],
      extraInfo: extraInfo,
    },
  };
};

const editStorySuccess = (state = INITIAL_STATE, action) => {
  MyLog("storyReducer editStorySuccess", action);
  const myItems = state.myItems.map((item) => {
    return item.id === action.backendData.id ? action.backendData : item;
  });
  const allItems = state.allItems.map((item) => {
    return item.id === action.backendData.id ? action.backendData : item;
  });
  const extraInfo = { ...state.extraInfo, ...{ loading: false } };
  return {
    ...state,
    ...{
      myItems: myItems,
      allItems: allItems,
      extraInfo: extraInfo,
    },
  };
};

const editStoryFailure = (state = INITIAL_STATE, action) => {
  MyLog("userReducer editStoryFailure", action);
  const extraInfo = {
    ...state.extraInfo,
    ...{ loading: false, errorMsg: action.error },
  };
  return {
    ...state,
    ...{
      extraInfo: extraInfo,
    },
  };
};

const delStorySuccess = (state = INITIAL_STATE, action) => {
  MyLog("delStorySuccess", action);
  const myItems = state.myItems.filter((item) => {
    return item.id !== action.formData.id;
  });
  const allItems = state.allItems.filter((item) => {
    return item.id !== action.formData.id;
  });
  return {
    ...state,
    ...{ myItems: myItems, allItems: allItems },
  };
};
const storyLoadingChange = (state = INITIAL_STATE, action) => {
  MyLog(" storyLoadingChange", action);
  const extraInfo = {
    ...state.extraInfo,
    ...{ loading: action.loading, errorMsg: null },
  };
  return {
    ...state,
    ...{
      extraInfo: extraInfo,
    },
  };
};
// map our action types to our reducer functions
export const HANDLERS = {
  [actionTypes.LIST_STORY_SUCCESS]: listStorySuccess,
  [actionTypes.ADD_STORY_SUCCESS]: addStorySuccess,
  [actionTypes.EDIT_STORY_SUCCESS]: editStorySuccess,
  [actionTypes.EDIT_STORY_FAILURE]: editStoryFailure,
  [actionTypes.DEL_STORY_SUCCESS]: delStorySuccess,

  [actionTypes.STORY_LOADING_CHANGE]: storyLoadingChange,
  // [actionTypes.LIKE_ITEM_SUCCESS]: likeItem,
  // [actionTypes.UNLIKE_ITEM_SUCCESS]: unlikeItem,
  // [actionTypes.DELETE_ITEM_SUCCESS]: deleteItem,
  // [actionTypes.SET_FILTER_KEY_SUCCESS]: setFilterKey,
  // [actionTypes.SHOW_LOADING]: showLoading,
};

export default createReducer(INITIAL_STATE, HANDLERS);
