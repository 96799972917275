/* eslint-disable react/prop-types */
import React, { useState, useReducer } from "react";
import { View, Platform } from "react-native";
import { Input, Button } from "react-native-elements";
import { useSelector } from "react-redux";
// import allActions from "../reduxStores/actions/allActions";
import { INITIAL_PET_DATA, MyLog } from "../constants/config";
import {
  MyInput,
  MyRadio,
  MyFormReducer,
  MySubmitButton,
  MyRowView,
} from "../helper/commonHelper";
import UploadImage from "./UploadImage";
import DateTimePicker from "@react-native-community/datetimepicker";

const PetForm = ({ onSubmit, currentPet, currentUser, allSpecies }) => {
  const [petDetails, setPetReducer] = useReducer(MyFormReducer, {
    ...INITIAL_PET_DATA,
    ...currentPet,
  });
  const [showAllSpecies, setShowAllSpecies] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const petExtraInfo = useSelector((reduxState) => reduxState.pets.extraInfo);

  const onDobChange = (event, selectedDate) => {
    setShowDatePicker(Platform.OS === "ios");
    if (selectedDate) {
      MyLog("onDobChange  selectedDate", petDetails.dob, selectedDate);
      setPetReducer({ type: "change_dob", payload: selectedDate });
    }
  };

  MyLog("PetForm petDetails", petDetails);

  return (
    <>
      <MyInput
        propName="name"
        label="Pet Name"
        keyboardType="default"
        formValues={petDetails}
        setFormValues={setPetReducer}
      />
      <Input label="Species" inputContainerStyle={{ display: "none" }} />
      {(!petDetails.id || showAllSpecies) &&
        allSpecies &&
        allSpecies.map((item) => {
          return (
            <MyRadio
              key={item.id}
              propName="species"
              value={item.name}
              formValues={petDetails}
              setFormValues={setPetReducer}
            />
          );
        })}

      {!!petDetails.id && (
        <>
          {!showAllSpecies && (
            <>
              <MyRadio
                propName="species"
                value={petDetails.species}
                formValues={petDetails}
                setFormValues={setPetReducer}
              />

              <Button
                title="Change species"
                type="clear"
                onPress={() => setShowAllSpecies(true)}
              />
            </>
          )}

          <UploadImage
            currentPet={petDetails}
            currentUser={currentUser}
            setFormValues={setPetReducer}
            category="petProfile"
          />

          <MyInput
            propName="detail"
            keyboardType="default"
            numberOfLines="3"
            formValues={petDetails}
            setFormValues={setPetReducer}
          />
          <MyInput
            propName="youtubeUrl"
            keyboardType="default"
            formValues={petDetails}
            setFormValues={setPetReducer}
          />

          <Input
            label="Pet's Date of birth"
            inputContainerStyle={{ display: "none" }}
          />

          {Platform.OS === "web" && (
            <input
              type="date"
              style={{
                border: 0,
                borderBottom: "1px solid gray",
                margin: "10px",
              }}
              value={new Date(petDetails.dob).toISOString().substr(0, 10)}
              onChange={(event) => {
                MyLog(event.target.value);
                setPetReducer({
                  type: "change_dob",
                  payload: new Date(event.target.value),
                });
              }}
            />
          )}

          {Platform.OS === "ios" || showDatePicker ? (
            <View style={{ marginBottom: 15 }}>
              <DateTimePicker
                dateID="dateTimePicker"
                value={new Date(petDetails.dob)}
                maximumDate={new Date()}
                mode={"date"}
                is24Hour={true}
                display="default"
                onChange={onDobChange}
              />
            </View>
          ) : (
            Platform.OS !== "web" && (
              <MyRowView>
                <Button
                  title={
                    "" +
                    new Date(petDetails.dob).toISOString().substr(0, 10) +
                    ""
                  }
                  buttonStyle={{
                    marginLeft: 10,
                    marginRight: 10,
                    paddingLeft: 25,
                    paddingRight: 25,
                  }}
                  type="outline"
                  onPress={() => setShowDatePicker(true)}
                />
              </MyRowView>
            )
          )}

          {/* 

        <MyInput
          propName="age"
          keyboardType='decimal-pad'
          formValues={petDetails}
          setFormValues={setPetReducer}
        /> */}
          <MyInput
            propName="editorChoice"
            keyboardType="decimal-pad"
            formValues={petDetails}
            setFormValues={setPetReducer}
          />
          <MyInput
            propName="priority"
            keyboardType="decimal-pad"
            formValues={petDetails}
            setFormValues={setPetReducer}
          />
          <Input label="Gender" inputContainerStyle={{ display: "none" }} />
          <MyRadio
            propName="gender"
            value="Male"
            formValues={petDetails}
            setFormValues={setPetReducer}
          />
          <MyRadio
            propName="gender"
            value="Female"
            formValues={petDetails}
            setFormValues={setPetReducer}
          />

          {/* <MyInput
          propName="id"
          keyboardType='default'
          formValues={petDetails}
          setFormValues={setPetReducer}
        /> */}
        </>
      )}

      <MySubmitButton
        isSave={petDetails.id}
        isLoading={petExtraInfo.loading}
        message={petExtraInfo.errorMsg}
        onSubmit={() => {
          setShowAllSpecies(false);
          return onSubmit(petDetails);
        }}
      />

      {/* <Text>currentUser:{currentUser?.id}   currentPet.userId:{currentPet?.userId} - LOADING: {petExtraInfo.loading ? 'LOADING' : 'NO'} </Text> */}
    </>
  );
};

PetForm.defaultProps = {
  currentPet: INITIAL_PET_DATA,
};

// const styles = StyleSheet.create({
//   input: {
//     fontSize: 18,
//     borderWidth: 1,
//     borderColor: "black",
//     marginBottom: 15,
//     padding: 5,
//     margin: 5,
//   },
//   label: {
//     fontSize: 20,
//     marginBottom: 5,
//     marginLeft: 5,
//   },
// });

export default PetForm;
