/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { View } from "react-native";
import { Text, Input, Button, CheckBox } from "react-native-elements";
import { MyLog as ConfigMyLog } from "../constants/config";
import moment from "moment";

export const MyFormReducer = (state, action) => {
  MyLog("MyFormReducer", action);
  const propName = action.type.replace("change_", "");
  switch (action.type) {
    case "change_name":
      return { ...state, name: action.payload };
    case "change_detail":
      return { ...state, detail: action.payload };
    default:
      if (propName in state) {
        const newValue = {};
        newValue[propName] = action.payload;
        return { ...state, ...newValue };
      } else {
        MyLog("MyFormReducer propName NOT in state", propName, state);
        return state;
      }
  }
};
export const MyInput = ({
  propName,
  label,
  keyboardType,
  formValues,
  setFormValues,
  numberOfLines,
  secureTextEntry,
}) => {
  const specialFields = ["username", "password", "email", "confirmPassword"];
  const autoCapitalize = specialFields.includes(propName)
    ? "none"
    : "sentences";
  const autoCorrect = specialFields.includes(propName) ? false : true;

  // MyLog('autoCapitalize', propName, autoCapitalize)

  return (
    <Input
      label={label || propName.charAt(0).toUpperCase() + propName.slice(1)}
      placeholder=""
      secureTextEntry={secureTextEntry ? true : false}
      value={formValues[propName] ? formValues[propName].toString() : ""}
      keyboardType={keyboardType || "default"}
      numberOfLines={numberOfLines ? parseInt(numberOfLines) : 1}
      multiline={numberOfLines ? true : false}
      textAlignVertical="top"
      autoCapitalize={autoCapitalize}
      autoCorrect={autoCorrect}
      // leftIcon={{ type: 'font-awesome', name: 'comment' }}
      onChangeText={(text) =>
        setFormValues({ type: "change_" + propName, payload: text })
      }
      inputStyle={{ width: "100%" }}
    />
  );
};

export const MyRadio = ({
  propName,
  label,
  value,
  formValues,
  setFormValues,
}) => {
  return (
    <CheckBox
      title={label || value.charAt(0).toUpperCase() + value.slice(1)}
      iconType="font-awesome"
      checkedIcon="dot-circle-o"
      uncheckedIcon="circle"
      checked={formValues[propName] == value}
      onPress={() =>
        setFormValues({ type: "change_" + propName, payload: value })
      }
    />
  );
};

export const MySubmitButton = ({
  isSave,
  isLoading,
  onSubmit,
  iconName,
  title,
  message,
  buttonStyle,
  titleStyle,
}) => {
  const [submitTimes, setSubmitTimes] = useState(0);
  let defButtonStyle = {
    width: "60%",
    marginTop: 40,
    marginBottom: 60,
    alignSelf: "center",
  };
  let defTitleStyle = { fontSize: 15, color: "white" };
  if (buttonStyle) {
    defButtonStyle = { ...defButtonStyle, ...buttonStyle };
  }
  if (titleStyle) {
    defTitleStyle = { ...defTitleStyle, ...titleStyle };
  }
  return (
    <>
      {message && submitTimes > 0 && (
        <Text style={{ alignSelf: "center", marginTop: 10, color: "purple" }}>
          {message}
        </Text>
      )}

      <Button
        icon={{
          name: iconName ? iconName : isSave ? "save" : "arrow-circle-right",
          type: "font-awesome",
          size: defTitleStyle.fontSize - 1,
          color: defTitleStyle.color,
        }}
        title={title ? title : isSave ? "Save >" : "Next >"}
        titleStyle={defTitleStyle}
        loading={isLoading && submitTimes > 0}
        disabled={isLoading && submitTimes > 0}
        disabledStyle={{ backgroundColor: "rgb(87, 110, 121)" }}
        buttonStyle={defButtonStyle}
        onPress={(data) => {
          setSubmitTimes(submitTimes + 1);
          return onSubmit(data);
        }}
      />
    </>
  );
};

export const MyLog = ConfigMyLog;

export const MyRowView = ({ style, children }) => {
  const defaultStyle = {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    marginBottom: 10,
  };

  return <View style={{ ...defaultStyle, ...style }}>{children}</View>;
};

export const MyFormattedTime = ({ date, showAge, type, style, prefix }) => {
  if (!date || typeof date !== "string") {
    MyLog("saga MyDobMyDob", date);
    return <></>;
  }
  const newDate = moment(date, "YYYY-MM-DDTHH:mm:ssZ");
  var returnTime = "";
  if (type == "DOB") {
    returnTime =
      (prefix || "DOB: ") +
      newDate.format("YYYY-MM-DD") +
      (showAge
        ? " (" + moment(newDate).fromNow().replace("ago", "old") + ")"
        : "");
    return <Text style={style}>{returnTime}</Text>;
  } else if (type == "comment") {
    returnTime =
      (prefix || "") +
      (showAge ? moment(newDate).fromNow() : "") +
      " (" +
      newDate.format("hh:mm a, DD MMM YYYY") +
      ")";
    return <Text style={style}>{returnTime}</Text>;
  } else if (type == "list") {
    returnTime =
      (prefix || "") +
      newDate.format("YYYY-MM-DD") +
      (showAge ? " (" + moment(newDate).fromNow() + ")" : "");
    return <Text style={style}>{returnTime}</Text>;
  }
};

export const getUniqueMergeArray = (existItems, newItems) => {
  const uniqueNewItems = newItems.filter(
    (v, i, a) => a.findIndex((t) => t.id === v.id) === i
  );
  // MyLog('newItems uniqueNewItems', newItems, uniqueNewItems)
  const noDuplicateItems = existItems.filter((item) => {
    if (uniqueNewItems.find((data) => data.id == item.id)) {
      return false;
    } else {
      return true;
    }
  });
  return [...noDuplicateItems, ...uniqueNewItems];
};

// convert flat id/parentId array to id/children nested array
export const getNestedArray = (
  itemArray,
  id = null,
  parentIdName = "parentId",
  childrenName = "children"
) => {
  if (!itemArray) {
    return [];
  }
  return itemArray
    .map((item) => {
      // eslint-disable-next-line no-prototype-builtins
      if (!item.hasOwnProperty(parentIdName) || item[parentIdName] == "") {
        item[parentIdName] = null;
      }
      return item;
    })
    .filter((item) => item[parentIdName] === id)
    .map((item) => {
      let childrenAry = {};
      childrenAry[childrenName] = getNestedArray(itemArray, item.id);
      return { ...item, ...childrenAry };
    });
};

// renderItem should be a component accept single item obj & nested depth
export const MyNestedComponent = ({
  data,
  renderItem,
  childrenName = "children",
  depth = 0,
}) => {
  if (data && data.length > 0) {
    depth++;
    return data.map((item) => {
      return (
        <React.Fragment key={item.id}>
          {renderItem({ item, depth })}
          {item[childrenName] && item[childrenName].length > 0 && (
            <MyNestedComponent
              data={item[childrenName]}
              renderItem={renderItem}
              childrenName={childrenName}
              depth={depth}
            />
          )}
        </React.Fragment>
      );
    });
  } else {
    return <></>;
  }
};

export const getTruncatedText = (text, totalNum = 150, ellipsis = "...") => {
  if (!text) {
    return "";
  }
  const stripedText = text.replace(/<[^>]+>/g, "");
  if (stripedText.length > totalNum + 10) {
    return stripedText.substring(0, totalNum) + ellipsis;
  } else {
    return stripedText;
  }
};
