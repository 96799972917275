import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "../reduxStores/actions/allActions";
import { listPet, editPet, addPet, delPet, listSpecies } from "./sagaPet";
import { listStory, editStory, addStory, delStory } from "./sagaStory";
import {
  userLogin,
  userLogout,
  userRegister,
  listUser,
  editUser,
  viewUser,
  listSiteSetting,
} from "./sagaUser";
import { listFile, addFile, delFile } from "./sagaFile";
import { listComment, addComment, addLike } from "./sagaComment";

function* rootSaga() {
  yield takeLatest(actionTypes.LIST_PET_REQUEST, listPet);
  yield takeLatest(actionTypes.EDIT_PET_REQUEST, editPet);
  yield takeLatest(actionTypes.ADD_PET_REQUEST, addPet);
  yield takeLatest(actionTypes.DEL_PET_REQUEST, delPet);

  yield takeLatest(actionTypes.LIST_STORY_REQUEST, listStory);
  yield takeLatest(actionTypes.EDIT_STORY_REQUEST, editStory);
  yield takeLatest(actionTypes.ADD_STORY_REQUEST, addStory);
  yield takeLatest(actionTypes.DEL_STORY_REQUEST, delStory);

  yield takeLatest(actionTypes.USER_LOGIN_REQUEST, userLogin);
  yield takeLatest(actionTypes.USER_LOGOUT_REQUEST, userLogout);
  yield takeLatest(actionTypes.USER_REGISTER_REQUEST, userRegister);
  yield takeLatest(actionTypes.LIST_USER_REQUEST, listUser);
  yield takeLatest(actionTypes.EDIT_USER_REQUEST, editUser);
  yield takeLatest(actionTypes.VIEW_USER_REQUEST, viewUser);

  yield takeLatest(actionTypes.ADD_FILE_REQUEST, addFile);
  yield takeLatest(actionTypes.DEL_FILE_REQUEST, delFile);
  yield takeLatest(actionTypes.LIST_FILE_REQUEST, listFile);

  yield takeLatest(actionTypes.LIST_SPECIES_REQUEST, listSpecies);

  yield takeLatest(actionTypes.LIST_COMMENT_REQUEST, listComment);
  yield takeLatest(actionTypes.ADD_COMMENT_REQUEST, addComment);

  yield takeLatest(actionTypes.ADD_LIKE_REQUEST, addLike);

  yield takeLatest(actionTypes.LIST_SITE_SETTING_REQUEST, listSiteSetting);
  // yield takeLatest(actionTypes.LIKE_ITEM_REQUEST, likeItem);
  // yield takeLatest(actionTypes.UNLIKE_ITEM_REQUEST, unlikeItem);
  // yield takeLatest(actionTypes.DELETE_ITEM_REQUEST, deleteItem);

  // yield takeLatest(actionTypes.SET_FILTER_KEY_REQUEST, setFilterKey);
}

export default rootSaga;
