/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { View } from "react-native";
import { Text, Divider, Badge } from "react-native-elements";
import { MyLog } from "../constants/config";
import CommentForm from "./CommentForm";
import CommentItem from "./CommentItem";
import { getNestedArray, MyNestedComponent } from "../helper/commonHelper";

export const Comment = ({
  currentPet,
  currentStory,
  currentUser,
  petOwner,
  category,
}) => {
  const allComments = useSelector((reduxState) => reduxState.pets.allComments);

  useEffect(() => {
    // let listCommentParams = {
    //   petId: currentPet?.id,
    //   storyId: currentStory?.id,
    //   category: category,
    //   ownerId: petOwner?.id,
    //   _sort: "id",
    //   _order: "DESC",
    //   _limit: 255,
    // };
    MyLog("ner", petOwner);
    MyLog("ntPet", currentPet);
    MyLog("ntStory", currentStory);
    // dispatch(allActions.listCommentRequest(listCommentParams));

    MyLog("mments", allComments);
  }, []);

  // const handleSubmit = (content, setContent) => {
  //   console.log(parentId, "parentid");
  //   if (content) {
  //     const newData = {
  //       nickname: currentUser ? currentUser.firstName : "anonymous",
  //       isPublic: isPublic,
  //       content: content,
  //       petId: currentPet?.id,
  //       storyId: currentStory?.id,
  //       userId: currentUser?.id,
  //       ownerId: petOwner?.id,
  //       category: category,
  //       parentId: parentId,
  //       // unmutatedParentId: item.id,
  //     };
  //     setContent("");
  //     dispatch(allActions.addCommentRequest(newData));
  //   }
  // };

  let filteredComments = [];

  allComments.map((item) => {
    let showItem = false;
    if (item.belongs_to == category && item.is_public) {
      if (category == "petProfile") {
        if (item.pet == currentPet.id) {
          showItem = true;
        }
      } else if (category == "petStory") {
        if (item.story == currentStory.id) {
          showItem = true;
        }
      } else if (category == "petOwner") {
        if (item.to_user == petOwner.id) {
          showItem = true;
        }
      }

      // if (item.petId == currentPet?.id) {
      //   console.log("category" + category);
      //   console.log(item, "item");
      //   console.log("currentStory", currentStory);
      //   if (category == "petStory" && item.storyId == currentStory?.id) {
      //     showItem = true;
      //   } else {
      //     if (category == "petProfile") {
      //       showItem = true;
      //     } else {
      //       showItem = false;
      //     }
      //   }
      // } else if (petOwner && item.ownerId == petOwner.id) {
      //   showItem = true;
      // }
    } else if (item.belongs_to == category && !item.is_public) {
      if (
        (currentUser && item.from_user == currentUser.id) ||
        (petOwner && item.to_user == petOwner.id)
      ) {
        showItem = true; // may still has bug
      }
    }
    if (showItem) {
      if (item.petId == currentPet?.id) {
        if (category == "petStory" && item.story == currentStory?.id) {
          filteredComments.push(item);
        } else {
          filteredComments.push(item);
        }
      } else if (petOwner && item.to_user == petOwner.id) {
        filteredComments.push(item);
      }
    }
  });

  const sortedComments = [...filteredComments].sort((a, b) => {
    return new Date(b.updated_at) - new Date(a.updated_at);
  });

  const nestedComments = getNestedArray(sortedComments);

  MyLog("force getNestedArray", nestedComments);

  return (
    <View style={{ marginLeft: 15 }}>
      <Text h4>
        Comments
        {sortedComments.length > 0 && (
          <Badge
            value={sortedComments.length}
            status="success"
            containerStyle={{ top: -10 }}
          />
        )}
      </Text>
      <CommentForm
        depth={1}
        currentPet={currentPet}
        currentStory={currentStory}
        currentUser={currentUser}
        petOwner={petOwner}
        category={category}
      />

      <MyNestedComponent
        data={nestedComments}
        // renderItem={CommentItem}
        renderItem={({ item, depth }) => {
          return (
            <>
              <CommentItem item={item} depth={depth} />
              <CommentForm
                item={item}
                depth={depth}
                // isReply={true}
                // handleSubmit={handleSubmit}
                currentPet={currentPet}
                currentStory={currentStory}
                currentUser={currentUser}
                petOwner={petOwner}
                category={category}
              />
              <Divider />
            </>
          );
        }}
      />
      {/* <CommentList
        currentPet={currentPet}
        currentStory={currentStory}
        currentUser={currentUser}
        petOwner={petOwner}
        category={category}
        allComments={allComments}
        setParentId={setParentId}
        displayFormId={displayFormId}
        setDisplayFormId={setDisplayFormId}
        replyContent={replyContent}
        setReplyContent={setReplyContent}
        replyNumberOfLines={replyNumberOfLines}
        setReplyNumberOfLines={setReplyNumberOfLines}
        handleSubmit={handleSubmit}
        setIsRepling={setIsRepling}
      /> */}
    </View>
  );
};

export default Comment;
