import { createActions } from "reduxsauce";

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  // pet
  listPetRequest: ["terms", "nextPageToken"],
  listPetSuccess: ["allData", "terms", "nextPageToken"],
  listPetFailure: ["error"],

  editPetRequest: ["formData"],
  editPetSuccess: ["backendData", "formData"],
  editPetFailure: ["error"],

  addPetRequest: ["formData"],
  addPetSuccess: ["backendData", "formData"],
  addPetFailure: ["error"],

  delPetRequest: ["formData"],
  delPetSuccess: ["backendData", "formData"],
  delPetFailure: ["error"],

  // upload file
  addFileRequest: ["formData"],
  addFileSuccess: ["backendData", "formData"],
  addFileFailure: ["error"],

  delFileRequest: ["formData"],
  delFileSuccess: ["backendData", "formData"],
  delFileFailure: ["error", "formData"],

  listFileRequest: ["terms", "nextPageToken"],
  listFileSuccess: ["allData", "terms", "nextPageToken"],
  listFileFailure: ["error"],

  // story
  listStoryRequest: ["terms", "nextPageToken"],
  listStorySuccess: ["allData", "terms", "nextPageToken"],
  listStoryFailure: ["error"],

  addStoryRequest: ["formData"],
  addStorySuccess: ["backendData", "formData"],
  addStoryFailure: ["error"],

  editStoryRequest: ["formData"],
  editStorySuccess: ["backendData", "formData"],
  editStoryFailure: ["error"],

  delStoryRequest: ["formData"],
  delStorySuccess: ["backendData", "formData"],
  delStoryFailure: ["error"],

  // user
  userLoginRequest: ["formData"],
  userLoginSuccess: ["backendData", "formData"],
  userLoginFailure: ["error"],

  userRegisterRequest: ["formData"],
  userRegisterSuccess: ["backendData", "formData"],
  userRegisterFailure: ["error"],

  userLogoutRequest: ["formData"],
  userLogoutSuccess: ["backendData", "formData"],
  userLogoutFailure: ["error"],

  listUserRequest: ["terms", "nextPageToken"],
  listUserSuccess: ["allData", "terms", "nextPageToken"],
  listUserFailure: ["error"],

  viewUserRequest: ["terms"],
  viewUserSuccess: ["backendData", "formData"],
  viewUserFailure: ["error"],

  editUserRequest: ["formData"],
  editUserSuccess: ["backendData", "formData"],
  editUserFailure: ["error"],

  // species
  listSpeciesRequest: ["terms", "nextPageToken"],
  listSpeciesSuccess: ["allData", "terms", "nextPageToken"],
  listSpeciesFailure: ["error"],

  // comments
  listCommentRequest: ["terms", "nextPageToken"],
  listCommentSuccess: ["allData", "terms", "nextPageToken"],
  listCommentFailure: ["error"],

  addCommentRequest: ["formData"],
  addCommentSuccess: ["backendData", "formData"],
  addCommentFailure: ["error"],

  // likes
  addLikeRequest: ["formData"],
  addLikeSuccess: ["backendData", "formData"],
  addLikeFailure: ["error"],

  delLikeRequest: ["formData"],
  delLikeSuccess: ["backendData", "formData"],
  delLikeFailure: ["error"],

  // site settings
  listSiteSettingRequest: ["terms", "nextPageToken"],
  listSiteSettingSuccess: ["allData", "terms", "nextPageToken"],
  listSiteSettingFailure: ["error"],

  // others

  listItemRequest: ["terms", "nextPageToken"],
  listItemSuccess: ["allData", "terms", "nextPageToken"],
  listItemFailure: ["error"],

  viewItemRequest: ["item"],
  viewItemSuccess: ["backendData", "item"],
  viewItemFailure: ["error"],

  likeItemRequest: ["item"],
  likeItemSuccess: ["backendData", "item"],
  likeItemFailure: ["error"],

  unlikeItemRequest: ["item"],
  unlikeItemSuccess: ["backendData", "item"],
  unlikeItemFailure: ["error"],

  deleteItemRequest: ["id"],
  deleteItemSuccess: ["id", "page"],
  deleteItemFailure: ["error"],

  setFilterKeyRequest: ["value"],
  setFilterKeySuccess: ["value"],
  setFilterKeyFailure: ["error"],

  showLoading: ["loading", "nextPageToken"],

  userLoadingChange: ["loading", "nextPageToken"],
  petLoadingChange: ["loading", "nextPageToken"],
  storyLoadingChange: ["loading", "nextPageToken"],
  commentLoadingChange: ["loading", "nextPageToken"],
});

export const actionTypes = Types;
export const taskTypes = Types;
export default Creators;
