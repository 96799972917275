/* eslint-disable react/prop-types */
import React, { useReducer } from "react";
// import { StyleSheet } from 'react-native';
import { Text, Input } from "react-native-elements";
import { useSelector } from "react-redux";
import { INITIAL_REGISTER_DATA } from "../constants/config";
import { MyInput, MyFormReducer, MySubmitButton } from "../helper/commonHelper";
// import SubmitButton from './SubmitButton'

const ProfileForm = ({ onSubmit, currentUser }) => {
  // const [submitTimes, setSubmitTimes] = useState(0);
  const [formValues, setFormValues] = useReducer(MyFormReducer, currentUser);
  const userExtraInfo = useSelector((reduxState) => reduxState.users.extraInfo);

  return (
    <>
      <MyInput
        propName="firstName"
        keyboardType="default"
        formValues={formValues}
        setFormValues={setFormValues}
      />
      <MyInput
        propName="lastName"
        keyboardType="default"
        formValues={formValues}
        setFormValues={setFormValues}
      />
      <Input
        label="Email"
        disabled={true}
        value={formValues.email}
        // inputContainerStyle={{ display: "none" }}
      />

      {/* <MyInput
            propName="email"
            keyboardType='default'
            formValues={formValues}
            setFormValues={setFormValues}
        /> */}
      {/* <MyInput
            propName="password"
            keyboardType='default'
            secureTextEntry={true}
            formValues={formValues}
            setFormValues={setFormValues}
        />
        */}

      <MySubmitButton
        iconName="user"
        title="Update"
        isLoading={userExtraInfo.loading}
        message={userExtraInfo.errorMsg}
        onSubmit={() => {
          return onSubmit(formValues);
        }}
      />

      <Text style={{ alignSelf: "center", marginBottom: 50 }}>
        Some changes may require re-login to effect!
      </Text>
    </>
  );
};

ProfileForm.defaultProps = {
  currentUser: INITIAL_REGISTER_DATA,
};

export default ProfileForm;
