/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { View } from "react-native";
import { Button, Text, Input } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import { MyLog, MyRowView, MySubmitButton } from "../helper/commonHelper";
import allActions from "../reduxStores/actions/allActions";

const CommentForm = ({
  depth,
  item,
  currentPet,
  currentStory,
  currentUser,
  petOwner,
  category,
}) => {
  const dispatch = useDispatch();

  const [content, setContent] = useState("");
  const [numberOfLines, setNumberOfLines] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isPublic] = useState(true);
  const [atNickname, setAtNickname] = useState("");
  const mainInputRef = React.createRef();
  const petExtraInfo = useSelector((reduxState) => reduxState.pets.extraInfo);
  const marginValue = 25;

  useEffect(() => {
    if (isSubmit && !petExtraInfo.loading) {
      // hide the form
      setShowForm(false);
      setIsSubmit(false);
      setContent("");
    }
  }, [isSubmit, petExtraInfo.loading]);

  const handleSubmit = () => {
    var ownerId = petOwner ? petOwner.id : currentPet?.user.id;
    console.log("ownerId ", ownerId);
    if (content.replace(atNickname, "").trim().length >= 3) {
      const newData = {
        nickname: currentUser ? currentUser.first_name : "Anonymous",
        is_public: isPublic,
        content: content,
        pet: currentPet?.id,
        story: currentStory?.id,
        from_user: currentUser?.id,
        to_user: ownerId,
        belongs_to: category,
        parent: item?.id,
      };
      // setContent("");
      setIsSubmit(true);
      dispatch(allActions.addCommentRequest(newData));
    } else {
      window.alert(
        "Please enter more than 3 characters before you comment/reply."
      );
    }
  };
  const letFormShow = () => {
    setAtNickname("@" + (item ? item.nickname : "anonymous" + "  "));
    setContent("@" + (item ? item.nickname : "anonymous") + "  ");
    if (mainInputRef.current) {
      mainInputRef.current.focus();
      console.log("mainInputRef Working");
    }
    setShowForm(!showForm);
  };

  return (
    <View style={{ marginLeft: marginValue * (depth - 1) }}>
      {item && item.id ? (
        <Button
          type="clear"
          title={showForm ? "Cancel" : "Reply"}
          titleStyle={{
            color: "gray",
            // fontWeight: "bold",
            fontSize: 12,
          }}
          buttonStyle={{ width: 60 }}
          onPress={letFormShow}
        ></Button>
      ) : !showForm ? (
        setShowForm(true)
      ) : (
        <Text />
      )}

      {showForm ? (
        <View>
          <Input
            style={{ width: "100%", fontSize: 13 }}
            value={content}
            onChangeText={setContent}
            placeholder={"Add a public " + (item ? "reply" : "comment") + "..."}
            multiline={true}
            numberOfLines={numberOfLines}
            ref={mainInputRef}
            onFocus={() => {
              if (numberOfLines == 1) {
                MyLog("ed!");
                setNumberOfLines(3);
              }
            }}
            onBlur={() => {
              if (!content) {
                MyLog("ed!");
                setNumberOfLines(1);
              }
            }}
          ></Input>

          {content.replace(atNickname, "").trim().length >= 3 ? (
            <MyRowView>
              {/* {item && item.id && (
                <Button
                  type="clear"
                  title={showForm ? "Cancel" : "Reply"}
                  titleStyle={{
                    color: "gray",
                    // fontWeight: "bold",
                    fontSize: 12,
                  }}
                  onPress={letFormShow}
                ></Button>
              )}
              <Button
                icon={{
                  name: "arrow-right",
                  size: 10,
                  color: "white",
                  type: "font-awesome",
                  marginBottom: 10,
                }}
                title={item ? "Reply" : "Comment"}
                titleStyle={{ fontSize: 12 }}
                onPress={(value) => {
                  handleSubmit(value);
                  item ? console.log("") : console.log("");
                  setShowForm(false);
                  setNumberOfLines(1);
                }}
              /> */}

              <MySubmitButton
                iconName="arrow-right"
                title={item ? "Reply" : "Comment"}
                isLoading={petExtraInfo.loading}
                message={petExtraInfo.errorMsg}
                titleStyle={{ fontSize: 12, color: "white" }}
                buttonStyle={{
                  // backgroundColor: "gray",
                  marginTop: 4,
                  marginBottom: 4,
                  width: "auto",
                }}
                onSubmit={(formData) => {
                  handleSubmit(formData);
                }}
              />
            </MyRowView>
          ) : (
            <Text />
          )}
        </View>
      ) : (
        <Text />
      )}
    </View>
  );
};

export default CommentForm;
