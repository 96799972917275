// @flow

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import petReducer from "./petReducer";
import storyReducer from "./storyReducer";
import userReducer from "./userReducer";

export default (storeHistory) =>
  combineReducers({
    router: connectRouter(storeHistory),
    pets: petReducer,
    stories: storyReducer,
    users: userReducer,
  });
