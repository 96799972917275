/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { ScrollView } from "react-native";
import SearchBar from "../components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../reduxStores/actions/allActions";
import PetResultsList from "../components/PetResultsList";
import TopNavBar, { PageHeader } from "../components/TopNavBar";
import BottomLink from "../components/BottomLink";
import { STACK_SCREEN, MyLog } from "../constants/config";

const HomepageScreen = () => {
  const dispatch = useDispatch();
  const allPets = useSelector((reduxState) => reduxState.pets.allItems);
  const allSpecies = useSelector((reduxState) => reduxState.pets.allSpecies);
  const [searchResults, setSearchResult] = useState(allPets);
  const [term, setTerm] = useState("");

  const filterResultsBySpecies = (species) => {
    return searchResults.filter((result) => {
      return result.species === species;
    });
  };

  useEffect(() => {
    MyLog("===== Loading homepage data ... ");
    dispatch(
      allActions.listPetRequest({
        // _start: 0,
        // _limit: 300,
        // _end: 1,
        // _sort: "editorChoice",
        // _order: "DESC",
      })
    );
    if (!allSpecies || allSpecies.length == 0) {
      dispatch(allActions.listSpeciesRequest());
    }
  }, []);

  useEffect(() => {
    setResultsByTerm(term);
  }, [allPets]);

  const setResultsByTerm = (term) => {
    if (term == "" || typeof term !== "string") {
      setSearchResult(allPets);
    } else {
      setSearchResult(
        allPets.filter((result) => {
          const lowerCaseTerm = term.toLowerCase();
          // MyLog('setResultsByTerm', result)
          return (
            (result.name &&
              result.name.toLowerCase().indexOf(lowerCaseTerm) != -1) ||
            (result.detail &&
              result.detail.toLowerCase().indexOf(lowerCaseTerm) != -1)
          );
        })
      );
    }
  };

  return (
    <>
      <PageHeader
        title={STACK_SCREEN.homepage.title}
        description={STACK_SCREEN.homepage.description}
      />
      <TopNavBar title={STACK_SCREEN.homepage.appTitle} />
      <SearchBar
        term={term}
        onTermChange={setTerm}
        onTermSubmit={() => setResultsByTerm(term)}
        inputContainerStyle={{ width: "100%" }}
      />
      {/* {errorMessage ? <Text>{errorMessage}</Text> : null} */}
      <ScrollView>
        {allSpecies &&
          allSpecies.map((item) => {
            return (
              <PetResultsList
                results={filterResultsBySpecies(item.name)}
                title={item.name}
                key={item.id}
              />
            );
          })}
        <BottomLink />
      </ScrollView>
    </>
  );
};

export default HomepageScreen;
