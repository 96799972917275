import axios from "axios";
import { mainApi, imgBbApi, imgUrApi } from "../constants/axiosApi";
import { MyLog } from "../constants/config";
import {
  userEmailLogin,
  userLogout,
  listUser,
  editUser,
  viewUser,
  userRegister,
  userGoogleLogin,
  listSiteSetting,
} from "./sagaApiUser";
// import firebase from 'firebase/app'

const getBasicParams = (actionTerms) => {
  let params = actionTerms ? actionTerms : {};
  // default params
  // if (!params._limit) {
  //   params._limit = NUM_PER_PAGE;
  // }
  // if (!params._start) {
  //   params._start = 0;
  // }
  // if (!params._end) {
  //   params._end = params._start + params._limit;
  // }
  return params;
};

// pet
const listPet = (action) => {
  let params = getBasicParams(action.terms);
  MyLog("listPet action params", action, params);
  return mainApi.get(`/pets`, {
    params: params,
  });
};

const editPet = (action) => {
  const { id, ...newFormData } = action.formData;
  MyLog("editPet action", action, id);
  return mainApi.put(
    `/pets/` + action.formData.id,
    newFormData, // without id
    {
      method: "PUT",
    }
  );
};

const addPet = (action) => {
  MyLog("addPet action", action);
  return mainApi.post(`/pets`, action.formData);
};

const delPet = (action) => {
  return mainApi.delete(`/pets/${action.formData.id}`);
};

// file
const addFile = (action) => {
  MyLog("addFile action2", action, action.formData);
  return mainApi.post(`/files`, action.formData);
};

const upload2cloudinary = async (action) => {
  let newFormData = new FormData();
  let public_id = action.formData.name.replace(/[^a-zA-Z0-9\-_.]/g, "_");
  public_id = public_id.substring(0, public_id.lastIndexOf("."));
  return await mainApi
    .get(`/site-settings?next_action=cloudinaryAuth&public_id=` + public_id)
    .then((res) => {
      newFormData.append("api_key", res.data.apiKey);
      newFormData.append("signature", res.data.signature || "");
      newFormData.append("timestamp", res.data.timestamp || 0);
      newFormData.append("eager", res.data.eager);
      newFormData.append("file", action.formData.data);
      newFormData.append("public_id", public_id);

      MyLog("cloudinaryAuth res", res);
      return axios.post(res.data.uploadUrl, newFormData);
    });
};

const upload2imagekit = async (action) => {
  let newFormData = new FormData();
  return await mainApi
    .get(`/site-settings?next_action=imageKitAuth`)
    .then((res) => {
      newFormData.append("publicKey", res.data.publicKey);
      newFormData.append("signature", res.data.signature);
      newFormData.append("token", res.data.token);
      newFormData.append("expire", res.data.expire);
      newFormData.append("fileName", action.formData.name);
      newFormData.append("file", action.formData.data);
      MyLog("imageKitAuth res", res);
      return axios.post(res.data.uploadUrl, newFormData);
    });
};

const uploadFile = async (action) => {
  MyLog("uploadFile action", action);

  let newFormData = new FormData();
  let uploadJson = null;
  const randomInt = Math.floor(Math.random() * 100);

  if (randomInt > 10) {
    uploadJson = await upload2cloudinary(action);
  } else if (randomInt > 0) {
    uploadJson = await upload2imagekit(action);
  }

  // uploadJson = await mainApi
  //   .get(`/site-settings?next_action=imageKitAuth`)
  //   .then((res) => {
  //     newFormData.append("publicKey", res.data.publicKey);
  //     newFormData.append("signature", res.data.signature);
  //     newFormData.append("token", res.data.token);
  //     newFormData.append("expire", res.data.expire);
  //     newFormData.append("fileName", action.formData.name);
  //     newFormData.append("file", action.formData.data);
  //     MyLog("imageKitAuth res", res);
  //     return imgKitApi.post(`/upload`, newFormData);
  //   });

  if (!uploadJson) {
    newFormData.append("name", action.formData.name);
    newFormData.append("image", action.formData.data);
    if (action.formData.size > 112345) {
      // >1MB(1012345) 10MB(10123456)
      uploadJson = await imgBbApi.post(`/upload`, newFormData);
    } else {
      uploadJson = await imgUrApi.post(`/image`, newFormData);
      if (uploadJson.data && uploadJson.data.data) {
        uploadJson.data.data.url = uploadJson.data.data.link;
      }
    }
  }

  MyLog("uploadFile uploadJson", uploadJson);
  return uploadJson;
};

const delFile = (action) => {
  return mainApi.delete(`/files/${action.formData.id}`);
};

const listFile = (action) => {
  let params = getBasicParams(action.terms);
  MyLog("listFile action", action);
  return mainApi.get(`/files`, {
    params: params,
  });
};

// story
const addStory = (action) => {
  MyLog("addStory action", action);
  return mainApi.post(`/stories`, action.formData);
};

const listStory = (action) => {
  MyLog("listStory action 2", action);
  let params = getBasicParams(action.terms);
  return mainApi.get(`/stories`, {
    params: params,
  });
};

const editStory = (action) => {
  const { id, ...newFormData } = action.formData;
  MyLog("editStory action", action, id);
  return mainApi.put(`/stories/` + action.formData.id, newFormData, {
    method: "PUT",
  });
};

const delStory = (action) => {
  return mainApi.delete(`/stories/${action.formData.id}`);
};

// species
const listSpecies = (action) => {
  MyLog("listSpecies action 2", action);
  return mainApi.get(`/species-types`, {
    params: {
      // _sort: "priority",
      // _order: "DESC",
    },
  });
};

// comments
const listComment = (action) => {
  MyLog("listComment action 2", action);
  let params = getBasicParams(action.terms);
  return mainApi.get(`/comments`, {
    params: params,
  });
};

const addComment = (action) => {
  MyLog("addComment action", action);
  return mainApi.post(`/comments`, action.formData);
};

// likes
const listLike = (action) => {
  MyLog("listLike action", action);
  let params = getBasicParams(action.terms);
  return mainApi.get(`/likes`, {
    params: params,
  });
};

const addLike = (action) => {
  MyLog("addLike action", action);
  return mainApi.post(`/likes`, action.formData);
};

const delLike = (action) => {
  MyLog("delLike action", action);
  return mainApi.delete(`/likes/${action.formData.id}`);
};

const ApiMethods = {
  listPet,
  editPet,
  addPet,
  delPet,
  addStory,
  editStory,
  listStory,
  delStory,
  userRegister,
  userEmailLogin,
  userGoogleLogin,
  userLogout,
  listUser,
  editUser,
  viewUser,
  addFile,
  uploadFile,
  delFile,
  listFile,
  listSpecies,
  listComment,
  addComment,
  listLike,
  addLike,
  delLike,
  listSiteSetting,
};

export default ApiMethods;
