/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { ScrollView } from "react-native";
import { Link } from "@react-navigation/native";
import { Divider, Text, Button } from "react-native-elements";
import TopNavBar, { PageHeader } from "../../components/TopNavBar";
import BottomLink from "../../components/BottomLink";
import { STACK_SCREEN, COLORS, MyLog } from "../../constants/config";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../reduxStores/actions/allActions";
import YoutubeVideo from "../../components/YoutubeVideo";
import PetImageCarousel from "../../components/PetImageCarousel";
import Comment from "../../components/Comment";
import LikeShareIcon from "../../components/LikeShareIcon";
import StoryList from "../../components/StoryList";
import {
  MyFormattedTime,
  MyRowView,
  getTruncatedText,
} from "../../helper/commonHelper";

const PetShowScreen = ({ route, navigation }) => {
  const petId = parseInt(route.params?.id);
  // const { allPets, editPet, deletePet, getAllPets } = useContext(Context);
  const dispatch = useDispatch();
  const allPets = useSelector((reduxState) => reduxState.pets.allItems);
  const currentPet = allPets.find((item) => item.id === petId);

  // const allStories = useSelector((reduxState) => reduxState.stories.allItems);
  // const currentPetStories = allStories
  //   ? allStories.filter((item) => item.petId == petId)
  //   : null;

  // const allFiles = useSelector((reduxState) => reduxState.pets.allFiles);
  // const currentFiles = allFiles
  //   ? allFiles.filter(
  //       (item) =>
  //         item.petId == petId &&
  //         item.category &&
  //         item.category.indexOf("petProfile") != -1
  //     )
  //   : [];
  const currentFiles = currentPet?.files;

  const currentUser = useSelector((reduxState) => reduxState.users.myProfile);
  const siteSettings = useSelector(
    (reduxState) => reduxState.users.siteSettings
  );

  MyLog("currentPet", petId, currentPet, siteSettings?.upload.pet.max_size);

  useEffect(() => {
    MyLog("always get the pet info from database");
    dispatch(
      allActions.listPetRequest({
        id: petId,
        // _embed: "stories,files,comments",
        // _expand: "user",
      })
    );

    if (currentPet) {
      // navigation.setOptions({ title: STACK_SCREEN.petShow.title + ':' + currentPet.species + ' ' + currentPet.name + '', description: STACK_SCREEN.petShow.title });
      // update viewNum
      // const timer1 = setTimeout(
      //   () =>
      //     dispatch(
      //       allActions.editPetRequest({
      //         id: petId,
      //         view_num: currentPet.view_num ? currentPet.view_num + 1 : 1,
      //       })
      //     ),
      //   4000
      // );
      // return () => {
      //   clearTimeout(timer1);
      // };
    }

    // if (currentPet) {
    //   dispatch(allActions.listStoryRequest({ petId: currentPet.id }));
    //   navigation.setOptions({ title: STACK_SCREEN.petShow.title + ':' + currentPet.species + ' ' + currentPet.name + '' });

    //   dispatch(allActions.listFileRequest({ petId: currentPet.id }));
    //   if (!petOwner) {
    //     MyLog('Retrieve user info from database');
    //     dispatch(allActions.listUserRequest({ id: currentPet.userId, appendToAll: true }));
    //   }
    // }
  }, [petId]);

  if (!currentPet) {
    MyLog("No info of currentPet");
    return null;
  }

  // truncate: function(str) {
  //   return str.length > 10 ? str.substring(0, 7) + "..." : str;
  // }

  return (
    <ScrollView>
      <PageHeader
        title={
          STACK_SCREEN.petShow.title +
          ":" +
          currentPet?.species +
          " " +
          currentPet?.name +
          ""
        }
        description={currentPet ? getTruncatedText(currentPet.detail) : null}
      />
      <TopNavBar title={STACK_SCREEN.petShow.title} />
      <Text h4 style={{ alignSelf: "center" }}>
        {currentPet.species}: {currentPet.name}
      </Text>
      <Text>{currentPet.youtubeUrl}</Text>
      {
        currentPet.youtubeUrl && currentPet.youtubeUrl.indexOf("http") != -1 ? (
          <YoutubeVideo currentPet={currentPet} />
        ) : (
          []
        ) // to avoid issue of "A text node cannot be a child of a <View>"
      }
      {/* <Text>ID: {currentPet.id}  - Priority: {currentPet.priority}</Text> */}
      <MyRowView>
        <Text style={{ marginRight: 20 }}>{currentPet.gender}</Text>
        {/* <MyDob dob={currentPet.dob} age={true} /> */}
        <MyFormattedTime date={currentPet.dob} showAge={true} type="DOB" />
      </MyRowView>
      <Divider />
      <Text style={{ alignSelf: "center", fontSize: 18, marginTop: 10 }}>
        {currentPet.detail}
      </Text>

      <Link
        to={"/owner/" + currentPet.userId}
        style={{
          alignContent: "flex-end",
          alignSelf: "flex-end",
          color: COLORS.secondary,
        }}
      >
        {"Pet Owner: " + currentPet.user.username}
      </Link>
      {/* <Text>User ID: {currentPet.userId}</Text> */}
      {/* <Image source={{ uri: currentPet.mainImageUrl || 'https://picsum.photos/320/180?ramdom=' + currentPet.id }} style={{ width: 480, height: 270 }} />
      <FlatList
        data={currentFiles}
        keyExtractor={item => item.id.toString()}
        pagingEnabled
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={({ item }) => {
          return <Image source={{ uri: item.mainUrl || 'https://picsum.photos/320/180?ramdom=' + item.id }} style={{ width: 480, height: 270 }} />
        }}
      /> */}
      <PetImageCarousel currentPet={currentPet} currentFiles={currentFiles} />
      <MyFormattedTime
        date={currentPet.createdAt}
        showAge={true}
        type="list"
        prefix="Pet profile added on "
        style={{ alignSelf: "center", color: "gray" }}
      />
      <StoryList stories={currentPet.stories} navigation={navigation} />

      {/* 

      {
        currentPetStories ? currentPetStories.map((item, i) => (
          <ListItem key={i}
            bottomDivider
            onPress={() => navigation.navigate(STACK_SCREEN.storyShow.name, { id: item.id })}>
            <Icon name="clipboard" type='font-awesome' />
            <ListItem.Content>
              <ListItem.Title>{item.title} - {item.detail}</ListItem.Title>
            </ListItem.Content>
            <ListItem.Chevron name="caret-right" type='font-awesome' />
          </ListItem>
        ))
          :
          <Text>Loading...</Text>
      } */}
      {currentUser && currentUser.id == currentPet.user.id && (
        <>
          <Button
            icon={{
              name: "edit",
              type: "font-awesome",
              size: 15,
              color: "white",
            }}
            // type="outline"
            title="Write a story for this pet"
            // titleStyle={{ color: "cyan" }}
            buttonStyle={{
              // width: "80%",
              marginTop: 40,
              alignSelf: "center",
            }}
            onPress={() =>
              navigation.navigate(STACK_SCREEN.storyCreate.name, {
                petId: petId,
              })
            }
          />
          <Button
            icon={{
              name: "edit",
              type: "font-awesome",
              size: 15,
              color: "rgb(32, 137, 220)",
            }}
            type="outline"
            title="Edit Pet"
            // titleStyle={{ color: "cyan" }}
            buttonStyle={{
              width: "40%",
              margin: 40,

              alignSelf: "center",
            }}
            onPress={() =>
              navigation.navigate(STACK_SCREEN.petEdit.name, { id: petId })
            }
          />
        </>
      )}
      <LikeShareIcon
        currentPet={currentPet}
        currentUser={currentUser}
        category="petProfile"
      />
      <Comment
        currentPet={currentPet}
        currentUser={currentUser}
        category="petProfile"
      />

      <BottomLink currentUser={currentUser} />
    </ScrollView>
  );
};

export default PetShowScreen;
