/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { ScrollView } from "react-native";
import { Text } from "react-native-elements";

import { useDispatch, useSelector } from "react-redux";
import allActions from "../../reduxStores/actions/allActions";
import BottomLink from "../../components/BottomLink";
import PetResultsList from "../../components/PetResultsList";
import TopNavBar, { PageHeader } from "../../components/TopNavBar";
import { STACK_SCREEN } from "../../constants/config";
import Comment from "../../components/Comment";
import LikeShareIcon from "../../components/LikeShareIcon";
import StoryList from "../../components/StoryList";

const OwnerShowScreen = ({ route, navigation }) => {
  const ownerId = route.params?.id;
  const dispatch = useDispatch();
  const myPets = useSelector((reduxState) =>
    reduxState.pets.allItems.filter((item) => item.userId == ownerId)
  );
  const myStories = useSelector((reduxState) =>
    reduxState.stories.allItems.filter((item) => item.userId == ownerId)
  );
  const currentUser = useSelector((reduxState) => reduxState.users.myProfile);
  const petOwner = useSelector((reduxState) =>
    reduxState.users.allItems.find((item) => item.id == ownerId)
  );

  useEffect(() => {
    // dispatch(allActions.listPetRequest({ userId: ownerId, appendToAll: true }));
    // if (myPets?.length > 0) {
    //   dispatch(allActions.listStoryRequest({ userId: ownerId }));
    // }
    if (petOwner) {
      // navigation.setOptions({
      //   title:
      //     STACK_SCREEN.ownerShow.title +
      //     ":" +
      //     petOwner.firstName +
      //     " " +
      //     petOwner.lastName +
      //     "",
      // });

      // update viewNum
      const timer1 = setTimeout(
        () =>
          dispatch(
            allActions.editUserRequest({
              id: ownerId,
              viewNum: petOwner.viewNum ? petOwner.viewNum + 1 : 1,
            })
          ),
        4000
      );
      return () => {
        clearTimeout(timer1);
      };
    }

    dispatch(
      allActions.viewUserRequest({
        id: ownerId,
        _embed: "pets,stories,comments|ownerId",
      })
    );
  }, [ownerId]);

  const sortedMyPets = [...myPets].sort((a, b) => {
    return b.priority - a.priority;
  });
  const sortedMyStories = [...myStories].sort((a, b) => {
    return new Date(b.updatedAt) - new Date(a.updatedAt);
  });

  return (
    <ScrollView>
      <PageHeader
        title={
          STACK_SCREEN.ownerShow.title +
          ":" +
          petOwner.firstName +
          " " +
          petOwner.lastName
        }
        description={
          "Hi, My name is " +
          petOwner.firstName +
          ", I've " +
          myPets.length +
          " pets and wrote " +
          myStories.length +
          " pet stories. Please let me know if you like my pet or the story!"
        }
      />

      <TopNavBar title={STACK_SCREEN.ownerShow.title} />
      <Text h4 style={{ alignSelf: "center", marginTop: 20 }}>
        Pet Owner {petOwner?.firstName}
      </Text>

      <PetResultsList
        results={sortedMyPets || []}
        title={"Pets from " + petOwner?.firstName}
      />
      <LikeShareIcon
        petOwner={petOwner}
        currentUser={currentUser}
        category="petOwner"
      />
      <Comment
        petOwner={petOwner}
        currentUser={currentUser}
        category="petOwner"
      />

      <Text style={{ fontSize: 18, fontWeight: "bold", margin: 15 }}>
        Pet Stories
      </Text>
      <StoryList
        stories={sortedMyStories}
        navigation={navigation}
        pets={myPets}
      />

      {/* <FlatList
                keyExtractor={item => item.id.toString()}
                data={sortedMyStories}
                renderItem={renderStoryItem}
            /> */}
      {/* {
                sortedMyStories ? sortedMyStories.map((item, i) => (
                    <ListItem key={i}
                        bottomDivider
                        onPress={() => navigation.navigate(STACK_SCREEN.storyShow.name, { id: item.id })}>
                        <Icon name="clipboard" type='font-awesome' />
                        <ListItem.Content>
                            <ListItem.Title>{item.title} - {item.detail}</ListItem.Title>
                        </ListItem.Content>
                        <ListItem.Chevron name="caret-right" type='font-awesome' />
                    </ListItem>
                ))
                    :
                    <Text>Loading...</Text>
            } */}
      {myPets?.length == 0 && (
        <Text style={{ alignSelf: "center", marginTop: 20, color: "red" }}>
          {ownerId} does not add any pet yet
        </Text>
      )}

      <BottomLink currentUser={currentUser} />
    </ScrollView>
  );
};

export default OwnerShowScreen;
