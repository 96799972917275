/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { ScrollView } from "react-native";
// import { Context } from '../../context/PetContext';
import StoryForm from "../../components/StoryForm";
import TopNavBar from "../../components/TopNavBar";
import BottomLink from "../../components/BottomLink";
import { Button } from "react-native-elements";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../reduxStores/actions/allActions";
import { STACK_SCREEN, MyLog } from "../../constants/config";

const StoryEditScreen = ({ route, navigation }) => {
  const storyId = route.params?.id;

  const currentUser = useSelector((reduxState) => reduxState.users.myProfile);
  const dispatch = useDispatch();
  // const myStories = useSelector((reduxState) => reduxState.stories.myItems);
  // MyLog("myStories", myStories);
  const currentStory = currentUser.stories.find((item) => item.id === storyId);

  const allPets = useSelector((reduxState) => reduxState.pets.allItems);
  const [confirmDel, setConfirmDel] = useState(false);

  if (allPets.length == 0) {
    MyLog("try to get all pets");
    dispatch(allActions.listPetRequest({ userId: currentUser?.id }));
  }

  useEffect(() => {
    if (!currentUser) {
      navigation.navigate(STACK_SCREEN.userLogin.name);
    }
  }, []);

  useEffect(() => {
    navigation.setOptions({
      title: STACK_SCREEN.storyEdit.title + ":" + currentStory?.name + "",
    });
    setConfirmDel(false);
  }, [currentStory]);

  MyLog("PetEditScreen currentStory", currentStory);

  if (!currentStory) {
    return <></>;
  }

  return (
    <ScrollView>
      <TopNavBar title={STACK_SCREEN.storyEdit.title} />
      <StoryForm
        currentStory={currentStory}
        currentUser={currentUser}
        onSubmit={(formData) => {
          if (!formData.title.trim()) {
            alert("Please Enter Story Title");
            return false;
          }
          dispatch(allActions.editStoryRequest(formData));
          // editPet(petDetails, () => navigation.navigate('PetIndex'));
        }}
      />

      <Button
        icon={{
          name: "trash",
          type: "font-awesome",
          size: 15,
          color: "red",
        }}
        type="outline"
        title={confirmDel ? "Confirm Delete" : "Delete"}
        titleStyle={{ color: "red" }}
        buttonStyle={{
          width: "60%",
          margin: 40,

          alignSelf: "center",
        }}
        onPress={() => {
          if (confirmDel) {
            dispatch(allActions.delStoryRequest(currentStory));
          } else {
            setConfirmDel(true);
          }

          // deletePet(id, () => navigation.navigate('PetIndex'));
        }}
      />
      <BottomLink currentUser={currentUser} />
    </ScrollView>
  );
};

// const styles = StyleSheet.create({});

export default StoryEditScreen;
