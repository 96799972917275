/* eslint-disable react/prop-types */
import React from "react";
import { ListItem, Text, Image } from "react-native-elements";
import { Link } from "@react-navigation/native";
import { STACK_SCREEN } from "../constants/config";
import { MyFormattedTime } from "../helper/commonHelper";

const StoryList = ({ navigation, stories, pets }) => {
  if (!stories || !stories.length) {
    return null;
  }
  return (
    <>
      {stories ? (
        stories.map((item, i) => {
          const petProfile = pets?.find((pet) => item.petId == pet.id);

          return (
            <ListItem key={i} bottomDivider>
              {/* <Icon name="clipboard" type='font-awesome' /> */}
              {/* <Avatar source={{ uri: item.mainImageUrl }} /> */}
              <Image
                style={{ height: 60, width: 100 }}
                source={{
                  uri:
                    item.mainImageUrl ||
                    "https://picsum.photos/100/60?ramdom=" + item.id,
                }}
                onPress={() =>
                  navigation.navigate(STACK_SCREEN.storyShow.name, {
                    id: item.id,
                  })
                }
              />

              <ListItem.Content>
                <ListItem.Title>
                  <Link to={"/stories/" + item.id}>Story: {item.title}</Link>
                </ListItem.Title>

                <ListItem.Subtitle style={{ marginTop: 10 }}>
                  {petProfile ? (
                    <>
                      <Link to={"/petStories/" + petProfile.species}>
                        {petProfile.species}
                      </Link>
                      :
                      <Link
                        to={"/pets/" + petProfile.id}
                        style={{ marginRight: 20 }}
                      >
                        {petProfile.name}
                      </Link>
                    </>
                  ) : (
                    []
                  )}
                  <MyFormattedTime
                    style={{ color: "gray" }}
                    date={item.created_at}
                    showAge={true}
                    type="list"
                  />
                </ListItem.Subtitle>
              </ListItem.Content>
              <ListItem.Chevron name="caret-right" type="font-awesome" />
            </ListItem>
          );
        })
      ) : (
        <Text>Loading...</Text>
      )}
    </>
  );
};

export default StoryList;
