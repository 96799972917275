import { call, put } from "redux-saga/effects";
import sagaApi from "./sagaApiServices";
import { MyLog } from "../constants/config";
import allActions from "../reduxStores/actions/allActions";

// comments
export function* listComment(action) {
  try {
    const json = yield call(sagaApi.listComment, action); // call sagaApi function
    MyLog("listComment-mylog1", action.type, json);
    yield put(
      allActions.listCommentSuccess(
        json.data,
        action.terms,
        action.nextPageToken
      )
    ); // call reducer function
  } catch (e) {
    yield put(allActions.listCommentFailure(e.message));
  }
}

export function* addComment(action) {
  yield put(allActions.petLoadingChange(true, ""));
  try {
    const json = yield call(sagaApi.addComment, action); // call sagaApi function
    MyLog("addComment-mylog5", action, json);
    yield put(allActions.addCommentSuccess(json.data, action.formData)); // call reducer function
    // yield navigationService.navigate(STACK_SCREEN.CommentEdit.name, {
    //   id: json.data.id,
    // });

    // update commentNum
    if (json.data.category == "petProfile") {
      yield call(sagaApi.editPet, {
        formData: { id: json.data.petId, commentNum: "increment" },
      }); // call sagaApi function
    } else if (json.data.category == "petStory") {
      yield call(sagaApi.editStory, {
        formData: { id: json.data.storyId, commentNum: "increment" },
      }); // call sagaApi function
    } else if (json.data.category == "petOwner") {
      yield call(sagaApi.editUser, {
        formData: { id: json.data.ownerId, commentNum: "increment" },
      }); // call sagaApi function
    }
  } catch (e) {
    yield put(allActions.addCommentFailure(e.message));
  }
}

// others
export function* listItem(action) {
  yield put(allActions.showLoading(true, action.nextPageToken));

  try {
    const json = yield call(
      sagaApi.listItem,
      action.terms,
      action.nextPageToken
    );
    MyLog("listItem-mylog1", action.type, json);
    yield put(
      allActions.listItemSuccess(json.data, action.terms, action.nextPageToken)
    );
  } catch (e) {
    yield put(allActions.listItemFailure(e.message));
  }
}

export function* viewItem(action) {
  try {
    const json = yield call(sagaApi.viewItem, action.item);
    MyLog("viewItem-mylog2", action.type, json);
    yield put(allActions.viewItemSuccess(json.data, action.item));
  } catch (e) {
    yield put(allActions.viewItemFailure(e.message));
  }
}

export function* likeItem(action) {
  try {
    const json = yield call(sagaApi.likeItem, action.item);
    MyLog("likeItem-mylog3", action.type, json);
    yield put(allActions.likeItemSuccess(json.data, action.item));
  } catch (e) {
    yield put(allActions.likeItemFailure(e.message));
  }
}

export function* unlikeItem(action) {
  try {
    const json = yield call(sagaApi.unlikeItem, action.item);
    MyLog("unlikeItem-mylog3", action.type, json);
    yield put(allActions.unlikeItemSuccess(json.data, action.item));
  } catch (e) {
    yield put(allActions.unlikeItemFailure(e.message));
  }
}

export function* deleteItem(action) {
  try {
    const json = yield call(sagaApi.deleteItem, action.id);
    MyLog(action, json);
    yield put(allActions.deleteItemSuccess(action.id));
    // yield put(push("/listPage"));
  } catch (e) {
    yield put(allActions.deleteItemFailure(e.message));
  }
}

export function* setFilterKey(action) {
  try {
    yield put(allActions.setFilterKeySuccess(action.value));
  } catch (e) {
    yield put(allActions.setFilterKeyFailure(e.message));
  }
}

export function* addLike(action) {
  try {
    const myLikes = yield call(sagaApi.listLike, {
      terms: { userId: action.formData.userId },
    }); // get all myLikes
    const newMyLikes = myLikes ? myLikes.data : [];
    MyLog("saga:addLike-myLikes", action, myLikes);

    let tempJson = null;
    let likeNumAction = "increment";
    if (action.formData.isLiked && action.formData.id) {
      tempJson = yield call(sagaApi.delLike, action); // call sagaApi function
      yield put(
        allActions.delLikeSuccess(
          newMyLikes.filter((item) => item.id != action.formData.id),
          action.formData
        )
      ); // call reducer function
      likeNumAction = "decrement";
    } else {
      tempJson = yield call(sagaApi.addLike, action); // call sagaApi function
      yield put(
        allActions.addLikeSuccess(
          [...newMyLikes, tempJson.data],
          action.formData
        )
      ); // call reducer function
    }

    // update likeNum
    if (action.formData.category == "petProfile") {
      tempJson = yield call(sagaApi.editPet, {
        formData: { id: action.formData.targetId, likeNum: likeNumAction },
      });
      MyLog("saga:editPet-likeNum", action, tempJson);
      yield put(allActions.editPetSuccess(tempJson.data, tempJson.data));
    } else if (action.formData.category == "petStory") {
      tempJson = yield call(sagaApi.editStory, {
        formData: { id: action.formData.targetId, likeNum: likeNumAction },
      });
      MyLog("saga:editStory-likeNum", action, tempJson);
      yield put(allActions.editStorySuccess(tempJson.data, tempJson.data));
    } else if (action.formData.category == "petOwner") {
      tempJson = yield call(sagaApi.editUser, {
        formData: { id: action.formData.targetId, likeNum: likeNumAction },
      });
      MyLog("saga:editUser-likeNum", action, tempJson);
      yield put(allActions.editUserSuccess(tempJson.data, tempJson.data));
    }
  } catch (e) {
    yield put(allActions.addLikeFailure(e.message));
  }
}
