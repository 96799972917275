/* eslint-disable react/prop-types */
import React, { useState, useReducer, useEffect } from "react";
import { TouchableOpacity } from "react-native";
import { Text, Input, Button } from "react-native-elements";
import { useSelector } from "react-redux";
import {
  INITIAL_LOGIN_DATA,
  STACK_SCREEN,
  BACKEND_API,
  MyLog,
} from "../constants/config";
import { MyInput, MyFormReducer, MySubmitButton } from "../helper/commonHelper";
import { Link } from "@react-navigation/native";
// import SubmitButton from './SubmitButton';

// import LoginWithGoogle from './LoginWithGoogle'
// import firebase from 'firebase/app'

const LoginForm = ({ onSubmit, initialValues, navigation }) => {
  const userExtraInfo = useSelector((reduxState) => reduxState.users.extraInfo);
  const [loginDetails, setLoginReducer] = useReducer(
    MyFormReducer,
    initialValues
  );
  const [formMessage] = useState(userExtraInfo.errorMsg);
  const siteSettings = useSelector(
    (reduxState) => reduxState.users.siteSettings
  );

  useEffect(() => {
    const screenOnBlur = navigation.addListener("blur", () => {
      MyLog("login useEffect screenOnBlur", formMessage);
    });
    return screenOnBlur;
  }, [navigation]);

  // React.useEffect(
  //   () => navigation.addListener('focus', () => alert('Screen was focused')),
  //   []
  // );

  // React.useEffect(
  //   () => navigation.addListener('blur', () => alert('Screen was unfocused')),
  //   [navigation]
  // );

  return (
    <>
      {siteSettings?.page.login.show_login_with_google && (
        <Link to={BACKEND_API + "/connect/google"}>
          <Button
            icon={{
              name: "users",
              type: "font-awesome",
              size: 15,
              color: "white",
            }}
            title="Login with Google account"
            // loading={userExtraInfo.loading && submitTimes > 0}
            type="outline"
            // disabled={userExtraInfo.loading && submitTimes > 0}
            // disabledStyle={{ backgroundColor: "rgb(87, 110, 121)" }}
            buttonStyle={{
              // width: "60%",
              margin: 40,
              alignSelf: "center",
            }}
            // onPress={() => { return onSubmit({ loginType: 'google' }) }}
            // onPress={redirectGoogleLogin}
            // onPress={() =>
            //   navigation.navigate(STACK_SCREEN.userLoginWithGoogle.name)
            // }
          />
        </Link>
      )}

      {siteSettings?.page.login.show_login_with_facebook && (
        <Link to={BACKEND_API + "/connect/facebook"}>
          <Button
            icon={{
              name: "users",
              type: "font-awesome",
              size: 15,
              color: "white",
            }}
            title="Login with Facebook account"
            // loading={userExtraInfo.loading && submitTimes > 0}
            type="outline"
            // disabled={userExtraInfo.loading && submitTimes > 0}
            // disabledStyle={{ backgroundColor: "rgb(87, 110, 121)" }}
            buttonStyle={{
              // width: "60%",
              margin: 40,
              alignSelf: "center",
            }}
            // onPress={() => { return onSubmit({ loginType: 'google' }) }}
            // onPress={redirectGoogleLogin}
            // onPress={() =>
            //   navigation.navigate(STACK_SCREEN.userLoginWithGoogle.name)
            // }
          />
        </Link>
      )}

      {siteSettings?.page.login.show_login_with_email && (
        <>
          <MyInput
            propName="email"
            keyboardType="default"
            formValues={loginDetails}
            setFormValues={setLoginReducer}
          />
          <MyInput
            propName="password"
            secureTextEntry={true}
            keyboardType="default"
            formValues={loginDetails}
            setFormValues={setLoginReducer}
          />
          <Input
            label="Remember Me"
            inputContainerStyle={{ display: "none" }}
          />
          {/* <MyRadio
      propName="rememberMe"
      value={true}
      formValues={loginDetails}
      setFormValues={setLoginReducer}
    /> */}

          {/* {userExtraInfo.errorMsg && submitTimes > 0 && <Text style={{ alignSelf: "center", marginTop: 10, color: "red" }}>{userExtraInfo.errorMsg}</Text>} */}

          {/* <Button
      icon={{
        name: "save",
        type: 'font-awesome',
        size: 15,
        color: "white"
      }}
      title="Login"
      loading={userExtraInfo.loading && submitTimes > 0}
      disabled={userExtraInfo.loading && submitTimes > 0}
      disabledStyle={{ backgroundColor: "rgb(87, 110, 121)" }}
      buttonStyle={{
        width: "60%",
        margin: 40,
        alignSelf: 'center'
      }}
      onPress={() => { setSubmitTimes(submitTimes + 1); return onSubmit(loginDetails) }}
    /> */}
          <MySubmitButton
            iconName="lock"
            title="Login"
            isLoading={userExtraInfo.loading}
            message={userExtraInfo.errorMsg}
            onSubmit={() => {
              return onSubmit(loginDetails);
            }}
          />
        </>
      )}

      <TouchableOpacity
        onPress={() => navigation.navigate(STACK_SCREEN.userRegister.name)}
      >
        <Text style={{ alignSelf: "center", marginBottom: 50 }}>
          Do not have an account? Register an account for FREE!
        </Text>
      </TouchableOpacity>
    </>
  );
};

LoginForm.defaultProps = {
  initialValues: INITIAL_LOGIN_DATA,
};

export default LoginForm;
