import { Platform } from "react-native";
import firebase from "firebase/app";
import "firebase/auth";

export const STACK_SCREEN = {
  homepage: {
    name: "Homepage",
    title:
      "Free Pet Story & Pet Diary, Write a story or diary for your pet - PetStory.onLine",
    appTitle: "Free Pet Story",
    route: "",
    file: "screens/homepage",
    description:
      "Write a pet story or diary of your lovely pet for FREE. PetStory is a story platform for pet lovers & pet parents. You can share your pet stories & pictures & videos here! It's FREE to use!",
  },

  petCreate: {
    name: "PetCreate",
    title: "Add New Pet",
    route: "pets/create",
    file: "screens/pet/create",
  },
  petEdit: {
    name: "PetEdit",
    title: "Edit Pet Profile",
    route: "pets/:id/edit",
    file: "screens/pet/edit",
  },
  petShow: {
    name: "PetShow",
    title: "Pet Profile",
    route: "pets/:id",
    file: "screens/pet/show",
  },
  petIndex: {
    name: "PetIndex",
    title: "All Pet Profiles",
    route: "petProfiles/:species?",
    file: "screens/pet/index",
  },

  storyIndex: {
    name: "StoryIndex",
    title: "Pet Stories",
    route: "petStories/:species?",
    file: "screens/story/index",
  },
  // storyIndexAll: { name: 'StoryIndexAll', title: "All Pet Stories", route: "stories", file: "screens/story/index" },
  storyCreate: {
    name: "StoryCreate",
    title: "Add New Story",
    route: "stories/create",
    file: "screens/story/create",
  },
  storyEdit: {
    name: "StoryEdit",
    title: "Edit Pet Story",
    route: "stories/:id/edit",
    file: "screens/story/edit",
  },
  storyShow: {
    name: "StoryShow",
    title: "Pet Story",
    route: "stories/:id",
    file: "screens/story/show",
  },

  userLogin: {
    name: "UserLogin",
    title: "User Login",
    route: "user/login",
    file: "screens/user/login",
  },
  userLogout: {
    name: "UserLogout",
    title: "Logout / Sign Out",
    route: "user/logout",
    file: "screens/user/logout",
  },
  userRegister: {
    name: "UserRegister",
    title: "Free Register",
    route: "user/register",
    file: "screens/user/register",
  },
  userLoginWithGoogle: {
    name: "UserLoginWithGoogle",
    title: "Login with Google",
    route: "/connect/google/redirect",
    file: "screens/user/loginWithGoogle",
  },
  userIndex: {
    name: "UserIndex",
    title: "User Dashboard",
    route: "user/index",
    file: "screens/user/index",
  },
  userProfile: {
    name: "UserProfile",
    title: "User Profile",
    route: "user/profile",
    file: "screens/user/profile",
  },

  ownerShow: {
    name: "OwnerShow",
    title: "Pet Owner",
    route: "owner/:id",
    file: "screens/owner/show",
  },

  // next step: add the component to screens/navigator/stack.js
};

export const NUM_PER_PAGE = 30;
export const WEB_URL = "https://petstory.online";
export const BACKEND_API = "http://petstory.local.10168.tech:9337";

const getScreenRoute = (stackScreens) => {
  let screens = {};
  for (const key in stackScreens) {
    // eslint-disable-next-line no-prototype-builtins
    if (stackScreens.hasOwnProperty(key)) {
      screens[stackScreens[key].name] = stackScreens[key].route;
      // console.log(key + " -> " + stackScreens[key]);
    }
  }

  return screens;
};

export const STACK_LINKING = {
  prefixes: ["http://localhost:3000", "mychat://"],
  config: {
    // initialRouteName: STACK_SCREEN.homepage.name, // this will load before every route(page)
    screens: getScreenRoute(STACK_SCREEN),
  },
};

export const INITIAL_PET_DATA = {
  name: "",
  detail: "",
  species: "Dog",
  gender: "Male",
  dob: new Date(),
  age: 1,
  priority: 5,
  editor_choice: 0,
  id: "",
  youtube_url: "",
  main_image_id: "",
  main_image_url: "",
  user: "",
};

export const INITIAL_STORY_DATA = {
  title: "",
  detail: "",
  pet: "",
  species: "", // nested join query is inefficiency for mongodb
  priority: 5,
  editor_choice: 0,
  id: "",
  youtube_url: "",
  main_image_id: "",
  main_image_url: "",
  user: "",
};

let testLoginData = {
  email: "",
  password: "",
  rememberMe: true,
};

if (process.env.NODE_ENV !== "production" && Platform.OS !== "web") {
  testLoginData = {
    email: "test123@test.com",
    password: "test1@test.com",
    rememberMe: true,
  };
}

export const INITIAL_LOGIN_DATA = testLoginData;

export const INITIAL_REGISTER_DATA = {
  email: "",
  password: "",
  confirmPassword: "",
  firstName: "",
  lastName: "",
  firebaseUid: null,
};

export const REACTPATH = {
  Redux: "",
};

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyALL6q6CsPqgmYyFsW5FHAq3FIdgUkTfAE",
  authDomain: "pet-story-react.firebaseapp.com",
  databaseURL: "https://pet-story-react.firebaseio.com",
  projectId: "pet-story-react",
  storageBucket: "pet-story-react.appspot.com",
  messagingSenderId: "453200870256",
  appId: "1:453200870256:web:a2242d5703b11f0c966db5",
  measurementId: "G-WH8L6H9E9F",
};

// some css style with same class name of bootstrap css
export const COLORS = {
  warning: "red",
  primary: "blue",
  secondary: "gray",
  info: "rgb(32, 137, 220)",
};

export const FIREBASE = firebase.initializeApp(FIREBASE_CONFIG);

export const MyLog = (v1, v2, v3, v4, v5) => {
  let showLog = true;

  // return false; // do not show any log

  const debugPaths = [
    // '/pets/',
    // '/stories/',
    // '/users/',
    // '/', // this means show log for all paths
  ];
  const v1Keywords = [
    // 'reducer',
    // 'saga',
    // 'api',
    "force",
  ];

  // const andCondition = true;
  const andCondition = false;

  let hasDebugPaths = false;
  if (Platform.OS == "web") {
    debugPaths.map((path) => {
      if (location.href.indexOf(path) != -1) {
        hasDebugPaths = true;
      }
    });
  } else {
    hasDebugPaths = true;
  }

  let hasV1keywords = false;
  if (typeof v1 == "string") {
    v1Keywords.map((str) => {
      if (v1.toLowerCase().indexOf(str) != -1) {
        hasV1keywords = true;
      }
    });
  }

  if (andCondition) {
    if (hasV1keywords && hasDebugPaths) {
      showLog = true;
    }
  } else {
    if (hasV1keywords || hasDebugPaths) {
      showLog = true;
    }
  }

  if (showLog) {
    console.log(
      "\n====== MyLog ======",
      "\n--- v1 ---\n",
      v1,
      "\n--- v2 ---\n",
      v2,
      "\n--- v3 ---\n",
      v3,
      "\n--- v4 ---\n",
      v4,
      "\n--- v5 ---\n",
      v5
    );
  }
};
