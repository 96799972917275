import { call, put } from "redux-saga/effects";
import sagaApi from "./sagaApiServices";
import navigationService from "./navigationService";
import { STACK_SCREEN, MyLog } from "../constants/config";
import allActions from "../reduxStores/actions/allActions";

// pet
export function* listPet(action) {
  yield put(allActions.petLoadingChange(true, ""));
  try {
    const json = yield call(sagaApi.listPet, action); // call sagaApi function
    MyLog("Saga:listPet-mylog1", action.type, json);

    // // handle extra data for _embed & _expand
    // if (json.data[0]) {
    //   if (newJsonData[0].user && newJsonData[0].user.id) {
    //     MyLog("Saga:listPet-user", json.data[0]);
    //     let userData = [];
    //     json.data.map(item => {
    //       userData.push(item.user);
    //     });
    //     yield put(
    //       allActions.listUserSuccess(userData, action.terms, action.nextPageToken)
    //     );
    //   }
    //   if (newJsonData[0].stories && newJsonData[0].stories.length > 0) {
    //     yield put(
    //       allActions.listStorySuccess(newJsonData[0].stories, action.terms, action.nextPageToken)
    //     );
    //   }
    //   if (newJsonData[0].files && newJsonData[0].files.length > 0) {
    //     yield put(
    //       allActions.listFileSuccess(newJsonData[0].files, action.terms, action.nextPageToken)
    //     );
    //   }
    //   if (newJsonData[0].comments && newJsonData[0].comments.length > 0) {
    //     yield put(
    //       allActions.listCommentSuccess(newJsonData[0].comments, action.terms, action.nextPageToken)
    //     );
    //   }
    // }

    let newJsonData = json.data;

    // handle extra data for _embed & _expand
    if (json.data[0]) {
      // let newJsonData = [...json.data];
      // let tempData = [];

      // save user to redux
      // if (newJsonData[0].user && newJsonData[0].user.id) {
      //   tempData = [];
      //   newJsonData.map((item) => {
      //     tempData.push(item.user);
      //     delete item.user;
      //   });
      //   yield put(
      //     allActions.listUserSuccess(
      //       tempData,
      //       action.terms,
      //       action.nextPageToken
      //     )
      //   );
      // }
      // save stories to redux
      // if (newJsonData[0].stories && newJsonData[0].stories.length > 0) {
      //   tempData = [];
      //   newJsonData.map((item) => {
      //     tempData = [...tempData, ...item.stories];
      //     delete item.stories;
      //   });
      //   if (tempData.length > 0) {
      //     yield put(
      //       allActions.listStorySuccess(
      //         tempData,
      //         action.terms,
      //         action.nextPageToken
      //       )
      //     );
      //   }
      // }
      // save files to redux
      // if (newJsonData[0].files && newJsonData[0].files.length > 0) {
      //   tempData = [];
      //   newJsonData.map((item) => {
      //     tempData = [...tempData, ...item.files];
      //     delete item.files;
      //   });
      //   if (tempData.length > 0) {
      //     yield put(
      //       allActions.listFileSuccess(
      //         tempData,
      //         action.terms,
      //         action.nextPageToken
      //       )
      //     );
      //   }
      // }
      // save comments to redux
      // if (newJsonData[0].comments && newJsonData[0].comments.length > 0) {
      //   tempData = [];
      //   newJsonData.map((item) => {
      //     tempData = [...tempData, ...item.comments];
      //     delete item.comments;
      //   });
      //   if (tempData.length > 0) {
      //     yield put(
      //       allActions.listCommentSuccess(
      //         tempData,
      //         action.terms,
      //         action.nextPageToken
      //       )
      //     );
      //   }
      // }

      MyLog("Saga:listPet", json.data, newJsonData);
    }

    yield put(
      allActions.listPetSuccess(newJsonData, action.terms, action.nextPageToken)
    ); // call reducer function
  } catch (e) {
    yield put(allActions.listPetFailure(e.message));
  }
}

export function* editPet(action) {
  yield put(allActions.petLoadingChange(true, ""));
  try {
    const json = yield call(sagaApi.editPet, action); // call sagaApi function
    MyLog("editPet-mylog3", action, json);
    yield put(allActions.editPetSuccess(json.data, action.formData)); // call reducer function
    if (action.formData.name) {
      yield navigationService.navigate(STACK_SCREEN.petShow.name, {
        id: json.data.id,
      });
    }
  } catch (e) {
    yield put(allActions.editPetFailure(e.message));
  }
}

export function* addPet(action) {
  yield put(allActions.petLoadingChange(true, ""));
  try {
    const json = yield call(sagaApi.addPet, action); // call sagaApi function
    MyLog("addPet-mylog3", action, json);
    yield put(allActions.addPetSuccess(json.data, action.formData)); // call reducer function
    yield navigationService.navigate(STACK_SCREEN.petEdit.name, {
      id: json.data.id,
    });
  } catch (e) {
    yield put(allActions.addPetFailure(e.message));
  }
}

export function* delPet(action) {
  try {
    const json = yield call(sagaApi.delPet, action); // call sagaApi function
    MyLog("delPet-mylog3", action, json);
    yield put(allActions.delPetSuccess(json.data, action.formData)); // call reducer function
    yield navigationService.navigate(STACK_SCREEN.petIndex.name);
  } catch (e) {
    yield put(allActions.delPetFailure(e.message));
  }
}

// species
export function* listSpecies(action) {
  try {
    const json = yield call(sagaApi.listSpecies, action); // call sagaApi function
    MyLog("listSpecies-mylog1", action.type, json);
    yield put(
      allActions.listSpeciesSuccess(
        json.data,
        action.terms,
        action.nextPageToken
      )
    ); // call reducer function
  } catch (e) {
    yield put(allActions.listSpeciesFailure(e.message));
  }
}
