/* eslint-disable react/prop-types */
import React, { useReducer } from "react";
import { TouchableOpacity } from "react-native";
import { Text, Input } from "react-native-elements";
import { useSelector } from "react-redux";
import { INITIAL_REGISTER_DATA, STACK_SCREEN } from "../constants/config";
import { MyInput, MyFormReducer, MySubmitButton } from "../helper/commonHelper";
// import SubmitButton from './SubmitButton'

const RegisterForm = ({ onSubmit, initialValues, navigation }) => {
  // const [submitTimes, setSubmitTimes] = useState(0);
  const [formValues, setFormValues] = useReducer(MyFormReducer, initialValues);
  const userExtraInfo = useSelector((reduxState) => reduxState.users.extraInfo);

  return (
    <>
      <MyInput
        propName="firstName"
        keyboardType="default"
        formValues={formValues}
        setFormValues={setFormValues}
      />
      <MyInput
        propName="lastName"
        keyboardType="default"
        formValues={formValues}
        setFormValues={setFormValues}
      />
      <MyInput
        propName="email"
        keyboardType="default"
        formValues={formValues}
        setFormValues={setFormValues}
      />
      <MyInput
        propName="password"
        keyboardType="default"
        secureTextEntry={true}
        formValues={formValues}
        setFormValues={setFormValues}
      />
      <Input label="Remember Me" inputContainerStyle={{ display: "none" }} />
      {/* <MyRadio
      propName="rememberMe"
      value={true}
      formValues={formValues}
      setFormValues={setFormValues}
    /> */}

      {/* {userExtraInfo.errorMsg && submitTimes > 0 && <Text style={{ alignSelf: "center", marginTop: 10, color: "red" }}>{userExtraInfo.errorMsg}</Text>} */}
      {/* {userExtraInfo.loading && <Text style={{ alignSelf: "center", marginTop: 10, color:"red"}}>Loading</Text>} */}

      {/* <Button
      icon={{
        name: "user",
        type: 'font-awesome',
        size: 15,
        color: "white"
      }}
      title="Register"
      loading={userExtraInfo.loading}
      disabled={userExtraInfo.loading}
      disabledStyle={{ backgroundColor: "rgb(87, 110, 121)" }}
      buttonStyle={{
        width: "60%",
        margin: 40,
        alignSelf: 'center',
        backgroundColor: "rgb(17, 52, 68)"
      }}
      onPress={() => { setSubmitTimes(submitTimes + 1); return onSubmit(formValues); }}
    /> */}
      <MySubmitButton
        iconName="user"
        title="Register"
        isLoading={userExtraInfo.loading}
        message={userExtraInfo.errorMsg}
        onSubmit={() => {
          return onSubmit(formValues);
        }}
      />

      <TouchableOpacity
        onPress={() => navigation.navigate(STACK_SCREEN.userLogin.name)}
      >
        <Text style={{ alignSelf: "center", marginBottom: 50 }}>
          Already have an account? Login here!
        </Text>
      </TouchableOpacity>
    </>
  );
};

RegisterForm.defaultProps = {
  initialValues: INITIAL_REGISTER_DATA,
};

export default RegisterForm;
