import { createReducer } from "reduxsauce";
import { actionTypes } from "../actions/allActions";
import Immutable from "seamless-immutable";
// import { MyLog } from '../../constants/config'
import { MyLog, getUniqueMergeArray } from "../../helper/commonHelper";

// the initial state of this reducer
export const INITIAL_STATE = Immutable({
  allItems: [],
  myProfile: null,
  siteSettings: null,
  firebaseData: null,
  myLikes: [],
  accessToken: null,
  extraInfo: {
    etag: null,
    loading: false,
    nextPageToken: null,
    resultsPerPage: 5,
    totalResults: null,
    errorMsg: null,
  },
});

// const getUniqueItemArray = (existItems, newItems) => {
//   const noDuplicateItems = existItems.filter(item => {
//     if (newItems.find(data => data.id == item.id)) {
//       return false;
//     } else {
//       return true;
//     }
//   })
//   return [...noDuplicateItems, ...newItems];
// }

const listSiteSettingSuccess = (state = INITIAL_STATE, action) => {
  MyLog("userReducer listSiteSettingSuccess", action);

  return {
    ...state,
    ...{
      siteSettings: action.allData,
    },
  };
};

const listUserSuccess = (state = INITIAL_STATE, action) => {
  MyLog("userReducer listUserSuccess", action);
  // const allItems = (action.terms?.appendToAll) ? getUniqueItemArray(state.allItems, action.allData) : action.allData;
  const myProfile =
    state.myProfile && action.allData[0].id === state.myProfile.id
      ? action.allData[0]
      : state.myProfile;
  const allItems = getUniqueMergeArray(state.allItems, action.allData);
  return {
    ...state,
    ...{
      allItems: allItems,
      myProfile: myProfile,
    },
  };
};

const userRegisterSuccess = (state = INITIAL_STATE, action) => {
  MyLog("userReducer userRegisterSuccess", action);
  const extraInfo = { ...state.extraInfo, ...{ loading: false } };
  return {
    ...state,
    ...{
      firebaseData: action.backendData.firebaseData,
      myProfile: action.backendData.userProfile,
      accessToken: action.backendData.jwt,
      extraInfo: extraInfo,
    },
  };
};

const userRegisterFailure = (state = INITIAL_STATE, action) => {
  MyLog("userReducer userRegisterFailure", action);
  // const firebaseData = [action.backendData, state.firebaseData];
  const extraInfo = {
    ...state.extraInfo,
    ...{ loading: false, errorMsg: action.error },
  };
  return {
    ...state,
    ...{
      extraInfo: extraInfo,
    },
  };
};

const userLoginSuccess = (state = INITIAL_STATE, action) => {
  MyLog("userReducer userLoginSuccess", action);
  const extraInfo = { ...state.extraInfo, ...{ loading: false } };
  return {
    ...state,
    ...{
      firebaseData: action.backendData.firebaseData,
      myProfile: action.backendData.userProfile,
      accessToken: action.backendData.jwt,
      extraInfo: extraInfo,
    },
  };
};

const userLoginFailure = (state = INITIAL_STATE, action) => {
  MyLog("userReducer userLoginFailure", action);
  const extraInfo = {
    ...state.extraInfo,
    ...{ loading: false, errorMsg: action.error },
  };
  return {
    ...state,
    ...{
      extraInfo: extraInfo,
    },
  };
};

const userLogoutSuccess = (state = INITIAL_STATE, action) => {
  MyLog("userReducer userLogoutSuccess", action);
  const extraInfo = { ...state.extraInfo, ...{ loading: false } };
  return {
    ...state,
    ...{
      firebaseData: null,
      myProfile: null,
      accessToken: null,
      extraInfo: extraInfo,
    },
  };
};

const userLoadingChange = (state = INITIAL_STATE, action) => {
  MyLog("userReducer userLoadingChange", action);
  const extraInfo = {
    ...state.extraInfo,
    ...{ loading: action.loading, errorMsg: null },
  };
  return {
    ...state,
    ...{
      extraInfo: extraInfo,
    },
  };
};

const editUserSuccess = (state = INITIAL_STATE, action) => {
  MyLog("userReducer editUserSuccess", action);
  const extraInfo = {
    ...state.extraInfo,
    ...{ loading: false, errorMsg: "Profile update successful!" },
  };
  const myProfile = action.formData.firstName
    ? { ...state.myProfile, ...action.formData }
    : state.myProfile;

  const allItems = state.allItems.map((item) => {
    return item.id === action.backendData.id ? action.backendData : item;
  });
  return {
    ...state,
    ...{
      myProfile: myProfile,
      allItems: allItems,
      extraInfo: extraInfo,
    },
  };
};

// likes
// action.backendData already merged exists myLikes in saga
const addLikeSuccess = (state = INITIAL_STATE, action) => {
  MyLog("userReducer addLikeSuccess", action);
  return {
    ...state,
    ...{
      myLikes: action.backendData,
    },
  };
};
const delLikeSuccess = (state = INITIAL_STATE, action) => {
  MyLog("userReducer delLikeSuccess", action);
  return {
    ...state,
    ...{
      myLikes: action.backendData,
    },
  };
};

// map our action types to our reducer functions
export const HANDLERS = {
  [actionTypes.USER_REGISTER_SUCCESS]: userRegisterSuccess,
  [actionTypes.USER_REGISTER_FAILURE]: userRegisterFailure,
  [actionTypes.USER_LOGIN_SUCCESS]: userLoginSuccess,
  [actionTypes.USER_LOGIN_FAILURE]: userLoginFailure,
  [actionTypes.USER_LOGOUT_SUCCESS]: userLogoutSuccess,
  [actionTypes.USER_LOADING_CHANGE]: userLoadingChange,
  [actionTypes.LIST_USER_SUCCESS]: listUserSuccess,
  [actionTypes.EDIT_USER_SUCCESS]: editUserSuccess,

  [actionTypes.ADD_LIKE_SUCCESS]: addLikeSuccess,
  [actionTypes.DEL_LIKE_SUCCESS]: delLikeSuccess,

  [actionTypes.LIST_SITE_SETTING_SUCCESS]: listSiteSettingSuccess,
  // [actionTypes.UNLIKE_ITEM_SUCCESS]: unlikeItem,
  // [actionTypes.DELETE_ITEM_SUCCESS]: deleteItem,
  // [actionTypes.SET_FILTER_KEY_SUCCESS]: setFilterKey,
  // [actionTypes.SHOW_LOADING]: showLoading,
};

export default createReducer(INITIAL_STATE, HANDLERS);
