/* eslint-disable react/prop-types */
import React from "react";
import { Platform } from "react-native";
// import YoutubePlayer from "react-native-youtube-iframe";
import { WebView } from "react-native-webview";
import { MyLog } from "../constants/config";

const YoutubeVideo = ({ currentPet }) => {
  const getYoutubeId = (youtubeUrl) => {
    let youtubeId = "";
    if (youtubeUrl.indexOf("youtu.be/") != -1) {
      youtubeId = youtubeUrl.replace("https://youtu.be/", "");
    } else if (youtubeUrl.indexOf("?v=") != -1) {
      youtubeId = youtubeUrl.replace("https://www.youtube.com/watch?v=", "");
    }
    MyLog("youtubeId = ", youtubeId);
    return youtubeId;
  };

  if (Platform.OS == "web") {
    return (
      <iframe
        width="100%"
        height="360"
        src={
          "https://www.youtube.com/embed/" + getYoutubeId(currentPet.youtubeUrl)
        }
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    );
  } else {
    return (
      <WebView
        allowsFullscreenVideo
        allowsInlineMediaPlayback
        // mediaPlaybackRequiresUserAction
        style={{ height: 250, width: 300 }}
        // originWhitelist={['*']}
        // source={{ html: '<h1>Hello world</h1>' }}
        source={{ uri: currentPet.youtubeUrl }}
        // javaScriptEnabled={true}
        // domStorageEnabled={true}
      />
    );
  }
};
export default YoutubeVideo;
