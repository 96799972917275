import { mainApi } from "../constants/axiosApi";
import { MyLog, FIREBASE } from "../constants/config";
// import firebase from 'firebase/app'
import { SetAccessToken } from "../components/UserToken";

// user
export const userRegister = async (action) => {
  MyLog("userRegister action", action);
  let profile = {};
  let errorData = {};
  let firebaseData = await FIREBASE.auth()
    .createUserWithEmailAndPassword(
      action.formData.email,
      action.formData.password
    )
    .then((response) => {
      // Signed in
      MyLog("createUserWithEmailAndPassword success", response);
      return response;
    })
    .catch((error) => {
      MyLog("createUserWithEmailAndPassword error", error);
      errorData = error;
    });
  MyLog("userRegister firebaseData", firebaseData, errorData);

  if (firebaseData) {
    const newData = {
      ...action.formData,
      ...{ firebaseUid: firebaseData.user.uid, confirmPassword: null },
    };
    MyLog("createUserWithEmailAndPassword newData", newData);

    const firebaseIdToken = await firebaseData.user.getIdToken(
      /* forceRefresh */ true
    );
    const response = await userProfile({
      email: action.formData.email,
      firebaseUid: firebaseData.user.uid,
      firebaseIdToken: firebaseIdToken,
    });

    MyLog("userRegister userProfile", response.data);
    if (response && response.data) {
      profile = response.data[0] ? response.data[0] : response.data;
      SetAccessToken(profile.accessToken);
    }
  } else {
    firebaseData = { error: errorData };
  }

  return { firebaseData: firebaseData, userProfile: profile };
};

export const userProfile2 = (action) => {
  MyLog("userProfile action", action);

  return mainApi.get(`/users`, {
    params: {
      email: action.email,
    },
  });
};

export const userProfile = (action) => {
  MyLog("userProfile action", action);

  return mainApi.post(`/users/getUserToken`, {
    email: action.email,
    firebaseUid: action.firebaseUid,
    firebaseIdToken: action.firebaseIdToken,
  });
};

export const userGoogleLogin = async (action) => {
  MyLog("userGoogleLogin action", action);
  return mainApi.get(`/auth/google/callback`, {
    params: {
      access_token: action.accessToken,
    },
  });
};

export const userFacebookLogin = async (action) => {
  MyLog("userFacebookLogin action", action);
  return mainApi.get(`/auth/facebook/callback`, {
    params: {
      access_token: action.accessToken,
    },
  });
};

export const userEmailLogin = async (action) => {
  MyLog("userLocalEmailLogin action", action);
  const strapiLoginData = {
    identifier: action.formData.email,
    password: action.formData.password,
  };
  return mainApi.post(`/auth/local`, strapiLoginData);
};

export const userFirebaseEmailLogin = async (action) => {
  MyLog("userFirebaseEmailLogin action", action);
  let profile = {};
  let errorData = {};
  let firebaseData = await FIREBASE.auth()
    .signInWithEmailAndPassword(action.formData.email, action.formData.password)
    .then((user) => {
      // Signed in
      MyLog("signInWithEmailAndPassword success", user);

      // user.user.getIdToken(/* forceRefresh */ true).then(function (idToken) {
      //   // Send token to your backend via HTTPS
      //   MyLog("signInWithEmailAndPassword getIdToken2", idToken);
      // }).catch(function (error) {
      //   // Handle error
      // });

      return user;
    })
    .catch((error) => {
      MyLog("signInWithEmailAndPassword error", error);
      errorData = error;
    });
  // MyLog("userEmailLogin firebaseData2", firebaseData, errorData);
  if (firebaseData) {
    const firebaseIdToken = await firebaseData.user.getIdToken(
      /* forceRefresh */ true
    );
    // MyLog("signInWithEmailAndPassword firebaseIdToken ", firebaseIdToken);
    const response = await userProfile({
      email: action.formData.email,
      firebaseUid: firebaseData.user.uid,
      firebaseIdToken: firebaseIdToken,
    });
    MyLog("userEmailLogin userProfile2", response.data);
    if (response && response.data) {
      profile = response.data[0] ? response.data[0] : response.data;
      SetAccessToken(profile.accessToken);
    }
  } else {
    firebaseData = { error: errorData };
  }

  return { firebaseData: firebaseData, userProfile: profile };
};

export const userLogout = (action) => {
  MyLog("userLogout action", action);

  return true;

  // return FIREBASE.auth()
  //   .signOut()
  //   .then(() => {
  //     MyLog("signOut success");
  //     SetAccessToken(null);
  //     return true;
  //   })
  //   .catch((error) => {
  //     MyLog("signOut error", error);
  //     return false;
  //   });
  // return mainApi.post(`/users`, action.formData);
};

// TODO: need to reinforce the backend security
// should not allow to get batch user info and sensitive info like password,email
export const listUser = (action) => {
  MyLog("listUser action", action);
  let params = action.terms;
  // if (action.terms?.id) {
  //   params.id = action.terms.id;
  // }

  return mainApi.get(`/users`, {
    params: params,
  });
};

export const viewUser = (action) => {
  MyLog("viewUser action", action);
  // let params = action.terms;
  return mainApi.get(`/users/` + action.terms.id);
};

export const editUser = (action) => {
  MyLog("editUser action", action);
  let newFormData = { ...action.formData };
  if (newFormData.email) {
    delete newFormData.email;
  }
  delete newFormData.id;
  delete newFormData.createdAt;
  delete newFormData.updatedAt;
  return mainApi.put(`/users/` + action.formData.id, newFormData, {
    method: "PUT",
  });
};

export const listSiteSetting = (action) => {
  MyLog("listSiteSetting action", action);
  let params = action.terms;

  return mainApi.get(`/site-settings`, {
    params: params,
  });
};
