/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { ScrollView } from "react-native";
// import { Context } from '../context/StoryContext';
import StoryForm from "../../components/StoryForm";
import TopNavBar from "../../components/TopNavBar";
import BottomLink from "../../components/BottomLink";
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../reduxStores/actions/allActions";
import { STACK_SCREEN } from "../../constants/config";

const StoryCreateScreen = ({ route, navigation }) => {
  const petId = route.params?.petId;
  const currentUser = useSelector((reduxState) => reduxState.users.myProfile);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!currentUser) {
      navigation.navigate(STACK_SCREEN.userLogin.name);
    }
  }, []);
  return (
    <ScrollView>
      <TopNavBar title={STACK_SCREEN.storyCreate.title} />
      <StoryForm
        petId={petId}
        currentUser={currentUser}
        onSubmit={(formData) => {
          if (!formData.title.trim()) {
            alert("Please Enter Story Title");
            return false;
          }
          const newData = {
            ...formData,
            ...{
              user: currentUser?.id,
              pet: formData.petId,
            },
          };
          dispatch(allActions.addStoryRequest(newData));
        }}
      />
      <BottomLink currentUser={currentUser} />
    </ScrollView>
  );
};

// const styles = StyleSheet.create({});

export default StoryCreateScreen;
