/* eslint-disable react/prop-types */
import React from "react";
import { ScrollView } from "react-native";
import { useDispatch } from "react-redux";
import allActions from "../../reduxStores/actions/allActions";
import { STACK_SCREEN } from "../../constants/config";
import RegisterForm from "../../components/UserRegisterForm";
import TopNavBar from "../../components/TopNavBar";

const UserRegisterScreen = ({ navigation }) => {
  const dispatch = useDispatch();
  return (
    <ScrollView>
      <TopNavBar title={STACK_SCREEN.userRegister.title} />
      <RegisterForm
        onSubmit={(formData) => {
          dispatch(allActions.userRegisterRequest(formData));
        }}
        navigation={navigation}
      />
    </ScrollView>
  );
};

// const styles = StyleSheet.create({});

export default UserRegisterScreen;
