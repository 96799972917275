import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const PetImageCarousel = ({ currentPet, currentFiles }) => {
  let selectedImageIndex = 0;
  if (!currentFiles) {
    return <></>;
  }
  currentFiles.map((item, index) => {
    if (item.url == currentPet.main_image_url) {
      selectedImageIndex = index;
    }
  });

  if (currentFiles.length < 2) {
    // return <div className="row justify-content-md-center pet-carousel"><div className="col-auto single-img"><Image source={{ uri: currentPet.main_image_url || 'https://picsum.photos/320/180?ramdom=' + currentPet.id }} style={{
    //     width: "100%", height: "400px", alignSelf: 'center'
    // }} /></div></div>

    return (
      <div className="pet-carousel">
        <Carousel showThumbs={false} showIndicators={false}>
          <div className="img-div">
            <img
              src={
                currentPet.main_image_url ||
                "https://picsum.photos/600/400?ramdom=" + currentPet.id
              }
            />
            {/* <p className="legend">{item.name}</p> */}
          </div>
        </Carousel>
      </div>
    );
  } else {
    return (
      <div className="pet-carousel">
        <Carousel
          swipeable={true}
          infiniteLoop={true}
          stopOnHover={true}
          autoPlay={true}
          interval="6000"
          selectedItem={selectedImageIndex}
        >
          {currentFiles.map((item) => {
            return (
              <div key={item.id} className="img-div">
                <img
                  src={
                    item.url ||
                    "https://picsum.photos/320/180?ramdom=" + item.id
                  }
                />
                {/* <p className="legend">{item.name}</p> */}
              </div>
            );
          })}
        </Carousel>
      </div>
    );

    // const sliderSettings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1
    // };

    // return (
    //     <Slider {...sliderSettings}>
    //         {currentFiles.map((item) => {
    //             return <div key={item.id}><Image source={{ uri: item.url || 'https://picsum.photos/320/180?ramdom=' + item.id }} style={{ width: 480, height: 270 }} /></div>
    //         })}

    //     </Slider>
    // );
    // return (
    //     <FlatList
    //         data={currentFiles}
    //         keyExtractor={item => item.id.toString()}
    //         pagingEnabled
    //         horizontal
    //         showsHorizontalScrollIndicator={false}
    //         renderItem={({ item }) => {
    //             return <Image source={{ uri: item.url || 'https://picsum.photos/320/180?ramdom=' + item.id }} style={{ width: 480, height: 270 }} />
    //         }}
    //     />
    // )
  }
};

export default PetImageCarousel;
