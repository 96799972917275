/* eslint-disable react/prop-types */
import React, { useEffect, useReducer } from "react";
import { ScrollView, View } from "react-native";
import { Link } from "@react-navigation/native";
import { Button, Text } from "react-native-elements";
import TopNavBar from "../../components/TopNavBar";
import BottomLink from "../../components/BottomLink";
import {
  COLORS,
  STACK_SCREEN,
  NUM_PER_PAGE,
  MyLog,
} from "../../constants/config";
// import Comment from '../../components/Comment';
import { useDispatch, useSelector } from "react-redux";
import allActions from "../../reduxStores/actions/allActions";
import { MyFormReducer, MySubmitButton } from "../../helper/commonHelper";

import StoryList from "../../components/StoryList";

const StoryIndexScreen = ({ route, navigation }) => {
  // let species = route.params?.species;
  // const [species, setSpecies] = useState(route.params?.species);
  const dispatch = useDispatch();
  const allSpecies = useSelector((reduxState) => reduxState.pets.allSpecies);
  const allPets = useSelector((reduxState) => reduxState.pets.allItems);

  const allStories = useSelector((reduxState) => reduxState.stories.allItems);
  const storyExtraInfo = useSelector(
    (reduxState) => reduxState.stories.extraInfo
  );

  // const [showAllSpecies, setShowAllSpecies] = useState(false);
  const currentUser = useSelector((reduxState) => reduxState.users.myProfile);

  const [storyReducer, setStoryReducer] = useReducer(MyFormReducer, {
    species: route.params?.species,
    showAllSpecies: false,
    relatedStories: allStories,
    isValidSpecies: false,
    displayNumber: NUM_PER_PAGE,
    params: {
      _limit: NUM_PER_PAGE,
      // _start: searchResults.length,
      _start: 0,
      // _end: 0,
      // _expand: "pet",
    },
  });

  MyLog("species param", storyReducer.species);
  // let relatedStories = allStories;
  // let isValidSpecies = false;
  if (storyReducer.species && storyReducer.species.indexOf("%") != -1) {
    // setSpecies(decodeURIComponent(species));
    setStoryReducer({
      type: "change_species",
      payload: decodeURIComponent(storyReducer.species),
    });
  }

  useEffect(() => {
    if (
      storyReducer.species &&
      !storyReducer.isValidSpecies &&
      allSpecies.find((item) => item.name == storyReducer.species)
    ) {
      // isValidSpecies = true;
      setStoryReducer({ type: "change_isValidSpecies", payload: true });
    }

    // if (allPets.length == 0) {
    //   MyLog('try to get this pet info from db');
    //   dispatch(allActions.listPetRequest({ isHomepage: 1 }));
    // }
    if (!allSpecies) {
      dispatch(allActions.listSpeciesRequest());
    }
    if (storyReducer.species) {
      navigation.setOptions({
        title:
          STACK_SCREEN.storyIndex.title +
          ":" +
          storyReducer.species +
          " Stories",
      });
    }
    MyLog(
      "storyReducer.species",
      storyReducer.species,
      storyReducer.isValidSpecies
    );

    // if (storyReducer.isValidSpecies) {
    //   // const relatedPets = allPets.filter(item => item.species == storyReducer.species);
    //   // MyLog('change_relatedStories', allStories)
    //   // setStoryReducer({
    //   //   type: 'change_relatedStories',
    //   //   payload: allStories.filter(item => {
    //   //     return relatedPets.find(pet => item.petId == pet.id)
    //   //   })
    //   // })
    //   const newParams = {
    //     ...storyReducer.params,
    //     ...{ species: storyReducer.species, _start: 0 }
    //   }
    //   setStoryReducer({
    //     type: 'change_params',
    //     payload: newParams
    //   })

    //   dispatch(allActions.listStoryRequest(newParams));

    // } else {
    //   // setStoryReducer({
    //   //   type: 'change_relatedStories',
    //   //   payload: !storyReducer.species ? allStories : []
    //   // })

    //   if (!storyReducer.species) {
    //     let newParams = {
    //       ...storyReducer.params,
    //       ...{ _start: 0 }
    //     }
    //     if (newParams.species) {
    //       delete newParams.species
    //     }

    //     dispatch(allActions.listStoryRequest(newParams));
    //   }

    // }
  }, [route.params, storyReducer.isValidSpecies]);

  useEffect(() => {
    const newParams = {
      ...storyReducer.params,
      ...{
        species: storyReducer.species,
        _start: 0,
      },
    };
    setStoryReducer({
      type: "change_params",
      payload: newParams,
    });

    dispatch(allActions.listStoryRequest(newParams));
  }, [route.params]);

  useEffect(() => {
    setStoryReducer({
      type: "change_relatedStories",
      payload: !storyReducer.species
        ? allStories
        : allStories.filter((item) => {
            return item.species == storyReducer.species;
          }),
    });
  }, [allStories]);

  MyLog(
    "displayNumber relatedStories.length",
    storyReducer.displayNumber,
    storyReducer.relatedStories.length,
    "start=",
    storyReducer.params._start
  );
  return (
    <ScrollView>
      <TopNavBar title={STACK_SCREEN.storyIndex.title} />
      <Text h4 style={{ textTransform: "capitalize", alignSelf: "center" }}>
        {storyReducer.species ? storyReducer.species : "Pet"} Stories
      </Text>
      {storyReducer.showAllSpecies ||
      storyReducer.relatedStories.length == 0 ? (
        <View style={{ flex: 1, flexDirection: "row", flexWrap: "wrap" }}>
          <Link
            to={"/petStories"}
            style={{ margin: 20, fontSize: 20, color: COLORS.info }}
            onPress={() => {
              setStoryReducer({ type: "change_species", payload: null });
              setStoryReducer({
                type: "change_isValidSpecies",
                payload: false,
              });
              setStoryReducer({
                type: "change_showAllSpecies",
                payload: false,
              });
              // setSpecies(null);
              // setShowAllSpecies(false)
            }}
          >
            * All Species
          </Link>
          {allSpecies.map((item) => {
            return (
              <Link
                to={"/petStories/" + item.name}
                style={{ margin: 20, fontSize: 20, color: COLORS.info }}
                onPress={() => {
                  setStoryReducer({
                    type: "change_species",
                    payload: item.name,
                  });
                  setStoryReducer({
                    type: "change_showAllSpecies",
                    payload: false,
                  });
                  // setSpecies(item.name); setShowAllSpecies(false)
                }}
                key={item.id}
              >
                * {item.name}
              </Link>
            );
          })}
        </View>
      ) : (
        <Button
          type="clear"
          title="Other Pet Species >>"
          buttonStyle={{ alignContent: "flex-end", alignSelf: "flex-end" }}
          // titleStyle={{ fontSize: 11 }}
          onPress={() => {
            setStoryReducer({ type: "change_showAllSpecies", payload: true });

            // setShowAllSpecies(true)
          }}
        />
      )}

      {/* {storyReducer.species && !storyReducer.isValidSpecies ?
        <Text h4 style={{ margin: 50, color: COLORS.primary }}>Woops, Pet species {storyReducer.species} not exists!</Text>
        :
        <>
          {
            storyReducer.relatedStories.length > 0 ?
              <StoryList stories={storyReducer.relatedStories.slice(0, storyReducer.displayNumber)} navigation={navigation} pets={allPets} />
              :
              <Text h4 style={{ margin: 50, color: COLORS.primary }}>Woops, No {storyReducer.species} story yet!</Text>
          }
        </>
      } */}

      <StoryList
        stories={storyReducer.relatedStories.slice(
          0,
          storyReducer.displayNumber
        )}
        navigation={navigation}
        pets={allPets}
      />

      {storyReducer.displayNumber <= storyReducer.relatedStories.length ||
      storyExtraInfo.loading ? (
        <MySubmitButton
          iconName="arrow-right"
          title={"Load more " + (storyReducer.species || "Pet") + " Story"}
          isLoading={storyExtraInfo.loading}
          message={storyExtraInfo.errorMsg}
          // buttonStyle={{
          //     backgroundColor: 'rgb(218, 68, 90)', paddingLeft: 20,
          //     paddingRight: 20, width: "auto"
          // }}
          onSubmit={() => {
            // if (searchResults.length >= displayNumber + listPetParams._limit) {
            //     setDisplayNumber(displayNumber + listPetParams._limit);
            // } else {
            // listPetParams._start = displayNumber;
            // setDisplayNumber(displayNumber + listPetParams._limit);
            const newParams = {
              ...storyReducer.params,
              ...{ _start: storyReducer.displayNumber },
            };
            setStoryReducer({
              type: "change_params",
              payload: newParams,
            });
            setStoryReducer({
              type: "change_displayNumber",
              payload: storyReducer.displayNumber + storyReducer.params._limit,
            });
            return dispatch(allActions.listStoryRequest(newParams));
            // }
          }}
        />
      ) : (
        <Button
          title={
            storyReducer.species
              ? "No more " + storyReducer.species + " Story"
              : "No more Pet Story" + storyReducer.displayNumber
          }
          type="clear"
          titleStyle={{ fontSize: 11 }}
        />
      )}

      <BottomLink currentUser={currentUser} />
    </ScrollView>
  );
};

export default StoryIndexScreen;
