import axios from "axios";
import { BACKEND_API } from "./config";
import { GetAccessToken } from "../components/UserToken";

// const getMainApiHeader = async () => {
//   const currentUser = await useSelector(reduxState => reduxState.users.myProfile);
//   let mainApiHeaders = {};
//   if (currentUser && currentUser.accessToken) {
//     mainApiHeaders.Authorization = 'Bearer ' + currentUser.accessToken;
//   }
//   return mainApiHeaders;
// }

export const mainApi = axios.create({
  baseURL: BACKEND_API,
  // baseURL: "https://data.petstory.online/api",
  // baseURL: 'https://meanapi.now.sh/serverlessApi',
  // baseURL: "http://localhost:8080/api",
  headers: {
    "Content-Type": "application/json",
    // "Api-Provider": "PetStory Strapi API",
  },
});

mainApi.interceptors.request.use(async function (config) {
  const myToken = await GetAccessToken();
  if (myToken) {
    config.headers["Authorization"] = "Bearer " + myToken;
  }
  // MyLog('interceptors config',config);
  return config;
});

export const imgBbApi = axios.create({
  baseURL: "https://api.imgbb.com/1",
  headers: {
    "Content-Type": "multipart/form-data",
  },
  params: {
    // expiration: 0,
    key: "241ab099e3539c32ceea7ed0951aa916",
  },
});

export const imgUrApi = axios.create({
  baseURL: "https://api.imgur.com/3",
  headers: {
    Authorization: "Client-ID 9a47b4fa47df38d",
    "Content-Type": "multipart/form-data",
  },
  params: {
    // expiration: 0,
    // key: '241ab099e3539c32ceea7ed0951aa916'
  },
});

export const imgKitApi = axios.create({
  baseURL: "https://upload.imagekit.io/api/v1/files",
  headers: {
    "Content-Type": "multipart/form-data",
  },
  params: {
    // expiration: 0,
    // key: '241ab099e3539c32ceea7ed0951aa916'
  },
});

// githubContentsApi for websiteApp example
export const githubRepository = {
  name: "AlexStack/React-Hook-Redux-Saga-Webpack-Starter",
  branch: "master",
  initDirectory: "doc/websiteApp",
  configFile: "0.websiteConfig.json",
  wrongFormatMsg:
    "<div>Error: File source not start with div, please ask the admin to fix it</div>",
  axiosErrorMsg:
    "<div>Get API result failed, please ask the admin to check the console log</div>",
  token1: "0655a262b7ca299897f235",
  token2: "cde0d924c4e4c05cde",
};

export const githubContentsApi = axios.create({
  baseURL:
    "https://api.github.com/repos/" + githubRepository.name + "/contents",
  timeout: 3000,
  responseType: "json",
  params: {
    ref: githubRepository.branch,
  },
  headers: {
    "Content-Type": "application/json",
    Authorization: "token " + githubRepository.token1 + githubRepository.token2,
  },
});

// get github raw file to avoid api rate limit
export const githubRawFile = axios.create({
  baseURL:
    "https://raw.githubusercontent.com/" +
    githubRepository.name +
    "/" +
    githubRepository.branch,
  timeout: 3000,
  responseType: "text",
  headers: {
    "Content-Type": "text/plain",
  },
});

export default mainApi;
