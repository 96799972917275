/* eslint-disable react/prop-types */
import React from "react";
import { View, StyleSheet } from "react-native";
// import { Feather } from '@expo/vector-icons';
import { SearchBar } from "react-native-elements";

const SearchBarTop = ({ term, onTermChange, onTermSubmit }) => {
  // const [search, setSearch] = useState('');
  return (
    <View style={styles.backgroundStyle}>
      {/* <Feather name="search" style={styles.iconStyle} /> */}

      {/* <TextInput
        autoCapitalize="none"
        autoCorrect={false}
        style={styles.inputStyle}
        placeholder="Search"
        value={term}
        onChangeText={onTermChange}
        onEndEditing={onTermSubmit}
      /> */}
      {/* <Icon
        raised={true}
        name='search'
        type='font-awesome'
        color='#f50'
        onPress={() => MyLog('hello')} /> */}

      <SearchBar
        placeholder="Keyword ...."
        onChangeText={onTermChange}
        onEndEditing={onTermSubmit}
        onSubmitEditing={onTermSubmit}
        onBlur={onTermSubmit}
        onClear={onTermSubmit}
        // inputContainerStyle={styles.backgroundStyle}
        searchIcon={{ type: "font-awesome", name: "search" }}
        clearIcon={{ type: "font-awesome", name: "times-circle" }}
        platform="ios"
        showLoading={false}
        value={term}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  backgroundStyle: {
    marginTop: 10,
    // backgroundColor: '#F0EEEE',
    height: 50,
    borderRadius: 5,
    marginHorizontal: 15,
    flexDirection: "row",
    marginBottom: 10,
  },
  inputStyle: {
    flex: 1,
    fontSize: 18,
    width: 250,
  },
  iconStyle: {
    fontSize: 35,
    alignSelf: "center",
    marginHorizontal: 15,
  },
});

export default SearchBarTop;
